import React, { useState, useEffect } from 'react';
import {
    Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal,
    ModalBody,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText, Progress,
    Breadcrumb,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    ModalHeader
} from 'reactstrap';
import { ValidarSoloLetras, ValidarSoloNumeros, ValidarCorreo } from '../../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import Fotop from '../../../assets/faces/user (3).png';
import * as Bi from "react-icons/bi";
import { ContraseñaSegura, EvaluarSeguridadContraseña, InfoMessage, NivelSeguridad, SuccessMessage, removeBase64Prefix } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import MenuItem from '../../../components/MenuItem';
import { IoMdReturnLeft } from "react-icons/io";
import { GetCargos, GetNombramientos, GetRoles, GetSexo, GetTipoDereccion } from '../../../../CapaDatos/DatosCargarSelects';
import { GetProvincias, GetCantones, GetDistritos, GetBarrios, GetPaises } from '../../../../CapaDatos/DatosCargarUbicaciones'
import { GetRegionales } from '../../../../CapaDatos/DatosCargarRegionales'
import { ErrorMessage, ObtenerImagen } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Swal from 'sweetalert2';
import Foto from '../../../assets/faces/user (3).png'
import { EstadosIngresarInstitucion } from '../../../../CapaUtilidades/Estados/EstadosIngresarInstitucion';
import * as Fa6 from "react-icons/fa6";
import Espaciador from '../../../components/Espaciador';
import { ActualizarNombramientoFunc, DeleteNombramiento, getActualizarFuncionario, getFuncionariosActivos, saveHistoricoNombramientos } from '../../../../CapaDatos/DatosFuncionarios';
import { TbFuncionario } from '../../../../CapaEntidades/TbFuncionario';
import { TbUsuarios } from '../../../../CapaEntidades/TbUsuarios';
import * as  Tb from "react-icons/tb";
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { DeleteUser, UpdateUser, getDatosUsuario } from '../../../../CapaDatos/DatosUsuarios';
import * as Fas from 'react-icons/fa';
import { getAsignaturas } from '../../../../CapaDatos/DatosAsignaturas';
import { TbAsignaturasDocente } from '../../../../CapaEntidades/TbAsignaturasDocente';
import { ExisteAsigDocente, RestaurarAsignaturaDocente, deleteAsignaturaDocente, getAsignaturasDocente, saveAsignaturaDocente } from '../../../../CapaDatos/DatosAsigDocentes';
import * as Md from "react-icons/md";
import { ObtenerNombreInst } from '../../../../CapaUtilidades/Utils/encryptUserData';

let globalFuncionariosData = null;
let globalDatosPerfilData = null;
let globalAsignaturasData = null;

const FrmFuncionarios = ({ codigoPresupuestario }) => {
    const TBFuncionario = TbFuncionario();
    const TBAsignaturasDocente = TbAsignaturasDocente();
    const TBUsuarios = TbUsuarios();
    TBFuncionario.CodigoPresupuestario = codigoPresupuestario;

    const CargarDatos = () => {
        GetPaises(TBFuncionario.setListaPaises)
        GetProvincias(TBFuncionario.setListaProvincias)
        GetRegionales(TBFuncionario.setListaRegionales)
        GetCargos(TBFuncionario.setListaCargos)
        GetNombramientos(TBFuncionario.setListaNombramientos)
        GetRoles(TBFuncionario.setListaRoles)
        GetTipoDereccion(TBFuncionario.setListaTipoDereccion)
        GetSexo(TBFuncionario.setListaSexo)
        TBUsuarios.setEstadoUser(1)
        TBAsignaturasDocente.setEstadoAsig(1)

    }

    useEffect(() => {
        if (TBFuncionario.selectedProvinciaFuncionario) {
            GetCantones({ target: { value: TBFuncionario.selectedProvinciaFuncionario } }, TBFuncionario.setSelectedProvinciaFuncionario, TBFuncionario.setListaCantones);
        }
    }, [TBFuncionario.selectedProvinciaFuncionario]);

    useEffect(() => {
        if (TBFuncionario.selectedCantonFuncionario) {
            GetDistritos({ target: { value: TBFuncionario.selectedCantonFuncionario } }, TBFuncionario.selectedProvinciaFuncionario, TBFuncionario.setSelectedCantonFuncionario, TBFuncionario.setListaDistritos);
        }
    }, [TBFuncionario.selectedCantonFuncionario]);

    useEffect(() => {
        if (TBFuncionario.selectedDistritoFuncionario) {
            GetBarrios({ target: { value: TBFuncionario.selectedDistritoFuncionario } }, TBFuncionario.selectedProvinciaFuncionario, TBFuncionario.selectedCantonFuncionario, TBFuncionario.setSelectedDistritoFuncionario, TBFuncionario.setListaBarrios);
        }
    }, [TBFuncionario.selectedDistritoFuncionario]);


    const fetchData2 = async () => {
        try {

            const parametro = 1;

            const data = await getAsignaturas(parametro);

            let globalAsignaturasData = data;
            TBFuncionario.setAsignaturas(data);
            
            
        } catch (error) {

        }
    };

    
    const fetchData = async () => {
        try {
            const parametro = { idInstitucion: TBFuncionario.CodigoPresupuestario, Estado: 1 };
            
            const data = await getFuncionariosActivos(1);
            const data2 = await getDatosUsuario();
            globalFuncionariosData = data;
            globalDatosPerfilData = data2;
            
            TBFuncionario.setDatosPerfil(data2);
            TBFuncionario.setFuncionarios(data);
            TBFuncionario.setLoading(false);
            CargarDatos();
        } catch (error) {
            
            TBFuncionario.setLoading(false);
        }
    };
    
    useEffect(() => {

      
            fetchData2();
        
    }, []);

    useEffect(() => {
        if ( globalFuncionariosData && globalDatosPerfilData ) {
            TBFuncionario.setDatosPerfil(globalDatosPerfilData);
            TBFuncionario.setFuncionarios(globalFuncionariosData);
            TBFuncionario.setLoading(false);
        } else {
            fetchData();
        }

    }, []);

    const MostrarContrasena = () => {
        TBFuncionario.setMostrarContrasena(!TBFuncionario.mostrarContrasena); // Cambia el estado para mostrar o no la contraseña
    };

    const Contraseña_TextChanged = (event) => {
        TBUsuarios.setPassword(event.target.value);
    };

    const nivelSeguridad = EvaluarSeguridadContraseña( TBUsuarios.password);

    if (TBFuncionario.loading) {
        return (
            <Cargando />
        );
    }

    if (TBFuncionario.funcionarios.estado === 0) {
        //fetchData();
    }
    if (!TBFuncionario.funcionarios?.length) {
        fetchData();

    }

    const toggle = () => {
        TBFuncionario.setOpen(!TBFuncionario.open);

    }
    const toggle2 = () => {
        TBFuncionario.setSeleccionadas([])
        TBFuncionario.setOpen2(!TBFuncionario.open2);

    }
    const toggle3 = () => {
        TBFuncionario.setSeleccionadas([])
        TBFuncionario.setOpen3(!TBFuncionario.open3);

    }
    const toggleA = (id) => {
        TBFuncionario.setOpenA(TBFuncionario.openA === id ? null : id); // Abre o cierra el acordeón según el id
    };
    const Abrirmodal = (data) => {
        const FuncionarioSeleccionado = TBFuncionario.funcionarios.find(funcionario => funcionario.cedulaFuncionario === data);

        if (FuncionarioSeleccionado) {
            TBFuncionario.setCedula(FuncionarioSeleccionado.cedulaFuncionario);
            TBFuncionario.setNombre(FuncionarioSeleccionado.nombreCompleto);
            TBFuncionario.setapellido1(FuncionarioSeleccionado.apellido1);
            TBFuncionario.setapellido2(FuncionarioSeleccionado.apellido2);
            TBFuncionario.setcargo(FuncionarioSeleccionado.cargo);
            TBFuncionario.setTipoNombramiento(FuncionarioSeleccionado.tipoNombramiento);
            TBFuncionario.setTelefonoFunPrincipal(FuncionarioSeleccionado.telefonoPrincipal);
            TBFuncionario.setTelefonoFunSecundario(FuncionarioSeleccionado.telefono1);
            TBFuncionario.setCorreo(FuncionarioSeleccionado.correo);
            TBFuncionario.setDireccionFuncionario(FuncionarioSeleccionado.direccion);
            if (FuncionarioSeleccionado.foto !== null) {
                TBFuncionario.setfotoPerfil(`data:image/jpeg;base64,${removeBase64Prefix(FuncionarioSeleccionado.foto)}`);
                TBFuncionario.setSelectedImage(`data:image/jpeg;base64,${removeBase64Prefix(FuncionarioSeleccionado.foto)}`);
            }
            TBFuncionario.setPais(FuncionarioSeleccionado.nacionalidad);
            TBFuncionario.setSelectedProvinciaFuncionario(FuncionarioSeleccionado.Provincia);

            TBFuncionario.setSelectedCantonFuncionario(FuncionarioSeleccionado.Canton);
            TBFuncionario.setSelectedDistritoFuncionario(FuncionarioSeleccionado.Distrito);
            TBFuncionario.setSelectedBarrioFuncionario(FuncionarioSeleccionado.Barrio);
            TBFuncionario.setSexo(FuncionarioSeleccionado.sexo);
            TBFuncionario.setIdNombromiento(FuncionarioSeleccionado.codigoNombramientoInstitucion)
            TBFuncionario.setFechaIngreso(FuncionarioSeleccionado.fechaInicio.substring(0, 10));
            TBFuncionario.setFechaSalida(FuncionarioSeleccionado.fechaFinaliza.substring(0, 10));
            TBFuncionario.setObservaciones(FuncionarioSeleccionado.observaciones);
             TBUsuarios.setUsuario(FuncionarioSeleccionado.usuario);
             TBUsuarios.setPassword(FuncionarioSeleccionado.passwd);
             TBUsuarios.setInstitucionUser(TBFuncionario.CodigoPresupuestario);
             TBUsuarios.setPermisoUsuario(FuncionarioSeleccionado.permisosUsuario);
             TBUsuarios.setEstadoUser(FuncionarioSeleccionado.estado);
        }

        TBFuncionario.setOpen(!TBFuncionario.open)

    }

    const Abrirmodal2 = async (data) => {

        //alert(JSON.stringify(data));
        const FuncionarioSeleccionado = TBFuncionario.funcionarios.find(funcionario => funcionario.cedulaFuncionario === data);
        //alert(JSON.stringify(FuncionarioSeleccionado));


        if (FuncionarioSeleccionado) {

            const param = {
                docente: FuncionarioSeleccionado.cedulaFuncionario,
                annio: TBFuncionario.currentYear.toString(),
                institucion: TBFuncionario.CodigoPresupuestario,
                estado: 1
            }

            const lista = await getAsignaturasDocente(param)
            //alert(JSON.stringify(lista));

            if (!lista.estado) {
                // La consulta fue exitosa pero no hay datos
                TBFuncionario.setCedula(FuncionarioSeleccionado.cedulaFuncionario);
                TBFuncionario.setNombre(FuncionarioSeleccionado.nombreCompleto);
                TBFuncionario.setapellido1(FuncionarioSeleccionado.apellido1);
                TBFuncionario.setapellido2(FuncionarioSeleccionado.apellido2);
                TBUsuarios.setInstitucionUser(FuncionarioSeleccionado.idInstitucion);

                TBAsignaturasDocente.setDocente(FuncionarioSeleccionado.cedulaFuncionario);
                TBAsignaturasDocente.setInstitucion(FuncionarioSeleccionado.idInstitucion);
                TBAsignaturasDocente.setSelectedYear(TBFuncionario.currentYear)
                TBFuncionario.setNombreInst(ObtenerNombreInst())
            }
            // La consulta fue exitosa y hay datos
            TBFuncionario.setListaAsigDocente(lista);
            TBFuncionario.setCedula(FuncionarioSeleccionado.cedulaFuncionario);
            TBFuncionario.setNombre(FuncionarioSeleccionado.nombreCompleto);
            TBFuncionario.setapellido1(FuncionarioSeleccionado.apellido1);
            TBFuncionario.setapellido2(FuncionarioSeleccionado.apellido2);
            TBUsuarios.setInstitucionUser(FuncionarioSeleccionado.idInstitucion);

            TBAsignaturasDocente.setDocente(FuncionarioSeleccionado.cedulaFuncionario);
            TBAsignaturasDocente.setInstitucion(TBFuncionario.CodigoPresupuestario);
            TBFuncionario.setNombreInst(ObtenerNombreInst())

        }

        //alert(currentYear); // Obtiene el año actual)
        TBFuncionario.setOpen2(!TBFuncionario.open2)
    }
    const Abrirmodal3 = async (data) => {
        const FuncionarioSeleccionado = TBFuncionario.funcionarios.find(funcionario => funcionario.cedulaFuncionario === data);


        if (FuncionarioSeleccionado) {

            const param = {
                docente: FuncionarioSeleccionado.cedulaFuncionario,
                annio: TBFuncionario.currentYear.toString(),
                institucion: TBFuncionario.CodigoPresupuestario,
                estado: 1
            }

            const lista = await getAsignaturasDocente(param)
            //alert(JSON.stringify(lista));
            TBFuncionario.setListaAsigDocente(lista)

            TBFuncionario.setCedula(FuncionarioSeleccionado.cedulaFuncionario);
            TBFuncionario.setNombre(FuncionarioSeleccionado.nombreCompleto);
            TBFuncionario.setapellido1(FuncionarioSeleccionado.apellido1);
            TBFuncionario.setapellido2(FuncionarioSeleccionado.apellido2);
            TBFuncionario.setInstitucionUser(FuncionarioSeleccionado.idInstitucion);
            TBFuncionario.setSexo(FuncionarioSeleccionado.sexo);
            TBAsignaturasDocente.setDocente(FuncionarioSeleccionado.cedulaFuncionario)
            TBAsignaturasDocente.setInstitucion(TBFuncionario.CodigoPresupuestario)
            TBFuncionario.setNombreInst(ObtenerNombreInst())
        }



        TBFuncionario.setOpen3(!TBFuncionario.open2)
    }
    const Eliminar = async (data, data2) => {
        Swal.fire({
            title: "Estas seguro que deseas dar de baja este funcionario?",
            text: "¡No podrás revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "¡Sí, bórrar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {

                const success = await DeleteUser(data)
                const success2 = await DeleteNombramiento(data2);
                if (success.estado && success2.estado) {
                    Swal.fire({
                        title: "¡Eliminado!",
                        text: "El Usuario ha sido dada de baja",
                        icon: "success",
                        timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
                        timerProgressBar: true, // Barra de progreso de tiempo
                        showConfirmButton: false
                    });
                    fetchData();
                } else {
                    ErrorMessage('Notificación del Sistema', success.mensaje);
                    return;

                }

            }
        });





    }



    const ActualizarFuncionario = async (event) => {
        event.preventDefault();
        if (nivelSeguridad === NivelSeguridad.MuySegura || nivelSeguridad === NivelSeguridad.MediaSegura) {

            const data =
            {
                cedulaFuncionario: TBFuncionario.cedula,
                nombreCompleto: TBFuncionario.nombre,
                apellido1: TBFuncionario.apellido1,
                apellido2: TBFuncionario.apellido2,

                telefonoPrincipal: TBFuncionario.telefonoFunPrincipal,
                telefono1: TBFuncionario.telefonoFunSecundario,
                correo: TBFuncionario.correo,
                direccion: TBFuncionario.DireccionFuncionario,
                foto: TBFuncionario.fotoPerfil,
                nacionalidad: parseInt(TBFuncionario.pais),
                Provincia: TBFuncionario.selectedProvinciaFuncionario.toString(),
                Canton: TBFuncionario.selectedCantonFuncionario.toString(),
                Distrito: TBFuncionario.selectedDistritoFuncionario.toString(),
                Barrio: TBFuncionario.selectedBarrioFuncionario.toString(),
                sexo: TBFuncionario.sexo

            }

            const success = await getActualizarFuncionario(data);
            if (success.estado) {

                let Observacion = '';
                if (TBFuncionario.Observaciones === '') {
                    Observacion = 'No hay observaciones';
                } else {
                    Observacion = TBFuncionario.Observaciones
                }

                const tbFuncLaboraInstitucion = {
                    idNombramiento: TBFuncionario.idNombramiento,
                    cargo: parseInt(TBFuncionario.cargo),
                    tipoNombramiento: parseInt(TBFuncionario.tipoNombramiento),
                    fechaInicio: TBFuncionario.fechaIngreso,
                    fechaFinaliza: TBFuncionario.fechaSalida,
                    observaciones: Observacion
                }


                const successFuncLaboraInstitucion = await ActualizarNombramientoFunc(tbFuncLaboraInstitucion);

                if (successFuncLaboraInstitucion.estado) {

                    const dataUser =
                    {
                        usuario:  TBUsuarios.usuario,
                        passwd: TBUsuarios. password,
                        idInstitucion: TBFuncionario.CodigoPresupuestario,
                        permisosUsuario: parseInt( TBUsuarios.permisoUsuario),
                        estado:  TBUsuarios.estadoUser

                    }

                    const fechaActual = new Date();
                    const year = fechaActual.getFullYear();
                    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
                    const day = String(fechaActual.getDate()).padStart(2, '0');
                    const hours = String(fechaActual.getHours()).padStart(2, '0');
                    const minutes = String(fechaActual.getMinutes()).padStart(2, '0');
                    const seconds = String(fechaActual.getSeconds()).padStart(2, '0');
                    const tbHistorico = {
                        cedulaFunc: TBFuncionario.cedula,
                        cargo: parseInt(TBFuncionario.cargo),
                        tipoNombramiento: parseInt(TBFuncionario.tipoNombramiento),
                        fechaInicio: TBFuncionario.fechaIngreso,
                        fechaFinaliza: TBFuncionario.fechaSalida,
                        institucion: TBFuncionario.CodigoPresupuestario,
                        registradoPor: TBFuncionario.DatosPerfil[0].nombreCompleto + " " + TBFuncionario.DatosPerfil[0].apellido1 + " " + TBFuncionario.DatosPerfil[0].apellido2,
                        fechaRegistro: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.000`
                    }

                    await saveHistoricoNombramientos(tbHistorico)

                    const successUser = await UpdateUser(dataUser)
                    if (successUser.estado) {

                        TBFuncionario.setOpen(!TBFuncionario.open);
                        fetchData();

                    } else {
                        ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante durante el proceso de guardado.');
                        return;
                    }

                } else {
                    ErrorMessage('Advertencia', 'Ocurrio un error durante el proceso de guardado.');
                    return;
                }









            } else {
                ErrorMessage('Notificación del Sistema', success.mensaje || 'Ocurrió un error durante la solicitud al servidor.');
            }

        } else {
            ErrorMessage('Notificación del Sistema', 'Nivel de seguridad de la contraseña no apto.');
            return;
        }






    };


    const Seleccion = (e) => {
        const { checked, value } = e.target;

        if (checked) {
            // Agregar la asignatura seleccionada al estado
            TBFuncionario.setSeleccionadas([...TBFuncionario.Seleccionadas, value]);


        } else {
            // Eliminar la asignatura deseleccionada del estado
            TBFuncionario.setSeleccionadas(TBFuncionario.Seleccionadas.filter(asig => asig !== value));
        }

    };
 


    const onChange = (e) => {
        let fecha = e.target.value;
        TBFuncionario.setSelectedYear(fecha.toString());

    };


    const AsignarMaterias = async (event) => {
        event.preventDefault();

        if (TBFuncionario.Seleccionadas.length > 0) {
            if (TBFuncionario.selectedYear !== '') {
                let success = false;
                let existe = false;
                for (let i = 0; i < TBFuncionario.Seleccionadas.length; i++) {
                    const CodAsignatura = TBFuncionario.Seleccionadas[i];
                    TBAsignaturasDocente.setCodigoAsignatura(CodAsignatura)
                    //alert(CodAsignatura)
                    const dataExiste = {
                        asignatura: CodAsignatura,
                        docente: TBAsignaturasDocente.docente,
                        annio: TBAsignaturasDocente.selectedYear.toString(),
                        institucion: TBAsignaturasDocente.institucion
                    }


                    existe = await ExisteAsigDocente(dataExiste);
                    // alert(existe)
                    if (!existe) {

                        const data = {
                            asignatura: CodAsignatura,
                            docente: TBAsignaturasDocente.docente,
                            annio: TBAsignaturasDocente.selectedYear.toString(),
                            institucion: TBAsignaturasDocente.institucion,
                            estado: 1,

                        }
                        //alert(JSON.stringify(data));
                        success = await saveAsignaturaDocente(data);

                    } else {



                        const param = {
                            docente: TBAsignaturasDocente.docente,
                            annio: TBAsignaturasDocente.currentYear.toString(),
                            institucion: TBAsignaturasDocente.institucion,
                            estado: 0
                        }

                        const lista = await getAsignaturasDocente(param)
                        TBFuncionario.setListaAsigDocente(lista)
                        const asignatura = lista.find(asig => asig.codigoAsignatura === CodAsignatura);
                        if (asignatura) {
                            const asignaturaId = asignatura.id;
                            // Haz lo que necesites con el ID de la asignatura
                            console.log("ID de la asignatura:", asignaturaId);

                            const dataRestore = {
                                idAsignaturaProfe: parseInt(asignaturaId),
                                estado: 1,

                            }
                            success = await RestaurarAsignaturaDocente(dataRestore);

                        }

                    }



                    //alert(JSON.stringify(data));
                }
                if (success) {
                    SuccessMessage('Exitoso!', 'Asignaturas registradas existosamente')
                    TBFuncionario.setOpen2(!TBFuncionario.open2);

                    TBFuncionario.setSeleccionadas([])

                } else {
                    ErrorMessage('Notificación del Sistema', 'No se pudo registrar las asignaturas al docente seleccionado');
                }


            } else {
                ErrorMessage('Notificación del Sistema', 'Debes seleccionar el curso lectivo')
            }


        } else {
            ErrorMessage('Notificación del Sistema', 'Debes seleccionar al menos 1 asignatura')
        }

    }

    const ElimiamarAsigaduraADocente = async (event) => {
        event.preventDefault();

        if (TBFuncionario.Seleccionadas.length > 0) {
            let success = false;
            for (let i = 0; i < TBFuncionario.Seleccionadas.length; i++) {
                const id = TBFuncionario.Seleccionadas[i];

                //alert(CodAsignatura)


                const data = {
                    idAsignaturaProfe: parseInt(id),
                    estado: 0,

                }

                success = await deleteAsignaturaDocente(data);

                //alert(JSON.stringify(data));
            }
            if (success) {
                SuccessMessage('Exitoso!', 'Asignaturas retiradas existosamente')

                TBFuncionario.setSeleccionadas([])

                TBFuncionario.setOpen3(!TBFuncionario.open3);
            } else {
                ErrorMessage('Notificación del Sistema', 'No se pudo registrar las asignaturas al docente seleccionado');
            }
        } else {
            ErrorMessage('Notificación del Sistema', 'Debes seleccionar al menos 1 asignatura')
        }

    }

    const toggleAC = (id) => {
        if (TBFuncionario.openAC === id) {
            TBFuncionario.setOpenAC();
        } else {
            TBFuncionario.setOpenAC(id);
        }
    };

    const SeleccionarFecha = (e, set) => {
        set(e.target.value)
    }
    // Renderizar las instituciones
    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio "
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text=" Funcionarios"
                        to=""
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>




            <Container fluid className='p-0 Mymt-5 '>
                <Col className='mb-3 pb-2 border-bottom'>
                    <Button className='p-0  btn btnAceptar    '>
                        <ul>
                            <MenuItem
                                className=' p-0'
                                icon={<Fa6.FaCirclePlus className="me-1 sizeIcon2" />}
                                text="Funcionarios"
                                to="/Menu/FrmRegistrarFuncionario" // aca agregar la ruta
                                classname={'NoDecoration'}
                            />
                        </ul>
                    </Button>
                    <Button className='p-0 mx-2 btn btnAceptar  '>
                        <ul>
                            <MenuItem
                                className=' p-0'
                                icon={<Md.MdFolderDelete className="me-1 sizeIcon2" />}
                                text="Funcionarios Inact."

                                to={"/Menu/FrmFuncionariosInactivos"} // aca agregar la ruta
                                classname={'NoDecoration'}
                            />
                        </ul>
                    </Button>
                </Col>
                {TBFuncionario.funcionarios.length > 0 ? (
                    <Row>
                        {TBFuncionario.funcionarios
                            .filter(Funcionario => ![1, 4, 5, 6, 7].includes(Funcionario.cargo))
                            .map((Funcionario, index) => (
                                <Col className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 pb-2 px-2 ' >
                                    <Accordion flush className='mt-1  ' open={TBFuncionario.openA} toggle={() => toggleA(index)}>
                                        <AccordionItem className=' mb-3 rounded-0'>
                                            <AccordionHeader className='shadow rounded-0 accordionBorder accordion-button2 accordion-button2-pr-0' targetId={index}>

                                                <Row className='px-3' >


                                                    <Col className=' col-10 p-0'>
                                                        <Label className='font-size'><strong>Funcionario:</strong> <br /> {Funcionario.nombreCompleto} {Funcionario.apellido1} {Funcionario.apellido2}</Label>
                                                    </Col>
                                                    <Col className=' col-2'>
                                                        <div className="text-center mt-0">
                                                            {Funcionario.foto !== null ? (
                                                                <img src={`data:image/jpeg;base64,${removeBase64Prefix(Funcionario.foto)}`} alt="Foto de institución" className=" FotoPerfil2 p-0" />
                                                            ) : (
                                                                <img src={Fotop} alt="Imagen por defecto" className="FotoPerfil2 p-2" />
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AccordionHeader>
                                            <AccordionBody className='shadow rounded-0' accordionId={index}>
                                                <Row>
                                                    <Col md={12} className=' accordionBorder pb-2'>
                                                        {/* <strong> Cedula: </strong><br />{Funcionario.cedulaFuncionario}<br /> */}
                                                        <Label><strong>Cargo:</strong></Label> <br /><Label>{Funcionario.CargoC} </Label> <br />
                                                        <Label><strong>Telefono: </strong> </Label><br /><Label>{Funcionario.telefonoPrincipal} / {Funcionario.telefono1}</Label>

                                                    </Col>

                                                    <Col md={12} className='d-flex justify-content-end mt-2 '>
                                                        <Button color='' className='mx-1 btn-editar' onClick={(e) => Abrirmodal(Funcionario.cedulaFuncionario)} >Actualizar</Button>
                                                        <Button color=' ' type='button' className='mx-1 btn-eliminar'
                                                            onClick={(e) => Eliminar(
                                                                {
                                                                    usuario: Funcionario.cedulaFuncionario,
                                                                    idInstitucion: TBFuncionario.CodigoPresupuestario,
                                                                    estado: 0
                                                                },
                                                                {
                                                                    idNombramiento: Funcionario.codigoNombramientoInstitucion,
                                                                    estado: 0
                                                                }
                                                            )}
                                                        >
                                                            Suspender
                                                        </Button>


                                                    </Col>
                                                </Row>
                                            </AccordionBody>

                                        </AccordionItem>
                                    </Accordion>
                                </Col>

                            ))}
                    </Row>
                ) : (
                    <Container fluid className="pt-2 ">
                        <h1 className='text-center mt-5 pt-5'>{TBFuncionario.mensaje}</h1>
                        <h4 className='text-center mt-5 mb-5'>{TBFuncionario.error}</h4>
                    </Container>
                )}
                <Button type="button" className='p-0 btn   btnCancelar px-3 d-flex justify-content-start mt-auto' id='GuardarInstitucion'>
                    <ul>
                        <MenuItem
                            className=' '
                            icon={<IoMdReturnLeft className="me-1" />}
                            text="Volver"

                            to="/Menu/"
                            classname={'NoDecoration'}
                        />
                    </ul>
                </Button>
            </Container>

            <Modal isOpen={TBFuncionario.open} size='xl' fullscreen='lg' className='mt-2'>
                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-1' >   Actualizar Informacion del Docente   </h5>
                </ModalHeader>
                <ModalBody className='ModalBody   p-0'>
                    <Container fluid className='  px-3  border rounded  ' id='institucion'  >
                        <Form onSubmit={ActualizarFuncionario} className='py-2'>

                            <Accordion className='mb-5  ' flush open={TBFuncionario.openAC} toggle={toggleAC}>

                                <AccordionItem className='mb-3 '>
                                    <AccordionHeader targetId="1" className=' accordionBorder accordion-button2'>Información Personal </AccordionHeader>
                                    <AccordionBody accordionId="1" className='shadow4'>
                                        <Row>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="Provincia">
                                                        Nacionalidad
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="NacionalidadFuncionario"
                                                        name="NacionalidadFuncionario"
                                                        placeholder="NacionalidadFuncionario"
                                                        type="select"
                                                        defaultValue={TBFuncionario.pais}
                                                        onChange={(e) => TBFuncionario.setPais(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected  >Seleccione la Nacionalidad</option>
                                                        {TBFuncionario.ListaPaises.map((paises, index) => (
                                                            <option key={paises.id} value={paises.id}  >{paises.nombre}</option>
                                                        ))}

                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md={9}></Col>

                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="identificacion" className="me-2">
                                                        Identificación
                                                    </Label>

                                                    <Input

                                                        required
                                                        id="identificacion"
                                                        name="identificacion"
                                                        placeholder="0-0000-0000"
                                                        type="text"
                                                        value={TBFuncionario.cedula}
                                                        onChange={(event) => {
                                                            ValidarSoloNumeros(event, TBFuncionario.setCedula, TBFuncionario.setcedulaValidad);
                                                            TBUsuarios.setUsuario(event.target.value);
                                                        }}
                                                        maxLength={12}
                                                        minLength={9}
                                                        className={TBFuncionario.cedulaValida ? ' ' : 'is-invalid  '}
                                                    />

                                                    {!TBFuncionario.cedulaValida && <div className="invalid-feedback">Formato de Cedula no valido</div>}


                                                </FormGroup>
                                            </Col>

                                            <Col md={3} >
                                                <FormGroup >
                                                    <Label for="nombre" className="me-2">
                                                        Nombre
                                                    </Label>
                                                    <Input

                                                        required
                                                        id="nombre"
                                                        name="nombre"
                                                        placeholder=""
                                                        type="text"
                                                        value={TBFuncionario.nombre}
                                                        onChange={(event) => ValidarSoloLetras(event, TBFuncionario.setNombre, TBFuncionario.setNombreValido)}
                                                        className={TBFuncionario.nombreValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!TBFuncionario.nombreValido && <div className="invalid-feedback">No se admiten caracteres numericos.</div>}
                                                </FormGroup>

                                            </Col>

                                            <Col md={3} >
                                                <FormGroup >
                                                    <Label for="primerApellido" className="me-2">
                                                        Primer Apellido
                                                    </Label>
                                                    <Input

                                                        required
                                                        id="primerApellido"
                                                        name="primerApellido"
                                                        placeholder=""
                                                        type="text"
                                                        value={TBFuncionario.apellido1}
                                                        onChange={(event) => ValidarSoloLetras(event, TBFuncionario.setapellido1, TBFuncionario.setapellido1Valido)}
                                                        className={TBFuncionario.apellido1Valido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!TBFuncionario.apellido1Valido && <div className="invalid-feedback">No se admiten caracteres numericos.</div>}
                                                </FormGroup>

                                            </Col>

                                            <Col md={3} >
                                                <FormGroup >
                                                    <Label for="segundoApellido" className="me-2">
                                                        Segundo Apellido
                                                    </Label>
                                                    <Input

                                                        required
                                                        id="segundoApellido"
                                                        name="segundoApellido"
                                                        placeholder=""
                                                        type="text"
                                                        value={TBFuncionario.apellido2}
                                                        onChange={(event) => ValidarSoloLetras(event, TBFuncionario.setapellido2, TBFuncionario.setapellido2Valido)}
                                                        className={TBFuncionario.apellido2Valido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!TBFuncionario.apellido2Valido && <div className="invalid-feedback">No se admiten caracteres numericos.</div>}
                                                </FormGroup>

                                            </Col>

                                            <Col md={4} className='py-1'>
                                                <FormGroup>
                                                    <Label for="Sexo">
                                                        Sexo
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="Sexo"
                                                        name="Sexo"
                                                        placeholder="NacionalidadFuncionario"
                                                        type="select"
                                                        defaultValue={TBFuncionario.sexo}
                                                        onChange={(e) => TBFuncionario.setSexo(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected  >Seleccione el tipo Sexo</option>
                                                        {TBFuncionario.ListaSexo.map(sexo => (
                                                            <option key={sexo.id} value={sexo.id}>{sexo.nombre}</option>
                                                        ))}

                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md={3} className='py-1'>

                                                <FormGroup>
                                                    <Label for="files" className='pr-2'>
                                                        Foto de Perfil
                                                    </Label>
                                                    <Input
                                                        hidden
                                                        id="files"
                                                        name="files"
                                                        type="file"
                                                        accept='image/*'
                                                        onChange={(e) => ObtenerImagen(e, TBFuncionario.setSelectedImage, TBFuncionario.setfotoPerfil)}
                                                    />
                                                    <Label for="files" className="custom-file-upload">Seleccionar Archivo</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} >

                                                {TBFuncionario.selectedImage ? (
                                                    <img src={TBFuncionario.selectedImage} className='FotoPerfil p-2' alt="Foto de Perfil" />
                                                ) : (
                                                    <img src={Foto} className='FotoPerfil p-2' alt="Foto de Perfil" />
                                                )}


                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem className='mb-3 '>
                                    <AccordionHeader targetId="2" className=' accordionBorder accordion-button2'>Lugar de Residencia </AccordionHeader>
                                    <AccordionBody accordionId="2" className='shadow4'>
                                        <Row>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="Provincia">
                                                        Provincia
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="ProvinciaFuncionario"
                                                        name="ProvinciaFuncionario"
                                                        placeholder="ProvinciaFuncionario"
                                                        type="select"
                                                        value={TBFuncionario.selectedProvinciaFuncionario}
                                                        onChange={(event) => GetCantones(event, TBFuncionario.setSelectedProvinciaFuncionario, TBFuncionario.setListaCantones)}
                                                    >
                                                        <option value="0" disabled selected  >Seleccione una Provincia</option>
                                                        {TBFuncionario.ListaProvincias.map(provincia => (
                                                            <option key={provincia.id} value={provincia.id}>{provincia.nombre}</option>
                                                        ))}

                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="CantonFuncionario">
                                                        Cantón
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="CantoFuncionarion"
                                                        name="CantonFuncionario"

                                                        type="select"
                                                        value={TBFuncionario.selectedCantonFuncionario}
                                                        onChange={(event) => GetDistritos(event, TBFuncionario.selectedProvinciaFuncionario, TBFuncionario.setSelectedCantonFuncionario, TBFuncionario.setListaDistritos)}

                                                    >
                                                        <option value="0" disabled selected  >Seleccione el Cantón</option>
                                                        {TBFuncionario.ListaCantones.map(canton => (
                                                            <option key={canton.id} value={canton.id}>{canton.nombre}</option>

                                                        ))}

                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="DistritoFuncionario">
                                                        Distrito
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="DistritoFuncionario"
                                                        name="DistritoFuncionario"

                                                        type="select"
                                                        value={TBFuncionario.selectedDistritoFuncionario}
                                                        onChange={(event) => GetBarrios(event, TBFuncionario.selectedProvinciaFuncionario, TBFuncionario.selectedCantonFuncionario, TBFuncionario.setSelectedDistritoFuncionario, TBFuncionario.setListaBarrios)}
                                                    >
                                                        <option value="0" disabled selected  >Seleccione el Distrito</option>
                                                        {TBFuncionario.ListaDistritos.map(distrito => (
                                                            <option key={distrito.id} value={distrito.id}>{distrito.nombre}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="BarrioFuncionario">
                                                        Barrio
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="BarrioFuncionario"
                                                        name="BarrioFuncionario"

                                                        type="select"
                                                        value={TBFuncionario.selectedBarrioFuncionario}


                                                        onChange={(e) => TBFuncionario.setSelectedBarrioFuncionario(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected>Seleccione el Barrio</option>
                                                        {TBFuncionario.ListaBarrios.map(barrio => (
                                                            <option key={barrio.id} value={barrio.id}>{barrio.nombre}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md={12} className='py-1'>
                                                <Label for="DireccionFuncionario">
                                                    Dirección
                                                </Label>
                                                <Input
                                                    required
                                                    id="DireccionFuncionario"
                                                    name="DireccionFuncionario"
                                                    placeholder="200 mt al este de la calle 10"
                                                    value={TBFuncionario.DireccionFuncionario}
                                                    onChange={(e) => TBFuncionario.setDireccionFuncionario(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem className='mb-3 '>
                                    <AccordionHeader targetId="3" className=' accordionBorder accordion-button2'>Información de Contacto </AccordionHeader>
                                    <AccordionBody accordionId="3" className='shadow4'>
                                        <Row>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="telefonfuncionarioo">
                                                        Telefono Principal
                                                    </Label>
                                                    <Input
                                                        maxLength={8}
                                                        minLength={8}
                                                        id="telefonofuncionario"
                                                        name="telefonofuncionario"
                                                        placeholder=""
                                                        type="tel"
                                                        value={TBFuncionario.telefonoFunPrincipal}
                                                        onChange={(event) => ValidarSoloNumeros(event, TBFuncionario.setTelefonoFunPrincipal, TBFuncionario.setTelefonoFunPrincipalvalido)}
                                                        className={TBFuncionario.telefonoFunPrincipalValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!TBFuncionario.telefonoFunPrincipalValido && <div className="invalid-feedback">Solo se adminten caracteres numericos.</div>} {/* Mostrar mensaje de error si el número de teléfono no es válido */}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="telefonSecundariofuncionarioo">
                                                        Telefono Secundario
                                                    </Label>
                                                    <Input
                                                        maxLength={8}
                                                        minLength={8}
                                                        id="telefonoSecundariofuncionario"
                                                        name="telefonoSecundariofuncionario"
                                                        placeholder=""
                                                        type="tel"
                                                        value={TBFuncionario.telefonoFunSecundario}
                                                        onChange={(event) => ValidarSoloNumeros(event, TBFuncionario.setTelefonoFunSecundario, TBFuncionario.setTelefonoFunSecundariovalido)}
                                                        className={TBFuncionario.telefonoFunSecundarioValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!TBFuncionario.telefonoFunSecundarioValido && <div className="invalid-feedback">Solo se adminten caracteres numericos.</div>} {/* Mostrar mensaje de error si el número de teléfono no es válido */}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} className='py-1'>
                                                <FormGroup>
                                                    <Label for="email">
                                                        Correo Electronico
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="email"
                                                        name="email"
                                                        placeholder=""
                                                        type="mail"
                                                        value={TBFuncionario.correo}
                                                        onChange={(event) => ValidarCorreo(event, TBFuncionario.setCorreo, TBFuncionario.setcorreoValido)}
                                                        className={TBFuncionario.correoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                    />
                                                    {!TBFuncionario.correoValido && <div className="invalid-feedback">Formato de correo no valido.</div>}

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem className='mb-3 '>
                                    <AccordionHeader targetId="4" className=' accordionBorder accordion-button2'>Información Laboral </AccordionHeader>
                                    <AccordionBody accordionId="4" className='shadow4'>
                                        <Row>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="cargo">
                                                        Cargo
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="cargo"
                                                        name="cargo"
                                                        placeholder="Nombre centro Educativo"
                                                        type="select"
                                                        defaultValue={TBFuncionario.cargo}

                                                        onChange={(e) => TBFuncionario.setcargo(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected>Seleccione el Cargo</option>
                                                        {TBFuncionario.ListaCargos.filter((cargo, index) => [1, 2, 7, 8, 9, 10, 11, 12].includes(index)).map(listaCargo => (
                                                            <option key={listaCargo.id} value={listaCargo.id}>{listaCargo.nombre}</option>
                                                        ))}

                                                    </Input>

                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="tiponombramiento">
                                                        Tipo de Nombramiento
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="tiponombramiento"
                                                        name="tiponombramiento"

                                                        type="select"
                                                        defaultValue={TBFuncionario.tipoNombramiento}
                                                        onChange={(e) => TBFuncionario.setTipoNombramiento(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected>Selec. tipo de Nombramiento</option>
                                                        {TBFuncionario.ListaNombramientos.map(listaNombramiento => (
                                                            <option key={listaNombramiento.id} value={listaNombramiento.id}>{listaNombramiento.nombre}</option>
                                                        ))}

                                                    </Input>

                                                </FormGroup>
                                            </Col >
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="fechaingreso">
                                                        Fecha de Ingreso
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="fechaingreso"
                                                        name="fechaingreso"
                                                        placeholder=""
                                                        type="date"
                                                        value={TBFuncionario.fechaIngreso}
                                                        onChange={(e) => SeleccionarFecha(e, TBFuncionario.setFechaIngreso)}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="fechasalida">
                                                        Fecha de Salida
                                                    </Label>
                                                    <Input

                                                        id="fechasalida"
                                                        name="fechasalida"
                                                        placeholder=""
                                                        type="date"
                                                        value={TBFuncionario.fechaSalida}
                                                        onChange={(e) => SeleccionarFecha(e, TBFuncionario.setFechaSalida)}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="observaciones">
                                                        Observaciones
                                                    </Label>
                                                    <Input

                                                        id="observaciones"
                                                        name="observaciones"
                                                        placeholder=""
                                                        type="text"
                                                        value={TBFuncionario.Observaciones}
                                                        onChange={(e) => TBFuncionario.setObservaciones(e.target.value)}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem className='mb-3 '>
                                    <AccordionHeader targetId="5" className=' accordionBorder accordion-button2'>Información de Usuario </AccordionHeader>
                                    <AccordionBody accordionId="5" className='shadow4'>
                                        <Row>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for="usuario">
                                                        Usuario
                                                    </Label>
                                                    <Input
                                                        readOnly
                                                        required
                                                        id="usuario"
                                                        name="usuario"
                                                        placeholder=""
                                                        type="text"
                                                        value={ TBUsuarios.usuario}
                                                        onChange={(e) =>  TBUsuarios.setUsuario(e.target.value)}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <InputGroup className='d-flex justify-content-between '>
                                                        <Label for=" password">
                                                            Contraseña
                                                        </Label>
                                                        <InputGroupText addonType="append" className='p-0 mr-5  border-0 '>
                                                            <Button
                                                                onClick={(e) => ContraseñaSegura( TBUsuarios.setPassword)}
                                                                color=''
                                                                className='p-0 px-2 rounded btn-editar '
                                                                title='Generar contraseña segura'>
                                                                <Tb.TbPasswordFingerprint />
                                                            </Button>
                                                        </InputGroupText>
                                                    </InputGroup>

                                                    <InputGroup>
                                                        <Input
                                                            required
                                                            id="password"
                                                            name="password"
                                                            placeholder=""
                                                            type={TBFuncionario.mostrarContrasena ? "text" : "password"} // Cambia el tipo de input según el estado
                                                            value={ TBUsuarios.password}
                                                            onChange={Contraseña_TextChanged}
                                                        />
                                                        <InputGroupText addonType="append" className='p-0'>
                                                            <Button onClick={MostrarContrasena} color="" className='p-0 px-2'>
                                                                {TBFuncionario.mostrarContrasena ? <FiEyeOff /> : <FiEye />} {/* Cambia el ícono según el estado */}
                                                            </Button>
                                                        </InputGroupText>
                                                    </InputGroup>
                                                    {NivelSeguridad && (
                                                        <div>
                                                            {nivelSeguridad}
                                                            <Progress

                                                                value={nivelSeguridad === NivelSeguridad.MuySegura ? 100 : nivelSeguridad === NivelSeguridad.MediaSegura ? 75 : nivelSeguridad === NivelSeguridad.PocoSegura ? 25 : 1}
                                                                color={nivelSeguridad === NivelSeguridad.MuySegura ? 'success' : nivelSeguridad === NivelSeguridad.MediaSegura ? 'warning' : nivelSeguridad === NivelSeguridad.PocoSegura ? 'danger' : 'danger'}
                                                            />
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3} className='py-1'>
                                                <FormGroup>
                                                    <Label for=" tipoUsuario">
                                                        Tipo Usuario
                                                    </Label>
                                                    <Input
                                                        required
                                                        id="tipoUsuario"
                                                        name=" tipoUsuario"
                                                        placeholder=""
                                                        type="select"
                                                        defaultValue={ TBUsuarios.permisoUsuario}
                                                        onChange={(e) =>  TBUsuarios.setPermisoUsuario(e.target.value)}
                                                    >
                                                        <option value="0" disabled selected>Selec. roll de Usuario</option>
                                                        {TBFuncionario.ListaRoles.slice(1).map(roles => (
                                                            <option key={roles.id} value={roles.id}>{roles.nombre}</option>
                                                        ))}
                                                    </Input>


                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </AccordionBody>
                                </AccordionItem>

                            </Accordion>
                            <Col className='d-flex justify-content-between'>
                                <Button type="button" onClick={toggle} className=' p-1     btnCancelar px-3  d-flex justify-content-start' id='volver'>
                                    <IoMdReturnLeft className="me-2 mt-1" /> Volver
                                </Button>
                                <Button type="submit" className=' p-1 px-3 btnAceptar ' id='actualizarInstitucion'>
                                    Actualizar Funcionario
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

            {/* <Modal isOpen={open2} size='lg' fullscreen='lg' className='mt-4'>
                <ModalBody className='ModalBody rounded'>
                    <Container fluid className='  p-3 shadow border rounded  ' id='institucion'  >
                        <Form onSubmit={AsignarMaterias}>
                            <h5 className='py-2' > Asignar Materias  </h5>
                            <Row>
                                <Col md={12} className='py-0 '>
                                    <FormGroup className='border-bottom'>
                                        <Label for="Provincia">
                                            <strong><span>Institucion:</span></strong> {institucion} {" - "} {NombreInst} <br />
                                            <strong><span>Docente:</span></strong> {nombre} {apellido1} {apellido2}
                                        </Label>

                                    </FormGroup>
                                </Col>

                                <Col md={12} className='py-0'>
                                    <FormGroup>
                                        <Label for="Provincia">
                                            <strong><span>Lista de Asignaturas disponibles</span></strong>
                                        </Label>
                                        <Col md={12} className=' border rounded p-2'>
                                            <Row>
                                                {Array.isArray(Asignaturas) && Asignaturas.map((asig, index) => (
                                                    <Col md={3} key={index}>

                                                        <FormGroup check>
                                                            <Input type="checkbox"
                                                                id={`checkbox-${index}`}
                                                                value={asig.codigoAsignatura}
                                                                onChange={Seleccion}
                                                                checked={Seleccionadas.includes(asig.codigoAsignatura) || (Array.isArray(ListaAsigDocente) && ListaAsigDocente.some(docAsig => docAsig.codigoAsignatura === asig.codigoAsignatura))}
                                                                disabled={Array.isArray(ListaAsigDocente) && ListaAsigDocente.some(docAsig => docAsig.codigoAsignatura === asig.codigoAsignatura)} />
                                                            <Label check className='negrita' htmlFor={`checkbox-${index}`}>
                                                                {asig.nombreAsignatura}
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                ))}
                                            </Row>

                                        </Col>
                                        <span className='LetraPeq text-danger'> * Las asignatura que se encuentra inhabilitadas ya se encuentran asignadas al Docente </span>
                                    </FormGroup>
                                </Col>
                                <Col md={6} className='py-0'>
                                    <FormGroup>
                                        <Label for="Provincia">
                                            <strong><span>Curso lectivo</span></strong>
                                        </Label>
                                        <Input
                                            type='number'
                                            min={2024}
                                            max={2100}
                                            value={selectedYear}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Col className='d-flex justify-content-between mt-3'>
                                <Button type="button" onClick={toggle2} className=' p-1     btnCancelar px-3  d-flex justify-content-start' id='GuardarInstitucion'>
                                    <IoMdReturnLeft className="me-2 mt-1" /> Volver
                                </Button>
                                <Button type="submit" className=' p-1 px-3 btnAceptar ' id='GuardarInstitucion'>
                                    Aplicar
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

            <Modal isOpen={open3} size='lg' fullscreen='lg' className='mt-4 '>
                <ModalBody className='ModalBody rounded'>
                    <Container fluid className='  p-3 shadow border rounded bg-white ' id='institucion'  >
                        <Form onSubmit={ElimiamarAsigaduraADocente}>
                            <h5 className='py-2' > Retirar Asignaturas  </h5>
                            <Row>
                                <Col md={12} className='py-0 '>
                                    <FormGroup className='border-bottom'>
                                        <Label for="Provincia">
                                            <strong><span>Institucion:</span></strong> {institucion} {" - "} {NombreInst} <br />
                                            <strong><span>Docente:</span></strong> {nombre} {apellido1} {apellido2}
                                        </Label>

                                    </FormGroup>
                                </Col>

                                <Col md={12} className='py-0'>
                                    <FormGroup>
                                        <Label for="Provincia">
                                            <strong><span>Lista de asignaturas impartidas actualmente por el Docente </span></strong>
                                        </Label>
                                        <Col md={12} className=' border rounded p-2'>
                                            <Row>
                                                {Array.isArray(ListaAsigDocente) && ListaAsigDocente.length > 0 ? (
                                                    ListaAsigDocente.map((asigL, index) => (
                                                        <Col md={3} key={index}>
                                                            <FormGroup check>
                                                                <Input
                                                                    id={`checkbox-${index}`}
                                                                    type="checkbox"
                                                                    value={asigL.id}
                                                                    onChange={Seleccion}
                                                                    checked={Seleccionadas.includes(asigL.id.toString())} />
                                                                <Label check className='negrita' htmlFor={`checkbox-${index}`}>
                                                                    {asigL.nombreAsignatura}<br />
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    ))
                                                ) : (
                                                    <Col md={12}>
                                                        <p>El Docente seleccionado NO posee asignaturas otorgadas </p>
                                                    </Col>
                                                )}
                                            </Row>

                                        </Col>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Col className='d-flex justify-content-between mt-3'>
                                <Button type="button" onClick={toggle3} className=' p-1     btnCancelar px-3  d-flex justify-content-start' id='GuardarInstitucion'>
                                    <IoMdReturnLeft className="me-2 mt-1" /> Volver
                                </Button>
                                <Button type="submit" className=' p-1 px-3 btnAceptar ' id='GuardarInstitucion'>
                                    Aplicar
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal> */}
        </Container>
    );
};

export default FrmFuncionarios;


