import { useState } from 'react';


/**
 * 
 * @returns Retorna las atributos con sus propiedades Get y Set de la TbFuncionario
 */
export const TbEstudiante = () => {

    const [tipoIdentificacion, setTipoIdentificacion] = useState(0);
    const [cedula, setCedula] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido1, setapellido1] = useState('');
    const [apellido2, setapellido2] = useState('');
    const [edad, setEdad] = useState('0 años 0 meses y 0 días');
    const [lugarNacimiento, setlugarNacimiento] = useState('');
    const [pais, setPais] = useState(41);
    const [provincia, setProvincia] = useState('');
    const [condicion, setCondicion] = useState(0);
    const [canton, setCanton] = useState('');
    const [distrito, setDistrito] = useState('');
    const [barrio, setBarrio] = useState('');
    const [direccionExacta, setDireccionExacta] = useState('');
    const [telefono, setTelefono] = useState('');
    const [celular, setCelular] = useState('');
    const [correo, setCorreo] = useState('');
    const [fotoPerfil, setfotoPerfil] = useState(null);
    const [telefonoEmergencia, setTelefonoEmergencia] = useState('');
    const [genero, setGenero] = useState('');
    const [casoEmergencia, setCasoEmergencia] = useState();
    const [fechaNacimiento, setFechaNacimiento] = useState('');

    const [nombreValido, setNombreValido] = useState(true);
    const [apellido1Valido, setapellido1Valido] = useState(true);
    const [apellido2Valido, setapellido2Valido] = useState(true);
    const [telefonoValido, setTelefonoValido] = useState(true);
    const [celularValido, setcelularValido] = useState(true);
    const [emergenciaValido, setEmergenciaValido] = useState(true);
    const [correoValido, setCorreoValido] = useState(true);
    const [cedulaValida, setCedulaValidad] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);


    const [ListaSexo, setListaSexo] = useState([]);
    const [ListaPaises, setListaPaises] = useState([]);
    const [ListaProvincias, setListaProvincias] = useState([]);
    const [ListaCantones, setListaCantones] = useState([]);
    const [ListaDistritos, setListaDistritos] = useState([]);
    const [ListaBarrios, setListaBarrios] = useState([]);
   
    const [ListaTipoID, setListaTipoID]  = useState([]);


    const [actualizar, setActualizar]=useState(true);



    return {
        tipoIdentificacion, setTipoIdentificacion,
        cedula, setCedula,
        nombre, setNombre,
        apellido1, setapellido1,
        apellido2, setapellido2,
        genero, setGenero,
        fechaNacimiento, setFechaNacimiento,
        edad, setEdad,
        lugarNacimiento, setlugarNacimiento,
        fotoPerfil, setfotoPerfil,
        pais, setPais,
        condicion, setCondicion,
        provincia, setProvincia,
        canton, setCanton,
        distrito, setDistrito,
        barrio, setBarrio,
        direccionExacta, setDireccionExacta,
        telefono, setTelefono,
        celular, setCelular,
        correo, setCorreo,
        telefonoEmergencia, setTelefonoEmergencia,
        casoEmergencia, setCasoEmergencia,

        nombreValido, setNombreValido,
        apellido1Valido, setapellido1Valido,
        apellido2Valido, setapellido2Valido,
        telefonoValido, setTelefonoValido,
        celularValido, setcelularValido,
        emergenciaValido, setEmergenciaValido,
        correoValido, setCorreoValido,
        cedulaValida, setCedulaValidad,
        selectedImage, setSelectedImage,

  
        ListaSexo, setListaSexo,
        ListaPaises, setListaPaises,
        ListaProvincias, setListaProvincias,
        ListaCantones, setListaCantones,
        ListaDistritos, setListaDistritos,
        ListaBarrios, setListaBarrios,

        ListaTipoID, setListaTipoID,

        actualizar, setActualizar,
    };
};