import React, { useState, useEffect } from 'react';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import MenuItem from '../../../components/MenuItem';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, AccordionItem, Accordion, AccordionBody, AccordionHeader, Table, ModalHeader } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as Fa6 from "react-icons/fa6";
import * as Fc  from "react-icons/fc";
import * as Bi from "react-icons/bi";
import * as Md from "react-icons/md";
import * as Tb from "react-icons/tb";
import * as Io from "react-icons/io";
import * as  Fa from "react-icons/fa";
import { CambiarEstadoEdificios, EditarEdificios, GetListaEdificios, RegistrarEdificios } from '../../../../CapaDatos/DatosEdificios';
import { obtenerIdInstitucion } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { Descargar, ErrorMessage, SuccessMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { TbEdificios } from '../../../../CapaEntidades/TbEdificios';
import { GetTipoEdificios } from '../../../../CapaDatos/DatosCargarSelects';
import Swal from 'sweetalert2';

let globalData = null;
let globalData2 = null;



const FrmEdificios = ({ codigoPresupuestario, codigoNombre }) => {

    const TBEdifios = TbEdificios();


   
    TBEdifios.CodigoPresupuestario = codigoPresupuestario;
    TBEdifios.CodigoNombre = codigoNombre;

    const fetchData = async () => {
        try {
            GetListaEdificios(TBEdifios.setCbListaEdificios)

            const parametros = {
                institucion: obtenerIdInstitucion(),
                estado: 1
            }
            const lista = await GetListaEdificios(parametros);

            const parametros2 = {
                institucion: obtenerIdInstitucion(),
                estado: 0
            }
            const lista2 = await GetListaEdificios(parametros2);

            globalData= lista;
            globalData2= lista2;

            TBEdifios.setListaEdificios(lista);
            TBEdifios.setListaEdificiosRemodelacion(lista2);

            GetTipoEdificios(TBEdifios.setCbListaEdificios)

            TBEdifios.setLoading(false);
        } catch (error) {
            TBEdifios.setLoading(false);
        }
    }

    useEffect(() => {
       
            fetchData();
       
    }, []);


    const [orden, setOrden] = useState({
        columna: null,
        ascendente: true
    });

    const [filtros, setFiltros] = useState({
        nombre: '',
        nombreTipoEdificio: '',
    });


    const OrdenarColumnas = (columna) => {
        setOrden(prevOrden => ({
            columna: columna,
            ascendente: prevOrden.columna === columna ? !prevOrden.ascendente : true
        }));
    };
    const obtenerIconoOrden = (columna) => {
        if (orden.columna === columna) {
            return orden.ascendente ? <Io.IoMdArrowDropup /> : <Io.IoMdArrowDropdown />
        }
        return null; // Retorna null si la columna no está siendo ordenada
    };
    const ordenarDatos = (datos) => {

        if (orden.columna) {
            return datos.slice().sort((a, b) => {
                const columnaA = a[orden.columna];
                const columnaB = b[orden.columna];
                if (typeof columnaA === 'string' && typeof columnaB === 'string') {
                    return orden.ascendente ? columnaA.localeCompare(columnaB) : columnaB.localeCompare(columnaA);
                } else {
                    return orden.ascendente ? columnaA - columnaB : columnaB - columnaA;
                }
            });
        } else {
            return datos;
        }
    };
    const Filtrar = (e, columna) => {
        const valor = e.target.value;
        setFiltros(prevFiltros => ({
            ...prevFiltros,
            [columna]: valor
        }));
    };
    const filtrarDatos = (datos) => {
        if (!Array.isArray(datos)) {
            console.error("Los datos proporcionados no son un arreglo.");
            return [];
        }

        return datos.filter(activo => {
            return Object.entries(filtros).every(([columna, valorFiltro]) => {
                if (valorFiltro === '') return true;
                return activo[columna].toLowerCase().includes(valorFiltro.toLowerCase());
            });
        });
    };
    if (TBEdifios.loading) {
        return (
            <Cargando />
        );
    }

    const IrDescargar = (nombre, array) => {
        if (array && array.length > 0) {

            Descargar(array, nombre)
        } else {
            ErrorMessage('Notificación del Sistema', 'No hay edificios para descargar')
        }
    }
    const toggleModalRegistrar = () => {
        TBEdifios.setOpenModalRegistrar(!TBEdifios.openModalRegistrar);

    }

    const toggleModalEditar = () => {
        TBEdifios.setOpenModalEditar(!TBEdifios.openModalEditar);

    }

    const AbrirmodalRegistar = () => {

        TBEdifios.setOpenModalRegistrar(!TBEdifios.openModalRegistrar)
    }

    const AbrirmodalEditar = (data, array) => {
        const EdificioSeleccioando = array.find(edificio => edificio.codigo === data);
        TBEdifios.setCodigoEdificio(EdificioSeleccioando.codigo)
        TBEdifios.setNombreEdificio(EdificioSeleccioando.nombre);
        TBEdifios.setTipoEdificio(EdificioSeleccioando.tipo)

        TBEdifios.setOpenModalEditar(!TBEdifios.openModalEditar)
    }

    const RegistrarEdificio = async (event) => {
        event.preventDefault();
        let tipo = TBEdifios.tipoEdificio
        if ( tipo !== 0) {
            TBEdifios.setErrorTipoEdificio(false);
            const parametros = {
                nombreEdificio: TBEdifios.nombreEdificio,
                tipoEdificio: parseInt(TBEdifios.tipoEdificio),
                institucionEdificio: obtenerIdInstitucion(),
                
            }
            const Success = await RegistrarEdificios(parametros);
            if (Success) {
                TBEdifios.setOpenModalRegistrar(!TBEdifios.openModalRegistrar);
                fetchData();
                SuccessMessage('Exitoso', 'Edificio registrado correctamente')
            }else{
                ErrorMessage('Notificación del sistema','Error al registrar el edificio')
                return;
            }
            
        } else {
            TBEdifios.setErrorTipoEdificio(true);  
        }
    }


    const EditarEdificio = async (event) => {
        event.preventDefault();
        let tipo = TBEdifios.tipoEdificio
        if ( tipo !== 0) {
            TBEdifios.setErrorTipoEdificio(false);
            const parametros = {
                codigoEdificio: TBEdifios.codigoEdificio,
                nombreEdificio: TBEdifios.nombreEdificio,
                tipoEdificio: parseInt(TBEdifios.tipoEdificio),
                
            }
            const Success = await EditarEdificios(parametros);
            if (Success) {
                TBEdifios.setOpenModalEditar(!TBEdifios.openModalEditar);
                fetchData();
                SuccessMessage('Exitoso', 'Edificio registrado correctamente')
            }else{
                ErrorMessage('Notificación del sistema','Error al registrar el edificio')
                return;
            }
            
        } else {
            TBEdifios.setErrorTipoEdificio(true);  
        }
    }

    const InhabilitarEdificio = async (param) => {
       
       
        Swal.fire({
          title: "Estas seguro que deseas inhabilitar el edificio?",
          text: "El esdificio estara en remodelación",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "¡Sí, Inhabilitar!",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
    
            const success = await CambiarEstadoEdificios(param);
    
            if (success) {
              Swal.fire({
                title: "¡Edificio Inhabilitado!",
                text: "El edificio se enceuntra inhabilitado",
                icon: "success",
                timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
                timerProgressBar: true, // Barra de progreso de tiempo
                showConfirmButton: false
              });
              fetchData();
            } else {
              ErrorMessage('Notificación del Sistema', success.mensaje);
              return;
    
            }
    
          }
        });
      };

      const RestaurarEdificio = async (param) => {
       
       
        Swal.fire({
          title: "Estas seguro que deseas Restaurar el edificio?",
          text: "El dificio estara disponible nuevamente",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "¡Sí, Restaurar!",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
    
            const success = await CambiarEstadoEdificios(param);
    
            if (success) {
              Swal.fire({
                title: "¡Edificio Restaurado!",
                text: "El edificio se enceuntra habilitado",
                icon: "success",
                timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
                timerProgressBar: true, // Barra de progreso de tiempo
                showConfirmButton: false
              });
              fetchData();
            } else {
              ErrorMessage('Notificación del Sistema', success.mensaje);
              return;
    
            }
    
          }
        });
      };

    return (
        <Container fluid className=" Divcontenedor ">

            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Edificios"
                        to="/Menu/FrmEdificios"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>

            <Container fluid className='p-0 Mymt-5'>
                <Col className='mb-2 pb-2 border-bottom'>
                    <Button className='p-1  btn btnAceptar    ' onClick={AbrirmodalRegistar}>
                        <Fa6.FaCirclePlus className="me-1 " size={18} />
                        Edificios
                    </Button>
                </Col>
            </Container>

            <Container fluid className='  px-0  ' >
                <Tabs className={'p-0 m-0  border  '}>
                    <TabList className={'tabs_ '} >
                        <Tab>Instalaciones</Tab>
                        <Tab>Instalaciones en Remodelación</Tab>
                    </TabList>
                    <Container fluid className=' shadow2 px-0 cont rounded-0' >

                        <TabPanel>
                            <Table responsive size="sm">
                                <thead className='border-bottom'>
                                    <tr>
                                        <th className='pb-0'>
                                            <a className='cursor px-2' onClick={() => OrdenarColumnas('nombre')}>  Instalación {obtenerIconoOrden('nombre')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor px-2' onClick={() => OrdenarColumnas('nombreTipoEdificio')}>Tipo de Instalación {obtenerIconoOrden('nombreTipoEdificio')}</a>
                                        </th>
                                        <th colSpan={2} className='pb-0'>

                                            <Fa.FaDownload className='mx-2 edi2' cursor={'pointer'} color='#314843' size={18} onClick={(e) => IrDescargar('Lista de edificios', TBEdifios.ListaEdificios)} />

                                        </th>
                                    </tr>

                                    <tr className='bg-tr'>

                                        <th className='p-0 px-2 pb-1   '>
                                            <Col md={6} className="input-with-icon   ">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.nombre}
                                                    onChange={(e) => Filtrar(e, 'nombre')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col md={6} className="input-with-icon   ">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.tipo}
                                                    onChange={(e) => Filtrar(e, 'nombreTipoEdificio')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>

                                        <th colSpan={2} className='p-0 px-2 pb-1' > </th>
                                    </tr>
                                </thead>
                                <tbody className='tablebody' id='tablebody' >
                                    {ordenarDatos(filtrarDatos(TBEdifios.ListaEdificios)).length > 0 ? (
                                        ordenarDatos(filtrarDatos(TBEdifios.ListaEdificios)).map((edificio, index) => {
                                            // Verificar si el activo está asignado

                                            return (
                                                <tr className="table-p" key={index}>
                                                    <td>{edificio.nombre}</td>
                                                    <td>{edificio.nombreTipoEdificio}</td>
                                                    <td>
                                                        <Fa.FaEye title='Ver mas...' className='mb-1 edi2 ' cursor={'pointer'} color='#314843' size={18} onClick={(e) =>AbrirmodalEditar (edificio.codigo, TBEdifios.ListaEdificios)}/>
                                                    </td>
                                                    <td>
                                                        <Fa.FaTools title='Reportar como remodelación' className='mb-1  eli2 ' cursor={'pointer'} color='#314843' size={18} onClick={(e)=> InhabilitarEdificio({codigoEdificio : edificio.codigo, estado: 0})}/>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No hay activos ingresados en el sistema.</td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </TabPanel>

                        <TabPanel>
                            <Table responsive size="sm">
                                <thead className='border-bottom'>
                                    <tr>
                                        <th className='pb-0'>
                                            <a className='cursor px-2' onClick={() => OrdenarColumnas('nombre')}>  Instalación {obtenerIconoOrden('nombre')}</a>
                                        </th>
                                        <th className='pb-0'>
                                            <a className='cursor px-2' onClick={() => OrdenarColumnas('tipo')}>Tipo de Instalación {obtenerIconoOrden('tipo')}</a>
                                        </th>
                                        <th colSpan={2} className='pb-0'>
                                            <Fa.FaDownload className='mx-2 edi2' cursor={'pointer'} color='#314843' size={18} onClick={(e) => IrDescargar('Lista de edificios', TBEdifios.ListaEdificiosRemodelacion)} />
                                        </th>
                                    </tr>

                                    <tr className='bg-tr'>

                                        <th className='p-0 px-2 pb-1   '>
                                            <Col md={6} className="input-with-icon   ">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.nombre}
                                                    onChange={(e) => Filtrar(e, 'nombre')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>
                                        <th className='p-0 px-2 pb-1'  >
                                            <Col md={6} className="input-with-icon   ">
                                                <Input
                                                    type="text"
                                                    className="py-1"
                                                    value={filtros.tipo}
                                                    onChange={(e) => Filtrar(e, 'tipo')}
                                                />
                                                <Fa.FaFilter className="filter-icon" />
                                            </Col>

                                        </th>

                                        <th colSpan={2} className='p-0 px-2 pb-1' > </th>
                                    </tr>
                                </thead>
                                <tbody className='tablebody' id='tablebody' >
                                    {ordenarDatos(filtrarDatos(TBEdifios.ListaEdificiosRemodelacion)).length > 0 ? (
                                        ordenarDatos(filtrarDatos(TBEdifios.ListaEdificiosRemodelacion)).map((edificio, index) => {
                                            // Verificar si el activo está asignado

                                            return (
                                                <tr className="table-p" key={index}>
                                                    <td>{edificio.nombre}</td>
                                                    <td>{edificio.nombreTipoEdificio}</td>
                                                    <td>
                                                        <Fa.FaTrashRestore title='Ver mas...' className='mb-1 edi2 ' cursor={'pointer'} color='#314843' size={18}  onClick={(e)=> RestaurarEdificio({codigoEdificio : edificio.codigo, estado: 1})} />
                                                    </td>
                                                    
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No existen edificios actualmente en remodelación.</td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>
                        </TabPanel>

                    </Container>

                </Tabs>
            </Container>
            <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
                <ul>
                    <MenuItem
                        className='btnAceptar'
                        icon={<Io.IoMdReturnLeft className="me-1" />}
                        text="Volver"
                        to="/Menu"
                        classname={'NoDecoration'}
                    />
                </ul>
            </Button>
            <Modal isOpen={TBEdifios.openModalRegistrar} size='md' fullscreen='lg' fade className='mt-2 shadow2 ' backdrop="static">
                <ModalBody className='p-0' onSubmit={RegistrarEdificio}>
                    <Container fluid className='  p-3 shadow3 border rounded  ' id='institucion'  >
                        <Form id='form1' >
                            <h5 className='py-2' > Registrar Edificio </h5>
                            <Row >
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="cod.Presupuestario" className="me-2">
                                            Institución
                                        </Label>
                                        <Input
                                            readOnly
                                            type="text"
                                            name="cod.Presupuestario"
                                            id="cod.Presupuestario"
                                            placeholder="Código Presupuestario"
                                            value={TBEdifios.CodigoNombre}
                                            className='py-1'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="TipoEdificio" className="me-2">
                                            Tipo Edificio
                                        </Label>
                                        <Input
                                            required
                                            className={`py-1 ${TBEdifios.errorTipoEdificio ? 'is-invalid' : ''}`}
                                            type="select"
                                            name="TipoEdificio"
                                            id="TipoEdificio"
                                            value={TBEdifios.tipoEdificio}
                                            onChange={(e) => TBEdifios.setTipoEdificio(e.target.value)}
                                        >
                                            <option key={0} value={0} disabled selected>Seleccione un tipo de activo</option>
                                            {TBEdifios.cbListaEdificios.map((tipo, i) => (
                                                <option key={tipo.id} value={tipo.id}  >{tipo.nombre}</option>
                                            ))}
                                        </Input>

                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="edificio" className="me-2">
                                            Edificio
                                        </Label>
                                        <Input
                                           required
                                            type="text"
                                            name="edificio"
                                            id="edificio"
                                            placeholder="Ejemplo: Aula-1"
                                            value={TBEdifios.nombreEdificio}
                                            className='py-1'
                                            onChange={(e) => TBEdifios.setNombreEdificio(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Col className='d-flex justify-content-between mt-3'>
                                <Button type="button" onClick={toggleModalRegistrar} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <Io.IoMdReturnLeft className="me-2 mt-1" /> Volver
                                </Button>

                                <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarInstitucion'>
                                    Registrar Edificio
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

            <Modal isOpen={TBEdifios.openModalEditar} size='md' fullscreen='lg' fade className='mt-2 shadow2 ' backdrop="static">
                <ModalBody className='p-0' onSubmit={EditarEdificio}>
                    <Container fluid className='  p-3 shadow3 border rounded  ' id='institucion'  >
                        <Form id='form1' >
                            <h5 className='py-2' > Actualizar Edificio </h5>
                            <Row >
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="cod.Presupuestario" className="me-2">
                                            Institución
                                        </Label>
                                        <Input
                                            readOnly
                                            type="text"
                                            name="cod.Presupuestario"
                                            id="cod.Presupuestario"
                                            placeholder="Código Presupuestario"
                                            value={TBEdifios.CodigoNombre}
                                            className='py-1'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="TipoEdificio" className="me-2">
                                            Tipo Edificio
                                        </Label>
                                        <Input
                                            required
                                            className={`py-1 ${TBEdifios.errorTipoEdificio ? 'is-invalid' : ''}`}
                                            type="select"
                                            name="TipoEdificio"
                                            id="TipoEdificio"
                                            value={TBEdifios.tipoEdificio}
                                            onChange={(e) => TBEdifios.setTipoEdificio(e.target.value)}
                                        >
                                            <option key={0} value={0} disabled selected>Seleccione un tipo de activo</option>
                                            {TBEdifios.cbListaEdificios.map((tipo, i) => (
                                                <option key={tipo.id} value={tipo.id}  >{tipo.nombre}</option>
                                            ))}
                                        </Input>

                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="edificio" className="me-2">
                                            Edificio
                                        </Label>
                                        <Input
                                           required
                                            type="text"
                                            name="edificio"
                                            id="edificio"
                                            placeholder="Ejemplo: Aula-1"
                                            value={TBEdifios.nombreEdificio}
                                            className='py-1'
                                            onChange={(e) => TBEdifios.setNombreEdificio(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Col className='d-flex justify-content-between mt-3'>
                                <Button type="button" onClick={toggleModalEditar} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <Io.IoMdReturnLeft className="me-2 mt-1" /> Volver
                                </Button>

                                <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarInstitucion'>
                                    Actualizar Edificio
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

        </Container>
    )
}

export default FrmEdificios
