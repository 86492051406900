import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, AccordionItem, Accordion, AccordionBody, AccordionHeader, ModalHeader } from 'reactstrap';
import { TbSecciones } from '../../../../CapaEntidades/TbSecciones';
import { RestoreSeccion, deleteSeccion, getSecciones, updateSeccion } from '../../../../CapaDatos/DatosSecciones';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import MenuItem from '../../../components/MenuItem';
import Espaciador from '../../../components/Espaciador';
import * as Fa6 from "react-icons/fa6";
import * as Bi from "react-icons/bi";
import * as Fas from 'react-icons/fa';
import { IoMdReturnLeft } from "react-icons/io";
import { GetGrados } from '../../../../CapaDatos/DatosCargarSelects';
import { ValidarLetrasNumerosGuion, ValidarSoloLetras, ValidarSoloNumeros } from '../../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import { ErrorMessage, SuccessMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Swal from 'sweetalert2';
import { TbFuncionario } from '../../../../CapaEntidades/TbFuncionario';
import { getFuncionariosActivos2 } from '../../../../CapaDatos/DatosFuncionarios';
import { EliminarSeccionDocente, ExisteSeccionDocente, GetListSeccDocent, RestaurarSeccionDocente, SaveSeccionDocente, getListaSeccionesDocente, getSeccionesDocente } from '../../../../CapaDatos/DatosSeccionesDocente';
import { TbSeccionFuncionario } from '../../../../CapaEntidades/TbSeccionFuncionario';
import { ObtenerNombreInst } from '../../../../CapaUtilidades/Utils/encryptUserData';


let globalData = null;

const FrmSecciones = () => {
    const currentDate = new Date(); // Obtiene la fecha actual
    const currentYear = currentDate.getFullYear(); // Obtiene el año actual
    const [loading, setLoading] = useState(true);
    const [Secciones, setSecciones] = useState([]);
    const [funcionarios, setFuncionarios] = useState([]);
    const [Seleccionadas, setSeleccionadas] = useState([]);
    const [ListaSeccionesDocente, setListaSeccionesDocente] = useState([]);
    const [ListSeccDocent, setListSeccDocent] = useState([]);
    const [ListaAsigDocente, setListaAsigDocente] = useState([]);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [ListaGrados, setListaGrados] = useState([]);
    const [openA, setOpenA] = useState('');

    const {
        IdSeccion, setIDSeccion,
        NombreSeccion, setNombreSeccion,
        Nivel, setNivel,
        CantMax, setCantMax,
        IdInstitucion, setIdInstitucion,
        NombreInst, setNombreInst,
        EstadoSeccion, setEstadoSeccion,
        CantidadValida, setCantidadValida,
        SeccionValida, setSeccionValida,

    } = TbSecciones();

    const {
        seccion, setSeccion,
        docente, setDocente,
        selectedYear, setSelectedYear,
        institucion, setInstitucion,
        estadoSeccFun, setEstadoSeccFun
    } = TbSeccionFuncionario();

    const {
        cedula, setCedula,
        nombre, setNombre,
        apellido1, setapellido1,
        apellido2, setapellido2,
        cargo, setcargo,
        tipoNombramiento, setTipoNombramiento,
        telefonoFunPrincipal, setTelefonoFunPrincipal,
        telefonoFunSecundario, setTelefonoFunSecundario,
        correo, setCorreo,
        DireccionFuncionario, setDireccionFuncionario,
        fotoPerfil, setfotoPerfil,
        pais, setPais,
        selectedProvinciaFuncionario, setSelectedProvinciaFuncionario,
        selectedCantonFuncionario, setSelectedCantonFuncionario,
        selectedDistritoFuncionario, setSelectedDistritoFuncionario,
        selectedBarrioFuncionario, setSelectedBarrioFuncionario,
        sexo, setSexo
    } = TbFuncionario();

    const fetchData = async () => {
        try {

            const Estado = 1;

            const data = await getSecciones(Estado);
            globalData = data;
            setSecciones(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        
            GetGrados(setListaGrados)
            fetchData();
        


    }, []);

    if (loading) {
        return (
            <Cargando />
        );
    }

    if (Secciones.estado === 0) {
        fetchData();
    }

    // if (!Secciones?.length) {

    //     return (
    //         <SinDatos
    //             mensaje={"Notificacion del Sistema"}
    //             error={"No existen Secciones registradas"}
    //             route="/Menu" />
    //     );
    // }

    const toggle = () => {
        setOpen(!open);

    }

    const Abrirmodal = (data) => {

        //alert(JSON.stringify(ListaGrados));
        setIDSeccion(data.idSeccion);
        setNombreSeccion(data.nombreSeccion);
        setNivel(data.nivel);
        setCantMax(data.cantMax);
        setIdInstitucion(data.idInstitucion);
        setEstadoSeccion(data.estadoSeccion);
        setNombreInst(ObtenerNombreInst())

        setOpen(!open)
    }

    const ActualizarSeccion = async (event) => {
        event.preventDefault();

        const data = {
            idSeccion: IdSeccion,
            nombreSeccion: NombreSeccion,
            nivel: parseInt(Nivel),
            cantMax: parseInt(CantMax),
            institucion: IdInstitucion

        }
        //alert(JSON.stringify(data));
        // console.log(JSON.stringify(data));
        const success = await updateSeccion(data);

        if (success) {
            fetchData();

            toggle();
        } else {
            ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante el proceso de guardado')
            return;
        }






    }

    const Eliminar = async (param) => {

        const data = {

            idSeccion: param,
            estado: 0
        }

        Swal.fire({
            title: "Estas seguro que deseas suspender esta Seccion?",
            text: "¡ Podras restaurarla desde la opción Secciones Inactivas ¡",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "¡Sí, bórrar!",
            cancelButtonText: "Cancelar",
            allowOutsideClick: false,
        }).then(async (result) => {
            if (result.isConfirmed) {

                const success = await RestoreSeccion(data);

                if (success) {
                    Swal.fire({
                        title: "¡Restaurado!",
                        text: "La Sección a sido restaurada, ",
                        icon: "success",
                        timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
                        timerProgressBar: true, // Barra de progreso de tiempo
                        showConfirmButton: false,
                        allowOutsideClick: false,
                    });
                    fetchData();
                } else {
                    ErrorMessage('Notificación del Sistema', success.mensaje);
                    return;

                }

            }
        });
    };

    const CrearNombreSeccion = (e) => {
        let seccion = parseInt(e.target.value); // Convertir a número
        setNivel(seccion);
        //alert(seccion);
        if (seccion === -1) {
            setNombreSeccion("Materno" + "-");
        } else if (seccion === 0) {
            setNombreSeccion("Transición" + "-");
        } else {
            setNombreSeccion(seccion + "-");
        }
    }


    const toggleA = (id) => {
        setOpenA(openA === id ? null : id); // Abre o cierra el acordeón según el id
    };

    const toggle2 = () => {

        setOpen2(!open2);

    }
    const toggle3 = () => {

        setOpen3(!open3);

    }
    const ObtenerFuncionarios = async (institucion) => {
        try {
            const parametro = { idInstitucion: institucion, Estado: 1 };
            //alert(JSON.stringify(parametro ));
            const data = await getFuncionariosActivos2(parametro);

            setFuncionarios(data);


        } catch (error) {

            ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante el proceso de carga de datos')
            return;
        }
    };
    const Abrirmodal2 = async (data) => {
        //alert(data);
        setSelectedYear(currentYear)
        const SeccionSeleccionada = Secciones.find(Seccion => Seccion.idSeccion === data);
        //alert(JSON.stringify(SeccionSeleccionada));
        setNombreSeccion(SeccionSeleccionada.nombreSeccion)
        setSeccion(SeccionSeleccionada.idSeccion)
        ObtenerFuncionarios(SeccionSeleccionada.institucion)
        setInstitucion(SeccionSeleccionada.institucion)
        setNombreInst(ObtenerNombreInst())
        //alert(JSON.stringify(funcionarios));

        const param = {
            annio: currentYear.toString(),
            institucion: SeccionSeleccionada.institucion,
            estado: 1
        }
        //alert(JSON.stringify(param));
        const lista = await GetListSeccDocent(param)
        //alert(JSON.stringify(lista));

        // Verificar si lista es un array
        if (Array.isArray(lista)) {
            setListSeccDocent(lista);
            setSelectedYear(currentYear)
        } else {
            // Si lista no es un array, establecer ListSeccDocent como un array vacío
            setListSeccDocent([]);
            setSelectedYear(currentYear)
        }


        setOpen2(!open2)
    }
    const Abrirmodal3 = async (data) => {
        setSelectedYear(currentYear)
        const SeccionSeleccionada = Secciones.find(Seccion => Seccion.idSeccion === data);
        //alert(JSON.stringify(SeccionSeleccionada));
        setNombreSeccion(SeccionSeleccionada.nombreSeccion)
        setSeccion(SeccionSeleccionada.idSeccion)
        ObtenerFuncionarios(SeccionSeleccionada.institucion)
        setInstitucion(SeccionSeleccionada.institucion)
        setNombreInst(ObtenerNombreInst())
        //alert(JSON.stringify(funcionarios));

        const param = {
            annio: currentYear.toString(),
            institucion: SeccionSeleccionada.institucion,
            estado: 1
        }
        //alert(JSON.stringify(param));
        const lista = await GetListSeccDocent(param)
        if (Array.isArray(lista)) {
            setListSeccDocent(lista);
            setSelectedYear(currentYear)
        } else {
            // Si lista no es un array, establecer ListSeccDocent como un array vacío
            setListSeccDocent([]);
            setSelectedYear(currentYear)
        }



        setOpen3(!open2)
    }
    const Seleccion = (e) => {
        const { checked, value } = e.target;
        //alert( value);
        if (checked) {
            // Agregar la asignatura seleccionada al estado
            setSeleccionadas([...Seleccionadas, value]);
            //alert(...Seleccionadas + " " + value);

        } else {
            // Eliminar la asignatura deseleccionada del estado
            setSeleccionadas(Seleccionadas.filter(asig => asig !== value));
        }

    };

    const onChange = (e) => {
        let fecha = e.target.value;
        setSelectedYear(fecha.toString());

    };

    const AsignarDocenteSeccion = async (event) => {
        event.preventDefault();

        if (Seleccionadas.length > 0) {
            if (selectedYear !== '') {
                let success = false;
                let existe = false;
                for (let i = 0; i < Seleccionadas.length; i++) {

                    const CedDocente = Seleccionadas[i];

                    setDocente(CedDocente)

                    const dataExiste = {
                        seccion: seccion,
                        profesor: CedDocente,
                        annio: selectedYear.toString(),
                        institucion: institucion
                    }



                    existe = await ExisteSeccionDocente(dataExiste);
                    //alert(existe)
                    if (!existe) {

                        const data = {
                            seccion: seccion,
                            profesor: CedDocente,
                            annio: selectedYear.toString(),
                            institucion: institucion,
                            estado: 1,

                        }
                        success = await SaveSeccionDocente(data);

                    } else {

                        const param = {
                            annio: currentYear.toString(),
                            institucion: institucion,
                            estado: 0
                        }

                        const lista = await GetListSeccDocent(param)
                        //alert(JSON.stringify(lista));
                        setListSeccDocent(lista)
                        const SeccionDocente = lista.find(list => list.seccion === seccion);
                        alert(JSON.stringify(SeccionDocente));
                        if (SeccionDocente) {
                            const codigo = SeccionDocente.codigo;
                            // Haz lo que necesites con el ID de la asignatura




                            const dataRestore = {
                                codigoSeccion: parseInt(codigo),
                                estado: 1,
                            }
                            success = await RestaurarSeccionDocente(dataRestore);
                        }

                    }



                    //alert(JSON.stringify(data));
                }
                if (success) {
                    SuccessMessage('Exitoso!', 'Docentes signados existosamente')
                    setOpen2(!open2);

                    setSeleccionadas([])

                } else {
                    ErrorMessage('Notificación del Sistema', 'No se pudo registrar el Docente a la Seccion');
                }


            } else {
                ErrorMessage('Notificación del Sistema', 'Debes seleccionar el curso lectivo')
            }


        } else {
            ErrorMessage('Notificación del Sistema', 'Debes seleccionar al menos 1 Docente')
        }

    }

    const ElimiamarSeccionADocente = async (event) => {
        event.preventDefault();

        if (Seleccionadas.length > 0) {
            let success = false;
            for (let i = 0; i < Seleccionadas.length; i++) {
                const id = Seleccionadas[i];

                //alert(CodAsignatura)


                const data = {
                    codigoSeccion: parseInt(id),
                    estado: 0,

                }

                success = await EliminarSeccionDocente(data);

                //     //alert(JSON.stringify(data));
            }
            if (success) {
                SuccessMessage('Exitoso!', 'Docente desligado a la sección existosamente')

                setSeleccionadas([])

                setOpen3(!open3);
            } else {
                ErrorMessage('Notificación del Sistema', 'No se pudo desligar al docente seleccionado');
            }
        } else {
            ErrorMessage('Notificación del Sistema', 'Debes seleccionar al menos 1 Docente')
        }
    }


    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio"
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Secciones"
                        to="/Menu/FrmSecciones"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>

            {/* <Container fluid className='fixed'>
                <Col className="floating-container" id="floating-container">

                    <Button className=" d-flex justify-content-center  btnAceptar p-2">
                        <Fas.FaPlus />
                    </Button>
                    <Col className="element-container" >
                        <Button className='p-0  btn btnAceptar float-element tooltip-left  '>
                            <ul>
                                <MenuItem
                                    className=' p-0'
                                    icon={<Fa6.FaCirclePlus className="me-1 sizeIcon2" />}
                                    text="Ing. Sección"
                                    to="/Menu/" // aca agregar la ruta
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                        <Espaciador espaciador='espaciador-SM' />
                        <Button className='p-0  btn btnAceptar float-element '>
                            <ul>
                                <MenuItem
                                    className=' p-0'
                                    icon={<Bi.BiSolidInstitution className="me-1" />}
                                    text="Secciones Inact."

                                    to={"/Menu/"} // aca agregar la ruta
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                    </Col>
                </Col>



            </Container> */}

            <Container fluid className='p-0 Mymt-5'>
                <Col className='mb-3 pb-2 border-bottom'>
                    <Button className='p-0  btn btnAceptar    '>
                        <ul>
                            <MenuItem
                                className=' p-0'
                                icon={<Fa6.FaCirclePlus className="me-1 sizeIcon2" />}
                                text="Ing. Sección"
                                to="/Menu/FrmRegistrarSecciones" // aca agregar la ruta
                                classname={'NoDecoration'}
                            />
                        </ul>
                    </Button>
                    <Button className='p-0 mx-2 btn btnAceptar  '>
                        <ul>
                            <MenuItem
                                className=' p-0'
                                icon={<Bi.BiSolidInstitution className="me-1" />}
                                text="Secciones Inact."

                                to={"/Menu/FrmSeccionesInactivas"} // aca agregar la ruta
                                classname={'NoDecoration'}
                            />
                        </ul>
                    </Button>
                </Col>
                {Secciones !== null && Secciones.length > 0 ? (
                    <Row>
                        {/* Agrupar las secciones por el primer carácter del nombre del nivel */}
                        {Object.entries(
                            Secciones.reduce((acc, Secc) => {
                                let firstChar = Secc.nombreSeccion.substring(0, 2).replace('-', '').replace('a', '').replace('r', '');
                                //console.log(firstChar);
                                if (firstChar === 'M') {

                                    firstChar = -1; // Si es 'M', asignar '-1'

                                } else if (firstChar === 'T') {
                                    firstChar = 0; // Si es 'T', asignar '0'
                                }
                                if (!acc[firstChar]) {
                                    acc[firstChar] = [];

                                }

                                acc[firstChar].push(Secc);
                                //console.log(acc);
                                return acc;
                            }, {})
                        ).sort((a, b) => a[0] - b[0]).map(([firstChar, Secciones]) => (
                            <Col key={firstChar} className='col-12'>
                                <Accordion flush className='mt-1' open={openA} toggle={() => toggleA(firstChar)}> {/* Usa firstChar como id */}
                                    <AccordionItem className=' mb-3 rounded-0'>
                                        <AccordionHeader className='shadow rounded-0 accordionBorder accordion-button2' targetId={firstChar}>Listado de secciones de {Secciones[0].NombreNivel}</AccordionHeader> {/* Accede a NombreNivel dentro de Secciones[0] */}
                                        <AccordionBody className='shadow rounded-0' accordionId={firstChar}>
                                            <Row>
                                                {Secciones.map((Secc, index) => (

                                                    <Col className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 pb-1'>
                                                        <Card key={index} className="mb-3 shadow2 rounded-3">
                                                            <CardBody className="d-flex flex-column py-4">
                                                                <Col>
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <strong>Seccion: </strong> <text>{Secc.nombreSeccion}</text> <br />
                                                                        </Col>
                                                                        <Col md={12}>
                                                                            <strong>Nivel: </strong>{Secc.NombreNivel}
                                                                        </Col>
                                                                        <Col md={12}>
                                                                            <strong>Cant. Alumnos Maxima: </strong> {Secc.cantMax}<br />
                                                                        </Col>
                                                                        <Col md={12} className="mt-auto d-flex justify-content-between"> {/* Este div empuja el botón hacia abajo */}
                                                                            <Button className=' mx-1 btn-Ver mt-2 px-2 ' type='button' onClick={(e) => Abrirmodal2(Secc.idSeccion)}>
                                                                                Asignar Docentes
                                                                            </Button>
                                                                            <Button className=' mx-1 btn-Ver mt-2 px-2 ' type='button' onClick={(e) => Abrirmodal3(Secc.idSeccion)}>
                                                                                Retirar Docentes
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </CardBody>
                                                            <CardFooter className='d-flex justify-content-end'>
                                                                <Button color='' className='mx-1 btn-editar'
                                                                    onClick={(e) =>
                                                                        Abrirmodal(
                                                                            {
                                                                                idSeccion: Secc.idSeccion,
                                                                                nombreSeccion: Secc.nombreSeccion,
                                                                                nivel: Secc.nivel,
                                                                                cantMax: Secc.cantMax,
                                                                                idInstitucion: Secc.institucion,
                                                                                estadoSeccion: Secc.estadoSeccion
                                                                            })}
                                                                >
                                                                    Actualizar
                                                                </Button>
                                                                <Button color=' ' type='button' className='mx-1 btn-eliminar' onClick={(e) => Eliminar(Secc.idSeccion)}>
                                                                    Suspender
                                                                </Button>
                                                            </CardFooter>
                                                        </Card>
                                                    </Col>

                                                ))}
                                            </Row>
                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>
                            </Col>
                        ))}
                    </Row>

                ) : (
                    <Col>
                        <p>No hay Secciones Registradas.</p>
                    </Col>
                )}
                <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-4' id='GuardarInstitucion'>
                    <ul>
                        <MenuItem
                            className='btnAceptar'
                            icon={<IoMdReturnLeft className="me-1" />}
                            text="Volver"
                            to="/Menu"
                            classname={'NoDecoration'}
                        />
                    </ul>
                </Button>
            </Container>

            <Modal isOpen={open} size='md' fullscreen='lg' fade className='mt-5 shadow2 ' backdrop="static">
                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-1' >  Actualizar Sección  </h5>
                </ModalHeader>
                <ModalBody className='p-0 border rounded'>
                    <Container fluid className='  p-3   ' id='institucion'  >
                        <Form onSubmit={ActualizarSeccion} id='form1' >

                            <Row >
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="cod.Presupuestario" className="me-2">
                                            Código Presupuestario
                                        </Label>

                                        <Input

                                            readOnly
                                            required
                                            id="cod.Presupuestario"
                                            name="cod.Presupuestario"

                                            type="text"
                                            value={IdInstitucion + " - " + NombreInst}

                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    <FormGroup className='mb-0'>
                                        <Label for="nivel">
                                            Nivel
                                        </Label>
                                        <Input
                                            required
                                            id="nivel "
                                            name=" nivel"

                                            type="select"
                                            defaultValue={Nivel}
                                            onChange={(e) => CrearNombreSeccion(e)}
                                        >
                                            <option value="" disabled selected  >Seleccione el grado</option>
                                            {ListaGrados.map((grados, index) => (
                                                <option key={grados.id} value={grados.id}  >{grados.nombre}</option>
                                            ))}

                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="seccion" className="me-2">
                                            Nombre de la Sección
                                        </Label>

                                        <Input
                                            required

                                            id="seccion"
                                            name="seccion"

                                            type="text"
                                            value={NombreSeccion}
                                            onChange={(e) => ValidarLetrasNumerosGuion(e, setNombreSeccion, setSeccionValida)}
                                            className={SeccionValida ? ' ' : 'is-invalid  '}
                                        />

                                        {!SeccionValida && <div className="invalid-feedback">Formato de ingresado no valido</div>}
                                        {SeccionValida && (
                                            <p className='LetraPeq'><strong>* Ejemplo 7-1 ó 7-A.</strong></p>
                                        )}

                                    </FormGroup>
                                </Col>




                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="cantMax" className="me-2">
                                            Cantidad Maxima de alumnos
                                        </Label>

                                        <Input
                                            required
                                            id="cantMax"
                                            name="cantMax"

                                            type="number"
                                            value={CantMax}
                                            onChange={(event) => ValidarSoloNumeros(event, setCantMax, setCantidadValida)}
                                            maxLength={2}
                                            minLength={2}
                                            className={CantidadValida ? ' ' : 'is-invalid  '}
                                        />

                                        {!CantidadValida && <div className="invalid-feedback">Formato de ingresado no valido</div>}


                                    </FormGroup>
                                </Col>

                            </Row>
                            <Col className='d-flex justify-content-between mt-3'>
                                <Button type="button" onClick={toggle} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                                    <IoMdReturnLeft className="me-2 mt-1" /> Volver
                                </Button>
                                <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarInstitucion'>
                                    Actualizar Sección
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

            <Modal isOpen={open2} size='xl' fullscreen='lg' className='mt-4'>
                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-1' >  Asignar Docentes  </h5>
                </ModalHeader>
                <ModalBody className='ModalBody rounded'>
                    <Container fluid className='  p-3 shadow border rounded  ' id='institucion'  >
                        <Form onSubmit={AsignarDocenteSeccion}>

                            <Row>
                                <Col md={12} className='py-0 '>
                                    <FormGroup className='border-bottom'>
                                        <Label for="Provincia">
                                            <strong><span>Institución:</span></strong> {institucion}{" - "} {NombreInst} <br />
                                            <strong><span>Sección:</span></strong> {NombreSeccion}
                                        </Label>

                                    </FormGroup>
                                </Col>

                                <Col md={12} className='py-0'>
                                    <FormGroup>
                                        <Label for="Provincia">
                                            <strong><span>Lista de Docentes seleccionables</span></strong>
                                        </Label>
                                        <Col md={12} className='border rounded p-2'>
                                            {Array.isArray(funcionarios) && funcionarios.length === 0 ? (
                                                <Label className="text-center text-muted">No hay funcionarios disponibles o registrados.</Label>
                                            ) : (
                                                <Row>
                                                    {funcionarios.map((fun, index) => {
                                                        // Verificar si el profesor ya está asignado a esta sección
                                                        const estaAsignado = ListSeccDocent.some(list => list.profesor === fun.cedulaFuncionario && list.seccion === seccion);
                                                        return (
                                                            <Col md={4} key={index}>
                                                                <FormGroup check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id={`checkbox-${index}`}
                                                                        value={fun.cedulaFuncionario}
                                                                        onChange={Seleccion}
                                                                        checked={Seleccionadas.includes(fun.cedulaFuncionario) || estaAsignado}
                                                                        disabled={estaAsignado} // Deshabilitar si ya está asignado a esta sección
                                                                    />
                                                                    <Label check className='negrita' htmlFor={`checkbox-${index}`}>
                                                                        {fun.nombreCompleto} {fun.apellido1} {fun.apellido2}
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        );
                                                    })}
                                                </Row>
                                            )}
                                        </Col>
                                        <span className='LetraPeq text-danger'> * Los Docentes que se encuentra inhabilitados ya se encuentran asignadas a esta sección </span>
                                    </FormGroup>
                                </Col>
                                <Col md={2} className='py-0'>
                                    <FormGroup>
                                        <Label for="Provincia">
                                            <strong><span>Curso lectivo</span></strong>
                                        </Label>
                                        <Input
                                            type='number'
                                            min={2024}
                                            max={2100}
                                            value={selectedYear}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Col className='d-flex justify-content-between mt-3'>
                                <Button type="button" onClick={toggle2} className=' p-1     btnCancelar px-3  d-flex justify-content-start' id='GuardarInstitucion'>
                                    <IoMdReturnLeft className="me-2 mt-1" /> Volver
                                </Button>
                                <Button type="submit" className=' p-1 px-3 btnAceptar ' id='GuardarInstitucion'>
                                    Aplicar
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>

            <Modal isOpen={open3} size='xl' fullscreen='lg' className='mt-4'>
                <ModalHeader className='color-header-form '>
                    <h5 className='py-1 m-1' >  Retirar Docentes Asignados  </h5>
                </ModalHeader>
                <ModalBody className='ModalBody rounded'>
                    <Container fluid className='  p-3 shadow border rounded bg-white ' id='institucion'  >
                        <Form onSubmit={ElimiamarSeccionADocente}>

                            <Row>
                                <Col md={12} className='py-0 '>
                                    <FormGroup className='border-bottom'>
                                        <Label for="Provincia">
                                            <strong><span>Institución:</span></strong> {institucion}{" - "} {NombreInst} <br />
                                            <strong><span>Sección:</span></strong>  {NombreSeccion}
                                        </Label>

                                    </FormGroup>
                                </Col>

                                <Col md={12} className='py-0'>
                                    <FormGroup>
                                        <Label for="Provincia">
                                            <strong><span>Lista de Docentes asignados actualmente a esta sección </span></strong>
                                        </Label>
                                        <Col md={12} className=' border rounded p-2'>
                                            <Row>
                                                {Array.isArray(ListSeccDocent) && ListSeccDocent.length > 0 ? (
                                                    ListSeccDocent.map((SeccDocente, index) => {
                                                        const perteneceASeccion = SeccDocente.seccion === seccion;
                                                        if (perteneceASeccion) {
                                                            return (
                                                                <Col md={4} key={index}>
                                                                    <FormGroup check>
                                                                        <Input
                                                                            id={`checkbox-${index}`}
                                                                            type="checkbox"
                                                                            value={SeccDocente.codigo}
                                                                            onChange={Seleccion}
                                                                            checked={Seleccionadas.includes(SeccDocente.codigo.toString())} />
                                                                        <Label check className='negrita' htmlFor={`checkbox-${index}`}>
                                                                            {SeccDocente.nombreCompleto} {SeccDocente.apellido1} {SeccDocente.apellido2}<br />
                                                                        </Label>
                                                                    </FormGroup>
                                                                </Col>
                                                            )
                                                        }
                                                        return null;
                                                    })
                                                ) : (

                                                    <Label>La sección seleccionado NO posee Docentes asignados </Label>

                                                )}
                                            </Row>

                                        </Col>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Col className='d-flex justify-content-between mt-3'>
                                <Button type="button" onClick={toggle3} className=' p-1     btnCancelar px-3  d-flex justify-content-start' id='GuardarInstitucion'>
                                    <IoMdReturnLeft className="me-2 mt-1" /> Volver
                                </Button>
                                <Button type="submit" className=' p-1 px-3 btnAceptar ' id='GuardarInstitucion'>
                                    Aplicar
                                </Button>
                            </Col>

                        </Form>

                    </Container>

                </ModalBody>
            </Modal>
        </Container>
    )
}

export default FrmSecciones
