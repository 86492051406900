
import { ErrorMessage, SuccessMessage } from '../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../CapaUtilidades/Utils/encryptUserData';
import endpoint from './EndPoints/endPoint';

var url = '';
var estado;

/**
 * Metodo para verificar si el funcionario que se va a registrar ya se encuentra en el sistema
 * @param {*} data parametro a evaluar en formato JSON {cedulaFuncionario:"string"}
 * @returns Retorna true si existe y False si no existe
 */
export async function ExisteFuncionario(data) {
    
     url = endpoint.urlExisteFuncionario;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

     try {
        
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
       
        estado = responseData.estado;
        
        return {estado: estado, mensaje:responseData.mensaje};

     } catch (error) {
        console.log(error)
         return { estado: estado, mensaje: error };
     }
}


/**
 * Metodo para guardar la institucion en la base de datos
 * @param {*} data Datos a guardar en un arreglo sencillo 
 * @returns retorna true si el guardado fue exitoso false si no se puedo guardar
 */
export async function saveFuncionario(data) {
    
    url = endpoint.urlSaveFuncionario;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

        console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
           // SuccessMessage('Exitoso', 'El Funcionario a sido registrado exitosamente');
            return { estado: responseData.estado};

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            return { estado: responseData.estado};
        }
        

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}

export async function saveFunLaborarInstitucion(data) {
    //console.log(JSON.stringify(data));
    url = endpoint.urlSaveFunLaborarInstitucion;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

        //console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
           // SuccessMessage('Exitoso', 'El Funcionario a sido registrado exitosamente');
            return { estado: responseData.estado};

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            return { estado: responseData.estado};
        }
        

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}

export const ActualizarNombramientoFunc = async (data) => {
    url = endpoint.urlUpdateFunLaborarInstitucion;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return { estado: false, mensaje: 'URL o datos no especificados.' };
   }

   try {
      
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

           const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
           console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
           throw new Error(`HTTP error! status: ${response.status}`);
       }

       const responseData = await response.json();
       //alert(JSON.stringify(responseData));
       if (responseData.estado) {
           // SuccessMessage('Exitoso', responseData.mensaje);
           return { estado: responseData.estado, mensaje: responseData.mensaje };

       }
       else {
           ErrorMessage('Error inesperado', responseData.mensaje);
           estado = responseData.estado
       }

   } catch (error) {

       return { estado: estado, mensaje: 'Ocurrio un error inesperado y no se puedo realizar la actualizacizar el funcionario ' };
   }
};

export async function ExisteNombramientoFun(data) {
    
    url = endpoint.urlNombramientoExiste;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return false;
   }

    try {
       
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

           const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
           console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
           throw new Error(`HTTP error! status: ${response.status}`);
       }

       const responseData = await response.json();
      
       estado = responseData.estado;
       
       return {estado: estado, mensaje:responseData.mensaje};

    } catch (error) {
       console.log(error)
        return { estado: estado, mensaje: error };
    }
}


export async function  DeleteNombramiento(data) {
    url = endpoint.urlDeleteFunLaborarInstitucion;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');

   }

   try {
       console.log(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),

       });

       if (!response.ok) {

           const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
           console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
           throw new Error(`HTTP error! status: ${response.status}`);
       }

       const responseData = await response.json();
       if (responseData.estado) {
           //SuccessMessage('Exitoso', responseData.mensaje);
           return { estado: responseData.estado, mensaje: responseData.mensaje };

       }
       else {
           ErrorMessage('Error inesperado', responseData.mensaje);
           estado = responseData.estado
       }

   } catch (error) {

       return { estado: estado, mensaje: 'Ocurrio un error inesperado y no se puedo realizar la eliminacion del usuario ' };
   }
}

export async function  RestoreNombramiento(data) {
    url = endpoint.urlRestoreFunLaborarInstitucion;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');

   }

   try {
       console.log(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),

       });

       if (!response.ok) {

           const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
           console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
           throw new Error(`HTTP error! status: ${response.status}`);
       }

       const responseData = await response.json();
       if (responseData.estado) {
           //SuccessMessage('Exitoso', responseData.mensaje);
           return { estado: responseData.estado, mensaje: responseData.mensaje };

       }
       else {
           ErrorMessage('Error inesperado', responseData.mensaje);
           estado = responseData.estado
       }

   } catch (error) {

       return { estado: estado, mensaje: 'Ocurrio un error inesperado y no se puedo realizar la eliminacion del usuario ' };
   }
}
/**
 * Funcion que retorna un arreglo con los Funcionarios activos de una Institucion determinada
 * @param {*} data parametro de filtrado en formato Json {"idInstitucion":"codigo","estado": 1 }
 * @returns retorna un arreglo con todas los funcionarios activos de la institucion seleccioada
 */
export async function getFuncionariosActivos(datas ) {
    url = endpoint.urlGetFuncionarios;
    let FuncionariosConDatosCompletos = null;
    let inst = obtenerIdInstitucion();

    const data = { idInstitucion: inst, Estado: datas }
    //alert(JSON.stringify(data));
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        //console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const FuncionariosResponse = await response.json();

        const Cargos = require('../CapaPresentacion/Json/Cargos.json');

        const funcionariosActivos = FuncionariosResponse[0];
        FuncionariosConDatosCompletos = funcionariosActivos.map(Funcionario => {
            const CargoData = Cargos[Funcionario.cargo.toString()];
           
            // const circuitoData = direccionRegionalData ? direccionRegionalData.Circuitos[institucion.circuito.toString()] : null;
            // const provinciaData = ubicaciones[institucion.Provincia.toString()];
            // const cantonData = provinciaData ? provinciaData.cantones[institucion.Canton.toString()] : null;
            // const distritoData = cantonData ? cantonData.distritos[institucion.Distrito.toString()] : null;
            // const barrioData = distritoData ? distritoData.barrios[institucion.Barrio.toString()] : null;

            return {
                ...Funcionario,
                CargoC: CargoData ,
                // circuitoC: circuitoData ? circuitoData.NombreCircuito : '',
                // provincia: provinciaData ? provinciaData.nombre_provincia : '',
                // canton: cantonData ? cantonData.nombre_canton : '',
                // distrito: distritoData ? distritoData.nombre_distrito : '',
                // barrio: barrioData ? barrioData : ''
            };
        });
        

        return FuncionariosConDatosCompletos;  // Devolvemos los datos obtenidos

    } catch (error) {
        return { estado: 0 };
    }
}

export async function getFuncionariosActivos2(data ) {
    url = endpoint.urlGetFuncionarios;
    let FuncionariosConDatosCompletos = null;
    let inst = obtenerIdInstitucion();

    //const data = { idInstitucion: inst, Estado: datas }
    //alert(JSON.stringify(data));
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    // try {
        //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const FuncionariosResponse = await response.json();
        const Cargos = require('../CapaPresentacion/Json/Cargos.json');
        //console.log(JSON.stringify(FuncionariosResponse));
        const funcionariosActivos = FuncionariosResponse[0];
        FuncionariosConDatosCompletos = funcionariosActivos.map(Funcionario => {
            const CargoData = Cargos[Funcionario.cargo.toString()];
           
            // const circuitoData = direccionRegionalData ? direccionRegionalData.Circuitos[institucion.circuito.toString()] : null;
            // const provinciaData = ubicaciones[institucion.Provincia.toString()];
            // const cantonData = provinciaData ? provinciaData.cantones[institucion.Canton.toString()] : null;
            // const distritoData = cantonData ? cantonData.distritos[institucion.Distrito.toString()] : null;
            // const barrioData = distritoData ? distritoData.barrios[institucion.Barrio.toString()] : null;

            return {
                ...Funcionario,
                CargoC: CargoData ,
                // circuitoC: circuitoData ? circuitoData.NombreCircuito : '',
                // provincia: provinciaData ? provinciaData.nombre_provincia : '',
                // canton: cantonData ? cantonData.nombre_canton : '',
                // distrito: distritoData ? distritoData.nombre_distrito : '',
                // barrio: barrioData ? barrioData : ''
            };
        });
        //console.log(JSON.stringify(FuncionariosConDatosCompletos));

        return FuncionariosConDatosCompletos;  // Devolvemos los datos obtenidos

    // } catch (error) {
    //     return { estado: 0 };
    // }
}

/**
 * Funcion para actualizar la institucion
 * @param {*} data Arreglo con los datos en formato JSON para realizar la actualizacion de datos
 * @returns retorna true su es correcta y false si no se realizar la actualizacion
 */
export const getActualizarFuncionario = async (data) => {
     url = endpoint.urlUpdateFuncionario;

    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return { estado: false, mensaje: 'URL o datos no especificados.' };
    }

    try {
       
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        //alert(JSON.stringify(responseData));
        if (responseData.estado) {
            // SuccessMessage('Exitoso', responseData.mensaje);
            return { estado: responseData.estado, mensaje: responseData.mensaje };

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }

    } catch (error) {

        return { estado: estado, mensaje: 'Ocurrio un error inesperado y no se puedo realizar la actualizacizar el funcionario ' };
    }
};


export async function saveHistoricoNombramientos(data) {
    //console.log(JSON.stringify(data));
    url = endpoint.urlSaveHistoricoNombramientoFunc;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

        //console.log(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
           
            return { estado: responseData.estado};

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            return { estado: responseData.estado};
        }
        

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}


export async function GetDatosPadron(data) {
    const url = `https://apis.gometa.org/cedulas/${data}`;
    
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return null;
    }

    try {
        const response = await fetch(url, {
            method: 'GET'
        });

        if (!response.ok) {
            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.message}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const DatosPadron = await response.json();
        
        return DatosPadron;  // Devolvemos los datos obtenidos
    } catch (error) {
        console.error('Error fetching data:', error);
        return { estado: 0 };
    }
}