import React, { useEffect, useState } from "react";
import * as Fa from "react-icons/fa";
import * as Fa6 from "react-icons/fa6";
import * as Io  from 'react-icons/io';
import * as Pi from "react-icons/pi";
import * as Gi from "react-icons/gi";
import * as Fc from "react-icons/fc";
import * as Bs from "react-icons/bs";
import * as Md from "react-icons/md";
import * as Bi from "react-icons/bi";
import { IoBookOutline } from "react-icons/io5";
import MenuItem from "./MenuItem";
import { Roll } from "../../CapaUtilidades/Utils/Cahe";
import { Button } from "reactstrap";
import { BsArrowsFullscreen } from "react-icons/bs";
import { AiOutlineFullscreenExit } from "react-icons/ai";


/**
 * Componente Sidebar menu lateral
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.isOpen - Indica si la barra lateral está abierta.
 * @param {function} props.toggleSidebar - Función para alternar la visibilidad de la barra lateral.
 * @returns {JSX.Element} Componente Sidebar.
 */
const SidebarAdministrador = ({ isOpen, toggleSidebar }) => {
  const [menuContraido, setMenuContraido] = useState(false);




  const [activeSubMenu, setActiveSubMenu] = useState(null);

  /**
  * Maneja el clic en un elemento de menú para alternar la visibilidad del submenú correspondiente.
  * @param {string} menuId - Identificador del menú.
  */
  const handleNavLinkClick = (menuId) => {
    setActiveSubMenu(activeSubMenu === menuId ? null : menuId);
  };

  const handleSidebarItemClick = () => {
    if (window.innerWidth <= 1200) {
      toggleSidebar();
    }
    setActiveSubMenu(null);
  };

  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  const ContraerMenu = () => {
    setMenuContraido(!menuContraido);
  };

  return (
    <div className={`sidebar p-0 ${menuContraido ? "sidebarContrac" : ""} ${isOpen ? "" : "hidden"}`} id="sidebar">

      <div className="d-flex justify-content-end pr-3 ocultarbtnMenu" id="btnMenu" >
        <Button title="Pantalla Completa" onClick={ContraerMenu} className='btnMenu ocultarbtnMenu'>
           <Md.MdMenu size={25} className=" " />
        </Button>
      </div>


      <ul>
        <MenuItem
          icon={<Md.MdHome size={22} className="me-1  " />}
          text={menuContraido ? "" : "Inicio"}
          onClick={handleSidebarItemClick}
          to="/Menu/"
          classname={'text-sidebar '}
        />

        <MenuItem //Gestion Estudiantes
          icon={<Pi.PiStudentFill size={22} className="me-1 " />}
          text={menuContraido ? "" : "Estudiantes"}
          subMenuItems={[
            { to: "/Menu/GestionEstudiante", text: menuContraido ? "" : "opc1", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: menuContraido ? "" : "opc2", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: menuContraido ? "" : "opc3", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: menuContraido ? "" : "opc4", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: menuContraido ? "" : "opc5", onClick: handleSidebarItemClick },
          ]}
          isActive={activeSubMenu === "gestionEstudiantes"}
          onClick={() => handleNavLinkClick("gestionEstudiantes")}
          to="#"
          padding={"p-2"}
          classname={'text-sidebar '}
        />

        <MenuItem // Gestion Docentes
          icon={<Gi.GiTeacher size={22} className="me-1 " />}
          text={menuContraido ? "" : "Docentes"}
          subMenuItems={[
            { to: "/Menu/GestionProfesores", text: menuContraido ? "" : "opc1", onClick: handleSidebarItemClick },
            { to: "/Menu/Administradores", text:menuContraido ? "" : "opc2", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: menuContraido ? "" : "opc3", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: menuContraido ? "" : "opc4", onClick: handleSidebarItemClick },
            { to: "/Menu/", text: menuContraido ? "" : "opc5", onClick: handleSidebarItemClick },
          ]}
          isActive={activeSubMenu === "gestionProfesores"}
          onClick={() => handleNavLinkClick("gestionProfesores")}
          to="#"
          padding={"p-2"}
          classname={'text-sidebar '}
        />

        <MenuItem // Gestion Adminstrativa
          icon={<Md.MdAdminPanelSettings size={22} className="me-1  " />}
          text= {menuContraido ? "" : "Adminstrativo"}
          subMenuItems={[
            { to: "/Menu/FrmInstitucion", icon2: <Bi.BiSolidInstitution className="me-1"  size={20} /> , text:menuContraido ? "" : "Institución", onClick: handleSidebarItemClick },
            { to: "/Menu/FrmFuncionarioDocente",  icon2: <Fa.FaChalkboardTeacher  className="me-1" size={20} />,text: menuContraido ? "" : "Docentes", onClick: handleSidebarItemClick },
            { to: "/Menu/FrmFuncionarios", icon2: <Fa6.FaPeopleGroup  className="me-1" size={20} />,text: menuContraido ? "" : "Funcionarios", onClick: handleSidebarItemClick },// limitar los cargos a solo profesores y crear otro form para los demas cargos para separar docentes de funcionarios
            { to: "/Menu/FrmAsignaturas", icon2: <IoBookOutline  className="me-1" size={20} />,text: menuContraido ? "" :  "Asignaturas", onClick: handleSidebarItemClick },
            { to: "/Menu/FrmSecciones", icon2: <Io.IoIosApps  className="me-1" size={20} />, text: menuContraido ? "" : "Secciones", onClick: handleSidebarItemClick },
            { to: "/Menu/FrmActivos", icon2: <Io.IoMdCheckmarkCircleOutline  className="me-1" size={20} />, text: menuContraido ? "" : "Activos", onClick: handleSidebarItemClick },
            { to: "/Menu/FrmEdificios", icon2: <Fa6.FaBuilding  className="me-1" size={20} />,text: menuContraido ? "" : "Edificios", onClick: handleSidebarItemClick },
            { to: "/Menu/FrmExpedientes", icon2: <Md.MdFolderShared  className="me-1" size={20} />,text: menuContraido ? "" : "Expediente", onClick: handleSidebarItemClick },
            // { to: "/Menu/", icon2: <Fa.FaUserShield  className="me-1" size={20} />, text: menuContraido ? "" : "Encargado Legal", onClick: handleSidebarItemClick },
            // { to: "/Menu/", icon2: <Pi.PiStudentBold  className="me-1" size={20} />, text: menuContraido ? "" : "Estudiantes", onClick: handleSidebarItemClick },
            { to: "/Menu/", icon2: <Fa.FaClipboardCheck  className="me-1" size={20} />,text: menuContraido ? "" : "Prematriculas", onClick: handleSidebarItemClick },
          ]}
          isActive={activeSubMenu === "gestionAdministrativa"}
          onClick={() => handleNavLinkClick("gestionAdministrativa")}
          to="#"
          padding={"p-2"}
          classname={'text-sidebar '}
        />





      </ul>
    </div>
  );
};

export default SidebarAdministrador;
