import { useState } from 'react';


/**
 * 
 * @returns Retorna las atributos con sus propiedades Get y Set de la TbFuncionario
 */
export const TbDatosMedicos = () => {

    const [peso, setPeso] = useState();
    const [talla, setTalla] = useState();
    const [imc, setImc] = useState(0);
    const [agudezaVisual, setAgudezaVisual] = useState(0);
    const [agudezaAuditiva, setAgudezaAuditiva] = useState(0);
    const [condicionBucoDental, setCondicionBucoDental] = useState(0);
    const [hemogramas, setHemogramas] = useState(0);
    const [situacionSalud, setSituacionSalud] = useState(0);
    const [situacion, setSituacion] = useState('');
    const [poseeTratamientoMedico, setPoseeTratamientoMedico] = useState(0);
    const [medicarseDuranteClases, setMedicarseDuranteClases] = useState(0);
    const [medicamentos, setMedicamentos] = useState([]);
    const [esquemaVacunas, setEsequemaVacunas] = useState(0);
    const [nombreVacunasFaltantes, setNombreVacunasFaltantes] = useState('');
    const [variedadAlimenticia, setVariedadAlimenticia] = useState(0);
    const [horariosRegularesComida, setHorariosRegularesComida] = useState(0);
    const [alergiaMedicamento, setAlergiaMedicamento] = useState(0);
    const [nombreMedicamentoAlergico, setNombreMedicamentoAlergico] = useState('');
    const [alergiaAlimento, setAlergiaAlimento] = useState(0);
    const [nombreAlimentoAlergico, setNombreAlimentoAlergico] = useState('');
    const [otrasAlergias, setOtrasAlergias] = useState('');
    const [poseeDiscapasidades, setPoseeDiscapasidades] = useState(0);
    const [discapacidades, setDiscapacidades] = useState('');
    const [tipoSangre, setTipoSangre] = useState(0);
    const [medicamentoIn, setMedicamentoIn] = useState('');
    const [dosis, setDosis] = useState('');
    const [horario, setHorario] = useState('');

    const [listaDiscapacidades, setListaDiscapacidades]= useState([]);
    const [listaTiposSanguineos, setListaTiposSanguineos] = useState([]);
    const [listaBucoDental, setListaBucoDental] = useState([]);

    const [pesoValido, setPesoValido] = useState(true);
    const [tallaValida, setTallaValida] = useState(true);
    const [hemogramaValido, setHemogramaValido] = useState(true);
    const [situacionValida, setSituacionValida] = useState(true);

    return {
        peso, setPeso,
        talla, setTalla,
        imc, setImc,
        agudezaVisual, setAgudezaVisual,
        agudezaAuditiva, setAgudezaAuditiva,
        condicionBucoDental, setCondicionBucoDental,
        hemogramas, setHemogramas,
        situacionSalud, setSituacionSalud,
        situacion, setSituacion,
        poseeTratamientoMedico, setPoseeTratamientoMedico,
        medicarseDuranteClases, setMedicarseDuranteClases,
        medicamentos, setMedicamentos,
        esquemaVacunas, setEsequemaVacunas,
        nombreVacunasFaltantes, setNombreVacunasFaltantes,
        variedadAlimenticia, setVariedadAlimenticia,
        horariosRegularesComida, setHorariosRegularesComida,
        alergiaMedicamento, setAlergiaMedicamento,
        nombreMedicamentoAlergico, setNombreMedicamentoAlergico,
        alergiaAlimento, setAlergiaAlimento,
        nombreAlimentoAlergico, setNombreAlimentoAlergico,
        otrasAlergias, setOtrasAlergias,
        poseeDiscapasidades, setPoseeDiscapasidades,
        discapacidades, setDiscapacidades,
        tipoSangre, setTipoSangre,
        medicamentoIn, setMedicamentoIn,
        dosis, setDosis,
        horario, setHorario,

        listaDiscapacidades, setListaDiscapacidades,
        listaTiposSanguineos, setListaTiposSanguineos,
        listaBucoDental, setListaBucoDental,

        pesoValido, setPesoValido,
        tallaValida, setTallaValida,
        hemogramaValido, setHemogramaValido,
        situacionValida, setSituacionValida,
    };
};