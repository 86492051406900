import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';


const MenuItem2 = ({ to, text }) => {
    return (
        <li className='NoDecoration3'>
            <NavLink
                className={'text-link'}
                to={to}
                title='Has click para ver toda la información'
            >
                {text}
            </NavLink>
        </li>
    )
}

export default MenuItem2
