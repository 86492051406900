
import { ErrorMessage, SuccessMessage } from '../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { obtenerIdInstitucion } from '../CapaUtilidades/Utils/encryptUserData';
import endpoint from './EndPoints/endPoint';

var url = '';
var estado;


export async function getSeccionesDocente(data) {

    url = endpoint.urlSeccionesDocente;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

       
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

       
            return   responseData

       
       

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return [];

    }
}

export async function getListaSeccionesDocente(data) {

    url = endpoint.urlListaSeccionesDocente;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

       //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

       
            return   responseData

       
       

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return [];

    }
}

export async function GetListSeccDocent(data) {

    url = endpoint.urlListSeccDocent;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

       //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

       
            return   responseData

       
       

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return [];

    }
}

export async function ExisteSeccionDocente(data) {
    
    url = endpoint.urlExisteSeccDocente;

   if (!url || !data) {
       ErrorMessage('Error', 'URL o datos no especificados.');
       return false;
   }

    try {
       //alert(JSON.stringify(data));
       const response = await fetch(url, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(data),
       });

       if (!response.ok) {

        const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
        console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

       const responseData = await response.json();
      
       estado = responseData.estado;
       //alert(estado)
       return estado;

    } catch (error) {
       console.log(error)
        return { estado: estado, mensaje: error };
    }
}

export async function SaveSeccionDocente(data) {

    url = endpoint.urlSaveSeccionDocente;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

      //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            // SuccessMessage('Exitoso', 'Asignatura registrada exitosamente');
            return   estado = responseData.estado

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }
       

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}

export async function RestaurarSeccionDocente(data) {

    url = endpoint.urlRestoreSeccionDocente;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

       alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            // SuccessMessage('Exitoso', 'Asignatura registrada exitosamente');
            return   estado = responseData.estado

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }
       

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}

export async function EliminarSeccionDocente(data) {

    url = endpoint.urlDeleteSeccionDocente;
    if (!url || !data) {
        ErrorMessage('Error', 'URL o datos no especificados.');
        return false;
    }

    try {

      //alert(JSON.stringify(data));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),

        });

        if (!response.ok) {

            const errorResponseData = await response.json(); // Obtener los datos de la respuesta de error
            console.error(`Error en la solicitud: ${errorResponseData.mensaje}`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();

        if (responseData.estado) {
            // SuccessMessage('Exitoso', 'Asignatura registrada exitosamente');
            return   estado = responseData.estado

        }
        else {
            ErrorMessage('Error inesperado', responseData.mensaje);
            estado = responseData.estado
        }
       

    } catch (error) {
        console.log('Error durante el fetch: =>' + error);
        return { estado: estado, mensaje: error };

    }
}