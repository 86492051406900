import Swal from 'sweetalert2';
import 'animate.css';
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { ExisteFuncionario, GetDatosPadron } from '../../CapaDatos/DatosFuncionarios';
import { ExistePersona, GetPersona } from '../../CapaDatos/DatosPersonaEstudiante';
import { ExisteConsdicionSocio_Economica, ExisteEstudiante, ListarConsdicionSocio_Economica } from '../../CapaDatos/DatosEstudiante';
 

/**
 * 
 * @param {*} dataUrl dato que devuelve la base de datos con el Base64 de la imagen
 * @returns retorna la imagen convertida para mostrarla
 */
export function removeBase64Prefix(dataUrl) {
    //console.log(dataUrl);
    return dataUrl.replace(/^data:image\/\w+;base64,/, '');
};

/**
 * 
 * @param {*} event Evento que activa 
 * @param {*} setSelectedImage areglo para almacenar la imagen y mostrarlas 
 * @param {*} setfotoPerfil areglo para almacenar la imagen y enviarla a la base de datos
 * @returns retorna la imagen convertida para mostrar
 */
export const ObtenerImagen = (event, setSelectedImage, setfotoPerfil) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const MAX_WIDTH = 500; // Ancho máximo deseado
            const MAX_HEIGHT = 500; // Alto máximo deseado
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            const dataUrl = canvas.toDataURL('image/jpeg', 0.86); // Calidad de compresión del 70%

            setSelectedImage(dataUrl);
            setfotoPerfil(dataUrl);
        };
        img.src = e.target.result;
    };
    reader.readAsDataURL(file);
};


export const ImageDefecto = (imagen, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(imagen);
    reader.onload = () => {
        const base64String = reader.result;
        callback(base64String); // Llamar a la función de retorno de llamada con la imagen base64
    };
    reader.onerror = (error) => {
        console.error('Error al leer la imagen por defecto:', error);
    };
};

/**
 * Funcion para obtener y convertir la imagen
 * @param {*} event Evento que activa 
 * @param {*} setSelectedLogo areglo para almacenar la imagen y mostrarlas 
 * @param {*} setLogo areglo para almacenar la imagen y enviarla a la base de datos
 * @returns retorna la imagen convertida para mostrar
 */
export const ObtenerLogo = (event, setLogo, setSelectedLogo) => {
    const file = event.target.files[0];
    const reader = new FileReader();



    reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const MAX_WIDTH = 700; // Ancho máximo deseado
            const MAX_HEIGHT = 700; // Alto máximo deseado
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            const dataUrl = canvas.toDataURL('image/jpeg', 0.86); // Calidad de compresión del 70%

            setLogo(dataUrl);
            setSelectedLogo(dataUrl);
        };
        img.src = e.target.result;
    };

    reader.readAsDataURL(file);
};

/**
 * Metodo para mostrar y ocultar l contraseña
 * @param {*} setMostrarContrasena Estado de tipo boleano para mostrar o no la contraseña
 */
export const MostrarContrasena = (setMostrarContrasena) => {
    setMostrarContrasena(true); // Cambia el estado para mostrar o no la contraseña
};

/**
 * Funcion para mostrar Mensaje de Exitoso
 * @param {*} Titulo Titulo del mensaje
 * @param {*} Mensaje Mensaje a mostrar
 */
export const SuccessMessage = (Titulo, Mensaje) => {
    Swal.fire({
        background: "#474747",
        color: "#FFFFFF",
        icon: 'success',
        title: Titulo,
        text: Mensaje,
        timer: 1500, // Tiempo en milisegundos (en este caso, 3 segundos)
        timerProgressBar: true, // Barra de progreso de tiempo
        showConfirmButton: false, // Oculta el botón de confirmación
        allowOutsideClick: false,
        showClass: {
            popup: `
              animate__animated
              animate__zoomIn
              animate__faster
            `
        },
        hideClass: {
            popup: `
              animate__animated
              animate__zoomOut
              animate__faster
            `
        }

    });
};

/**
 * Funcion para mostrar Mensaje de Error
 * @param {*} Titulo Titulo del mensaje
 * @param {*} Mensaje Mensaje a mostrar
 */
export const ErrorMessage = (Titulo, Mensaje) => {
    Swal.fire({
        background: "#474747",
        color: "#FFFFFF",
        icon: 'error',
        title: Titulo,
        text: Mensaje,
        //timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
        //timerProgressBar: true, // Barra de progreso de tiempo
        showConfirmButton: true, // Oculta el botón de confirmación
        confirmButtonColor: '#009175',
        allowOutsideClick: false,
        //showCloseButton: true, 
        showClass: {
            popup: `
              animate__animated
              animate__zoomIn
              animate__faster
            `
        },
        hideClass: {
            popup: `
              animate__animated
              animate__zoomOut
              animate__faster
            `
        }

    });
};

/**
 * Funcion para mostrar Mensaje de Warning
 * @param {*} Titulo Titulo del mensaje
 * @param {*} Mensaje Mensaje a mostrar
 */
export const WarningMessage = (Titulo, Mensaje) => {
    Swal.fire({
        background: "#474747",
        color: "#FFFFFF",
        icon: 'warning',
        title: Titulo,
        text: Mensaje,
        //timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
        //timerProgressBar: true, // Barra de progreso de tiempo
        showConfirmButton: true, // Oculta el botón de confirmación
        confirmButtonColor: '#009175',
        allowOutsideClick: false,
        //showCloseButton: true, 
        showClass: {
            popup: `
              animate__animated
              animate__zoomIn
              animate__faster
            `
        },
        hideClass: {
            popup: `
              animate__animated
              animate__zoomOut
              animate__faster
            `
        }
    });
};

export const ErrorMessageLogin = (Titulo, Mensajes) => {
    let textoMensaje = Mensajes.join("<br>"); // Une los mensajes en una cadena HTML separada por saltos de línea
    Swal.fire({
        icon: 'error',
        title: Titulo,

        html: textoMensaje, // Usa 'html' en lugar de 'text' para permitir etiquetas HTML en el mensaje
        //timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
        timerProgressBar: true, // Barra de progreso de tiempo
        showConfirmButton: false, // Oculta el botón de confirmación
        allowOutsideClick: false,
        showCloseButton: true,
        showClass: {
            popup: `
              animate__animated
              animate__zoomIn
              animate__faster
            `
        },
        hideClass: {
            popup: `
              animate__animated
              animate__zoomOut
              animate__faster
            `
        }
    });
};

/**
 * Funcion para mostrar Mensaje de Info
 * @param {*} Titulo Titulo del mensaje
 * @param {*} Mensaje Mensaje a mostrar
 */
export const InfoMessage = (Titulo, Mensaje) => {
    Swal.fire({
        background: "#474747",
        color: "#FFFFFF",
        icon: 'info',
        title: Titulo,
        text: Mensaje,
        //timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
        //timerProgressBar: true, // Barra de progreso de tiempo
        showConfirmButton: true, // Oculta el botón de confirmación
        confirmButtonColor: '#009175',
        allowOutsideClick: false,
        //showCloseButton: true, 
        showClass: {
            popup: `
              animate__animated
              animate__zoomIn
              animate__faster
            `
        },
        hideClass: {
            popup: `
              animate__animated
              animate__zoomOut
              animate__faster
            `
        }
    });
};

/**
 * Funcion para mostrar Mensaje de Question
 * @param {*} Titulo Titulo del mensaje
 * @param {*} Mensaje Mensaje a mostrar
 */
export const QuestionMessage = (Titulo, Mensaje) => {
    Swal.fire({
        background: "#474747",
        color: "#FFFFFF",
        icon: 'question',
        title: Titulo,
        text: Mensaje,
        //timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
        //timerProgressBar: true, // Barra de progreso de tiempo
        showConfirmButton: true, // Oculta el botón de confirmación
        confirmButtonColor: '#009175',
        allowOutsideClick: false,
        //showCloseButton: true, 
        showClass: {
            popup: `
              animate__animated
              animate__zoomIn
              animate__faster
            `
        },
        hideClass: {
            popup: `
              animate__animated
              animate__zoomOut
              animate__faster
            `
        }
    });
};



export const NivelSeguridad = {
    MuySegura: 'Muy segura',
    MediaSegura: 'Media segura',
    PocoSegura: 'Poco segura',
    Insegura: 'Insegura',
};

export const EvaluarSeguridadContraseña = (contraseña) => {
    const longitud = contraseña.length;
    let puntaje = 0;
    let contieneMayusculas = false;
    let contieneMinusculas = false;
    let contieneNumeros = false;
    let contieneCaracteresEspeciales = false;

    // Verificar si la contraseña contiene caracteres de cada tipo
    for (const c of contraseña) {
        if (c.match(/[A-Z]/)) contieneMayusculas = true;
        else if (c.match(/[a-z]/)) contieneMinusculas = true;
        else if (c.match(/[0-9]/)) contieneNumeros = true;
        else if (c.match(/[^a-zA-Z0-9]/)) contieneCaracteresEspeciales = true;
    }

    // Calcular el puntaje de seguridad de la contraseña
    if (longitud >= 8) {
        puntaje += 1;
        if (longitud >= 10) puntaje += 1;
        if (longitud >= 12) puntaje += 1;
        if (contieneMayusculas) puntaje += 1;
        if (contieneMinusculas) puntaje += 1;
        if (contieneNumeros) puntaje += 1;
        if (contieneCaracteresEspeciales) puntaje += 1;
    }

    // Determinar el nivel de seguridad basado en el puntaje
    if (puntaje >= 7) return NivelSeguridad.MuySegura;
    else if (puntaje >= 5 && puntaje <= 7) return NivelSeguridad.MediaSegura;
    else if (puntaje >= 2 && puntaje <= 4) return NivelSeguridad.PocoSegura;
    else if (puntaje >= 1) return NivelSeguridad.Insegura;
    else return NivelSeguridad.Insegura;
};

/**
 * Funcion para obtener el nivel de seguridad de una contraseña
 * @param {*} contraseña Contraseña a evaluar
 */
export const ContraseñaSegura = (setPassword, longitudMinima = 8, longitudMaxima = 12) => {
    const caracteres = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+"

    let contraseñaGenerada = "";

    while (true) {
        const longitud = Math.floor(Math.random() * (longitudMaxima - longitudMinima + 1)) + longitudMinima;

        for (let i = 0; i < longitud; i++) {
            contraseñaGenerada += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        }
        if (contraseñaGenerada.match(/[A-Z]/) && contraseñaGenerada.match(/[a-z]/) && contraseñaGenerada.match(/[0-9]/) && contraseñaGenerada.match(/[!@#$%^&*()_+]/)) {
            break;
        } else {
            contraseñaGenerada = "";
        }

    }
    setPassword(contraseñaGenerada);
};


export const Descargar = (datos, nombre, estado) => {
    //alert(JSON.stringify(datos));   
    if (datos.length === 0) {
        ErrorMessage('Notificación del sistema', 'No Existen datos que descargar')
    } else {

        // const lista = datos.filter(lista=> lista.estadoActivo === estado )
        // Crear una hoja de cálculo
        const hoja = XLSX.utils.json_to_sheet(datos);

        // Crear un libro de trabajo
        const libro = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(libro, hoja, 'Datos');

        // Convertir el libro de trabajo en un archivo Excel
        const nombreArchivo = nombre + '.xlsx';
        XLSX.writeFile(libro, nombreArchivo);
    }
};


export const BuscarPersona = async (cedula, TBFuncionario) => {
    try {

        if (cedula !== '') {



            const existe = await ExisteFuncionario({ cedulaFuncionario: cedula })

            if (existe) {

                const lista = await GetPersona({ cedula: cedula })

                if (lista && lista.length > 0) {
                    const persona = lista[0];

                }
            } else {

                const datosPadron = await GetDatosPadron(cedula);

                if (datosPadron && datosPadron.results && datosPadron.results.length > 0) {
                    const persona = datosPadron.results[0];
                    TBFuncionario.setNombre(persona.firstname || '');
                    TBFuncionario.setapellido1(persona.lastname1 || '');
                    TBFuncionario.setapellido2(persona.lastname2 || '');


                } else {
                    ErrorMessage('Notificación del sistema', 'Datos no encontrados o inexistentes por favor ingresa los datos manualmente')
                    TBFuncionario.setNombre('');
                    TBFuncionario.setapellido1('');
                    TBFuncionario.setapellido2('');
                }

            }

        } else {
            ErrorMessage('Notificación del sistema', 'Debes ingresar un numero de cedula valido')
        }
    } catch (error) {
        console.log("Error al obtener los datos del padrón:", error);
    }
};

export const BuscarEstudiante = async (cedula, TBEstudiante, idNombre, idApellido, idApellido2, setHabilitarDatosFamiliares, TBCondSocioEco) => {
    try {
        if (cedula !== '') {
             
            const existe = await ExisteEstudiante({ cedulaEstudiante: cedula });

            if (existe.estado) {
                const datosEstu = existe.datos;
                const currentYear = new Date().getFullYear();


                // Limpiar los campos del estudiante antes de actualizar con los nuevos datos
                TBEstudiante.setTipoIdentificacion('');
                TBEstudiante.setCedula('');
                TBEstudiante.setNombre('');
                TBEstudiante.setapellido1('');
                TBEstudiante.setapellido2('');
                TBEstudiante.setGenero(0);
                TBEstudiante.setFechaNacimiento('');
                TBEstudiante.setEdad('');
                TBEstudiante.setlugarNacimiento('');
                TBEstudiante.setfotoPerfil('');
                TBEstudiante.setPais(0);
                TBEstudiante.setCondicion(0);
                TBEstudiante.setProvincia(0);
                TBEstudiante.setCanton(0);
                TBEstudiante.setDistrito(0);
                TBEstudiante.setBarrio(0);
                TBEstudiante.setDireccionExacta('');
                TBEstudiante.setTelefono('');
                TBEstudiante.setCelular('');
                TBEstudiante.setCorreo('');
                TBEstudiante.setTelefonoEmergencia('');
                TBEstudiante.setCasoEmergencia('');

                TBCondSocioEco.setIdSocioEconomica(0);
                TBCondSocioEco.setTipoCasa(0);
                TBCondSocioEco.setCondicionVivienda(0);
                TBCondSocioEco.setCantPersonas(0);
                TBCondSocioEco.setAccesoInternet(0);
                TBCondSocioEco.setIngresosMensuales('');
                TBCondSocioEco.setGastosMensuales('');
                TBCondSocioEco.setTipoBeca(0);
                TBCondSocioEco.setBonoEscolar(0);
                TBCondSocioEco.setOtroBeneficio('');
                TBCondSocioEco.setAño('');

                // Asignar datos del estudiante
                if (datosEstu.cedulaEstudiante.length === 9) {
                    TBEstudiante.setTipoIdentificacion(1);
                } else if (datosEstu.cedulaEstudiante.length === 12) {
                    TBEstudiante.setTipoIdentificacion(2);
                }
                TBEstudiante.setCedula(datosEstu.cedulaEstudiante);
                TBEstudiante.setNombre(datosEstu.nombreCompleto);
                TBEstudiante.setapellido1(datosEstu.apellido1);
                TBEstudiante.setapellido2(datosEstu.apellido2);
                TBEstudiante.setGenero(datosEstu.genero);
                TBEstudiante.setFechaNacimiento(datosEstu.fechaNacimiento.split('T')[0]);

                // Calcular y asignar la edad
                const calcularEdad = (fechaNacimiento) => {
                    const fecha = new Date(fechaNacimiento);
                    const hoy = new Date();
                    let años = hoy.getFullYear() - fecha.getFullYear();
                    let meses = hoy.getMonth() - fecha.getMonth();
                    let dias = hoy.getDate() - fecha.getDate();

                    if (meses < 0 || (meses === 0 && dias < 0)) {
                        años--;
                        meses += 12;
                    }

                    if (dias < 0) {
                        meses--;
                        const mesAnterior = hoy.getMonth() - 1;
                        const diasMesAnterior = new Date(hoy.getFullYear(), mesAnterior + 1, 0).getDate();
                        dias += diasMesAnterior;
                    }

                    return `${años} años ${meses} meses y ${dias} días`;
                };

                TBEstudiante.setEdad(calcularEdad(datosEstu.fechaNacimiento));

                // Asignar otros datos del estudiante
                TBEstudiante.setlugarNacimiento(datosEstu.lugarNacimiento);
                TBEstudiante.setfotoPerfil(datosEstu.foto);
                TBEstudiante.setSelectedImage(datosEstu.foto);
                TBEstudiante.setPais(datosEstu.nacionalidad);
                TBEstudiante.setCondicion(datosEstu.condicion);
                TBEstudiante.setProvincia(datosEstu.provincia);
                TBEstudiante.setCanton(datosEstu.canton);
                TBEstudiante.setDistrito(datosEstu.distrito);
                TBEstudiante.setBarrio(datosEstu.barrio);
                TBEstudiante.setDireccionExacta(datosEstu.direccionExacta);
                TBEstudiante.setTelefono(datosEstu.telefono);
                TBEstudiante.setCelular(datosEstu.celular);
                TBEstudiante.setCorreo(datosEstu.correo);
                TBEstudiante.setTelefonoEmergencia(datosEstu.contactoEmergencia);
                TBEstudiante.setCasoEmergencia(datosEstu.contactarA);
                TBEstudiante.setActualizar(false)
                setHabilitarDatosFamiliares(true);
                const existeCondicion = await ExisteConsdicionSocio_Economica({ cedulaEstuduante: cedula, annio: currentYear })
                
               
                if (existeCondicion) {
                   
                    const datos = await ListarConsdicionSocio_Economica({ cedulaEstuduante: cedula, annio: currentYear })
                    if (datos && datos.length > 0) {
                        const socioEconomica = datos[0]; // Suponiendo que solo hay un objeto en el array
                       
                        TBCondSocioEco.setExisteSocioEco(existeCondicion)
    
                        TBCondSocioEco.setIdSocioEconomica(socioEconomica.IdSocioEconomica);
                        TBCondSocioEco.setTipoCasa(socioEconomica.tipoCasa);
                        TBCondSocioEco.setCondicionVivienda(socioEconomica.condicionCasa);
                        TBCondSocioEco.setCantPersonas(socioEconomica.cantidadFamiliares);
                        TBCondSocioEco.setAccesoInternet(socioEconomica.accesoInternet);
                        TBCondSocioEco.setIngresosMensuales(socioEconomica.ingresosTotales);
                        TBCondSocioEco.setGastosMensuales(socioEconomica.gastoMensuales);
                        TBCondSocioEco.setTipoBeca(socioEconomica.posseBeca);
                        TBCondSocioEco.setBonoEscolar(socioEconomica.bonoEscolar);
                        TBCondSocioEco.setOtroBeneficio(socioEconomica.otroTipoBeneficio);
                        TBCondSocioEco.setAño(socioEconomica.annio);
                    }
                    
                } else {
                    
                    TBCondSocioEco.setExisteSocioEco(existeCondicion)
                    TBCondSocioEco.setIdSocioEconomica(0);
                    TBCondSocioEco.setTipoCasa(0);
                    TBCondSocioEco.setCondicionVivienda(0);
                    TBCondSocioEco.setCantPersonas(0);
                    TBCondSocioEco.setAccesoInternet(0);
                    TBCondSocioEco.setIngresosMensuales('');
                    TBCondSocioEco.setGastosMensuales('');
                    TBCondSocioEco.setTipoBeca(0);
                    TBCondSocioEco.setBonoEscolar(0);
                    TBCondSocioEco.setOtroBeneficio('');
                    TBCondSocioEco.setAño('');
                }

                
                // console.log(JSON.stringify(SocioEconomica));
            } else {
                const datosPadron = await GetDatosPadron(cedula);

                if (datosPadron && datosPadron.results && datosPadron.results.length > 0) {
                    const persona = datosPadron.results[0];
                    TBEstudiante.setNombre(persona.firstname || '');
                    TBEstudiante.setapellido1(persona.lastname1 || '');
                    TBEstudiante.setapellido2(persona.lastname2 || '');
                    document.getElementById(idNombre).setAttribute('disabled', true);
                    document.getElementById(idApellido).setAttribute('disabled', true);
                    document.getElementById(idApellido2).setAttribute('disabled', true);
                } else {
                    ErrorMessage('Notificación del sistema', 'Datos no encontrados o inexistentes por favor ingresa los datos manualmente');
                    TBEstudiante.setNombre('');
                    TBEstudiante.setapellido1('');
                    TBEstudiante.setapellido2('');
                    document.getElementById(idNombre).removeAttribute('disabled');
                    document.getElementById(idApellido).removeAttribute('disabled');
                    document.getElementById(idApellido2).removeAttribute('disabled');
                }
            }
        } else {
            ErrorMessage('Notificación del sistema', 'Debes ingresar un número de cédula válido');
        }
    } catch (error) {
        console.log("Error al obtener los datos del padrón:", error);
    }
};


export const BuscarPersonaFamiliar = async (cedula, TBPersona, idCedula, idNombre, idApellido, idApellido2, setHabilitarDatosSocioEconomicos) => {
    try {

        if (cedula !== '') {

            TBPersona.setNombreFami('')
            TBPersona.setApellido1Fami('')
            TBPersona.setApellido2Fami('')
            TBPersona.setGeneroFami(0)
            TBPersona.setEstadoCivil(0)
            TBPersona.setViveConEstudiante(0)
            TBPersona.setTelefonoFami('')
            TBPersona.setCorreoFami('')
            TBPersona.setParentesco(0)
            TBPersona.setOtroParentesco('')
            TBPersona.setNacionalidadFami(0)
            TBPersona.setCondicionFami(0)
            TBPersona.setProvinciaFami(0)
            TBPersona.setCantonFami(0)
            TBPersona.setDistritoFami(0)
            TBPersona.setBarrioFami(0)
            TBPersona.setDireccionFami('')
            TBPersona.setNivelEscolaridad(0)
            TBPersona.setOtraOcupacion('')
            TBPersona.setOCupacion(0)
            TBPersona.setLugarTrabajo('')
            TBPersona.setTipoNacionalidad(0)

            const existe = await ExistePersona({ cedula: cedula });
            if (existe) {
                const lista = await GetPersona({ cedula: cedula })
                TBPersona.setNombreFami('')
                TBPersona.setApellido1Fami('')
                TBPersona.setApellido2Fami('')
                TBPersona.setGeneroFami(0)
                TBPersona.setEstadoCivil(0)
                TBPersona.setViveConEstudiante(0)
                TBPersona.setTelefonoFami('')
                TBPersona.setCorreoFami('')
                TBPersona.setParentesco(0)
                TBPersona.setOtroParentesco('')
                TBPersona.setNacionalidadFami('')
                TBPersona.setCondicionFami(0)
                TBPersona.setProvinciaFami('')
                TBPersona.setCantonFami('')
                TBPersona.setDistritoFami('')
                TBPersona.setBarrioFami('')
                TBPersona.setDireccionFami('')
                TBPersona.setNivelEscolaridad(0)
                TBPersona.setOtraOcupacion('')
                TBPersona.setOCupacion(0)
                TBPersona.setLugarTrabajo('')
                TBPersona.setTipoNacionalidad(0)
                if (lista && lista.length > 0) {
                    const persona = lista[0];
                    TBPersona.setDatosPersona(persona)
                    //alert(JSON.stringify(persona));

                    TBPersona.setIdentificacion(persona.cedula)
                    TBPersona.setTipoIdentificacion(persona.tipoIdentificacion)
                    TBPersona.setNombreFami(persona.nombreCompleto)
                    TBPersona.setApellido1Fami(persona.apellido1)
                    TBPersona.setApellido2Fami(persona.apellido2)
                    TBPersona.setGeneroFami(persona.genero)
                    TBPersona.setEstadoCivil(persona.estadoCivil)
                    TBPersona.setViveConEstudiante(persona.viveConEstudiante)
                    TBPersona.setTelefonoFami(persona.telefono)
                    TBPersona.setCorreoFami(persona.email)
                    TBPersona.setParentesco(persona.parentesco)
                    TBPersona.setOtroParentesco(persona.otroParentesco)
                    TBPersona.setNacionalidadFami(persona.nacionalidad)
                    TBPersona.setCondicionFami(persona.condicion)
                    TBPersona.setProvinciaFami(persona.provincia)
                    TBPersona.setCantonFami(persona.canton)
                    TBPersona.setDistritoFami(persona.distrito)
                    TBPersona.setBarrioFami(persona.barrio)
                    TBPersona.setDireccionFami(persona.direccion)
                    TBPersona.setNivelEscolaridad(persona.nivelEscolaridad)
                    TBPersona.setOtraOcupacion(persona.otraOcupacion)
                    TBPersona.setOCupacion(persona.ocupacion)
                    TBPersona.setLugarTrabajo(persona.lugarTrabajo)
                    TBPersona.setTipoNacionalidad(persona.tipoNacionalidad)
                    setHabilitarDatosSocioEconomicos(true);
                }
            } else {

                const datosPadron = await GetDatosPadron(cedula);

                if (datosPadron && datosPadron.results && datosPadron.results.length > 0) {
                    const persona = datosPadron.results[0];
                    TBPersona.setNombreFami(persona.firstname || '');
                    TBPersona.setApellido1Fami(persona.lastname1 || '');
                    TBPersona.setApellido2Fami(persona.lastname2 || '');
                    //document.getElementById(idCedula).setAttribute('disabled', true);
                    document.getElementById(idNombre).setAttribute('disabled', true);
                    document.getElementById(idApellido).setAttribute('disabled', true);
                    document.getElementById(idApellido2).setAttribute('disabled', true);

                } else {
                    ErrorMessage('Notificación del sistema', 'Datos no encontrados o inexistentes por favor ingresa los datos manualmente')
                    TBPersona.setNombreFami('');
                    TBPersona.setApellido1Fami('');
                    TBPersona.setApellido2Fami('');
                    // document.getElementById(idCedula).removeAttribute('disabled');
                    document.getElementById(idNombre).removeAttribute('disabled');
                    document.getElementById(idApellido).removeAttribute('disabled');
                    document.getElementById(idApellido2).removeAttribute('disabled');
                }

            }

        } else {
            ErrorMessage('Notificación del sistema', 'Debes ingresar un numero de cedula valido')
        }
    } catch (error) {
        console.log("Error al obtener los datos del padrón:", error);
    }
};

export const ObtenerEdad = (e, setEdad, setFechaNacimiento) => {
    let fechaStr = e.target.value;

    // Verificar que la fecha seleccionada sea una fecha completa y válida
    if (fechaStr.length !== 10) {
        setEdad('');
        return;
    }

    let fecha = new Date(fechaStr); // Convertir la cadena en un objeto Date
    if (isNaN(fecha.getTime())) {
        ErrorMessage('Fecha no válida', 'Fecha seleccciónada no es valida.');
        return;
    }

    let hoy = new Date(); // Fecha actual

    // Calcular la diferencia en años
    let años = hoy.getFullYear() - fecha.getFullYear();

    // Calcular la diferencia en meses
    let meses = hoy.getMonth() - fecha.getMonth();

    // Calcular la diferencia en días
    let dias = hoy.getDate() - fecha.getDate();

    // Ajustar los cálculos si el mes actual es anterior al mes de nacimiento o
    // si el mes es el mismo pero el día actual es anterior al día de nacimiento
    if (meses < 0 || (meses === 0 && dias < 0)) {
        años--;
        meses += 12;
    }

    if (dias < 0) {
        meses--;
        // Obtener la cantidad de días del mes anterior al mes actual
        let mesAnterior = hoy.getMonth() - 1;
        if (mesAnterior < 0) {
            mesAnterior = 11;
        }
        let diasMesAnterior = new Date(hoy.getFullYear(), mesAnterior + 1, 0).getDate();
        dias += diasMesAnterior;
    }

    setFechaNacimiento(fechaStr);
    setEdad(años + ' años ' + meses + ' meses y ' + dias + ' días');
}

export const ActivarTab = (index, setActiveTab, estado) => {
    if (estado) {
        setActiveTab(index);
    } else {
        ErrorMessage('Notificación del sistema', 'Debes registrar los datos personales del estudiante para continuar');
        return;
    }
}

