import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb } from 'reactstrap';
import { TbAsignaturas } from '../../../../CapaEntidades/TbAsignaturas';
import MenuItem from '../../../components/MenuItem';
import { ValidarSoloLetras, ValidarSoloNumeros } from '../../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import { IoMdReturnLeft } from "react-icons/io";
import { getAsignaturas, saveAsignatura } from '../../../../CapaDatos/DatosAsignaturas';
import { ErrorMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { validarSoloLetrasYEspacios } from '../../../../CapaUtilidades/Utils/validaciones';
import { ObtenerNombreInst, obtenerIdInstitucion } from '../../../../CapaUtilidades/Utils/encryptUserData';

const FrmRegis_Asignatura = ({ codigoPresupuestario }) => {
    
    const TBAsignaturas = TbAsignaturas();
    TBAsignaturas.CodigoPresupuestario = codigoPresupuestario;
    
    const fetchData = async () => {


        const parametro = { estado: 1};

        const data = await getAsignaturas(1);
        //alert(JSON.stringify(data));
        TBAsignaturas.setAsignaturas(data);
        

    };

    useEffect(() => {

        fetchData();
        
    }, []);



    useEffect(() => {
        TBAsignaturas.setAsignaturaCentro(obtenerIdInstitucion());
        TBAsignaturas.setNombreInst(ObtenerNombreInst())
    }, []);

    const ObtenerCodAsignatura = (nombre) => {
        if (nombre !== "") {
            let codigo;
            const palabras = nombre.split(" ");
            if (palabras.length > 1) {
                // Si hay espacio, toma las tres primeras letras de cada palabra
                codigo = palabras[0].substring(0, 3).toUpperCase() + ". " + palabras[1].substring(0, 3).toUpperCase();
            } else {
                // Si no hay espacio, toma solo las tres primeras letras del nombre
                codigo = nombre.substring(0, 3).toUpperCase();
            }
            TBAsignaturas.setCodigoAsignatura("CA-" + codigo + "-" + TBAsignaturas.CodigoPresupuestario);
            TBAsignaturas.setAsignaturaCentro(TBAsignaturas.CodigoPresupuestario);
        } else {
            TBAsignaturas.setCodigoAsignatura("");
            TBAsignaturas.setAsignaturaCentro(TBAsignaturas.CodigoPresupuestario);
        }
    };
    
    


    const GuardarAsignatura = async (event) => {
        event.preventDefault();



        const asignaturaExiste = TBAsignaturas.Asignaturas.some(asig => asig.codigoAsignatura === TBAsignaturas.CodigoAsignatura);

        if (!asignaturaExiste) {

            const data = {
                codigoAsignatura: TBAsignaturas.CodigoAsignatura,
                nombreAsignatura: TBAsignaturas.NombreAsignatura,
                calificacionMinima: TBAsignaturas.CalificacionMinima,
                asignaturaCentro: TBAsignaturas.AsignaturaCentro
            }

            const success = await saveAsignatura(data);

            if (success) {

                TBAsignaturas.setNombreAsignatura("");
                TBAsignaturas.setCodigoAsignatura("");
                TBAsignaturas.setCalificacionMinima("");
                TBAsignaturas.setAsignaturaCentro(TBAsignaturas.CodigoPresupuestario);
                fetchData();
            } else {
                ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante el proceso de guardado')
                return;
            }


        } else {
            ErrorMessage('Notificación del Sistema', 'La asignatura ya se encuentra registrada')
        }





    }



    return (

        <Container fluid className="pt-2 Divcontenedor ">

            <Col>
                <Breadcrumb listTag="div">
                    <MenuItem
                        className='p-0 text-dark'

                        text="Asignaturas "
                        to="/Menu/FrmAsignaturas"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Registrar Asignaturas"
                        to="/Menu/FrmRegis_Asignatura"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>

            <Container fluid className='mt-0 p-3  shadow2 border rounded ' >
                <Form onSubmit={GuardarAsignatura}>
                    <h5 className='py-2' > Registrar Asignatura </h5>
                    <Row>
                        <Col md={2}> </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="calificacion" className="me-2">
                                    Nombre de la Asignatura
                                </Label>

                                <Input
                                    required
                                    id="calificacion"
                                    name="calificacion"
                                    
                                    type="text"
                                    value={TBAsignaturas.NombreAsignatura}
                                    onChange={(e) => {{ ValidarSoloLetras(e, TBAsignaturas.setNombreAsignatura, TBAsignaturas.setAsignaturaValida); ObtenerCodAsignatura(e.target.value)} }}
                                    className={TBAsignaturas.AsignaturaValida ? ' ' : 'is-invalid  '}
                                />

                                {!TBAsignaturas.AsignaturaValida && <div className="invalid-feedback">Formato de ingresado no valido</div>}


                            </FormGroup>
                        </Col>
                        <Col md={2}> </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="calificacion" className="me-2">
                                    Calificación Minima
                                </Label>

                                <Input
                                    required
                                    id="calificacion"
                                    name="calificacion"
                                    
                                    type="text"
                                    value={TBAsignaturas.CalificacionMinima}
                                    onChange={(event) => ValidarSoloNumeros(event, TBAsignaturas.setCalificacionMinima, TBAsignaturas.setCalificacionValida)}
                                    maxLength={2}
                                    minLength={2}
                                    className={TBAsignaturas.CalificacionValida ? ' ' : 'is-invalid  '}
                                />

                                {!TBAsignaturas.CalificacionValida && <div className="invalid-feedback">Formato de ingresado no valido</div>}


                            </FormGroup>
                        </Col>
                        <Col md={2}> </Col>
                        <Col md={2}> </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="calificacion" className="me-2">
                                    Código Asignatura
                                </Label>

                                <Input
                                    readOnly
                                    required
                                    id="calificacion"
                                    name="calificacion"
                                    placeholder=" Ejem: CA-MAT-0000"
                                    type="text"
                                    value={TBAsignaturas.CodigoAsignatura } 


                                />
                                <p className='LetraPeq'><strong>* Este campo es autogenerado por el Sistema.</strong></p>
                            </FormGroup>
                        </Col>
                        <Col md={2}> </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="calificacion" className="me-2">
                                    Código Presupuestrario
                                </Label>

                                <Input
                                    readOnly
                                    required
                                    id="calificacion"
                                    name="calificacion"
                                     
                                    type="text"
                                    value={TBAsignaturas.AsignaturaCentro + " - "+ TBAsignaturas.NombreInst}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}> </Col>
                    </Row>
                    <Col className='d-flex justify-content-between '>

                        <Button type="button" className='p-0 btn  px-2 btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                            <ul>
                                <MenuItem
                                    className='btnAceptar'
                                    icon={<IoMdReturnLeft className="me-1" />}
                                    text="Volver"

                                    to="/Menu/FrmAsignaturas"
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                        <Button type="submit" className='p-1 btn  px-2 btnAceptar d-flex justify-content-end' id='GuardarFuncionario' >
                            Registrar Asignatura
                        </Button>
                    </Col>
                </Form>
            </Container>


        </Container>

    )
}

export default FrmRegis_Asignatura
