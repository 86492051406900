import React, { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';


import Logo from "../../assets/Nombre 3.png";
import LogoSiage1 from "../../assets/SVG/log1.svg"
import LogoSiage2 from "../../assets/SVG/log2.svg"

import PrincipalSVG from "../../assets/SVG/4662928_2450616 (1).svg"
import Encargado from "../../assets/imagenes/encargado.png";
import Profesor from "../../assets/imagenes/profesor.png";
import Administrativo from "../../assets/imagenes/administrativo.png";

import { Container, Row, Col, Form, FormGroup, Label, Input, Button, FormText, Div, InputGroup, InputGroupText } from 'reactstrap'
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Barra from '../../components/Barra';
import ContactForm from "../../components/ContacForm";
import { validarSoloNumeros } from "../../../CapaUtilidades/Utils/validaciones";
import { ValidarSoloNumeros } from "../../../CapaUtilidades/Utils/FuncionesDeValidacion";
import { inicioSesion, inicioSesionEncargado } from "../../../CapaDatos/DatosLogin";
import { ErrorMessage } from "../../../CapaUtilidades/Utils/FuncionesDeReutilizacion";
import { GuardarCahe } from "../../../CapaUtilidades/Utils/Cahe";
import { GuardarEnLocalStorage, encryptUserData } from "../../../CapaUtilidades/Utils/encryptUserData";

import { getInstitucion } from "../../../CapaDatos/DatosDesarrolladores";
import Swal from "sweetalert2";


function Login() {




    const [username, setUsername] = useState('206750945');
    const [password, setPassword] = useState('AxellDereck22@');


    const [userValido, setUserValido] = useState(true);

    let usuarioI = '', permiso = '', nombreinstitucion = ''

    const [error, setError] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);

    const [isVisible, setIsVisible] = useState(false);
    const [mostrarContrasena, setMostrarContrasena] = useState(false);
    // Función para hacer scroll hasta el inicio de la página
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Función para manejar el evento de desplazamiento
    const handleScroll = () => {
        if (window.pageYOffset > 100) { // Cambia 100 según lo que consideres apropiado
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Agregar evento de desplazamiento cuando el componente se monta
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('TKO');
        if (isLoggedIn === 'true') {
            setLoggedIn(true);
        }
    }, []);

    const ValidarInicioSecion = async (e) => {
        e.preventDefault();

        const parametro = {
            usuario: username,
            passwd: password,
            estado: 1
        }
        //alert(JSON.stringify(parametro));
        let data = await inicioSesion(parametro)

        //alert(JSON.stringify(data));



        if (data !== null && data.length !== 0) {

            let usuarioValido = false;
            let passwordValido = false;
            let id = '';
            let user = '';
            let pass = '';
            //alert(JSON.stringify(data));
            for (const DatosUsuario of data) {

                let { usuario, passwd, idInstitucion } = DatosUsuario;

                if (username === usuario) {

                    usuarioI = usuario
                    if (password === passwd) {
                        id = idInstitucion
                        user = usuario
                        pass = passwd
                        usuarioValido = true;
                        passwordValido = true;
                        break;

                    } else {

                        passwordValido = false;

                    }

                }
            }
            if (!passwordValido) {
                setError('La contraseña es incorrecta.');
                return; // Detener la ejecución si el nombre de usuario no coincide
            }
            if (usuarioValido && passwordValido) {

                const inputOptions = new Promise(async (resolve) => {

                    const options = {};
                    // // Obtener las idInstitucion disponibles para el usuario
                    const idInstituciones = data
                        .filter(usuario => usuario.usuario === user && usuario.passwd === pass) // Filtrar los usuarios que coincidan con user y pass
                        .map(usuario => usuario.idInstitucion);
                    // // Crear un objeto con las idInstitucion como clave y valor

                    for (const id of idInstituciones) {
                        const nombreinsti = await getInstitucion({ codigoPresupuestario: id });
                        options[id] = nombreinsti; // Asignar el nombre de la institución como valor
                    }
                    // Resolver la promesa con las opciones de input
                    resolve(options);
                });

                const { value: idInstitucionSeleccionada } = await Swal.fire({
                    title: "Seleccione la institucion a la que desea ingresar",
                    input: "radio",
                    inputOptions,
                    inputValidator: (value) => {
                        if (!value) {
                            return "¡Debes seleccionar una opción!";
                        }
                    },
                    allowOutsideClick: false,
                    width: 700,

                    showCloseButton: true,
                    showClass: {
                        popup: `
                          animate__animated
                          animate__zoomIn
                          animate__faster
                        `
                    },
                    hideClass: {
                        popup: `
                          animate__animated
                          animate__zoomOut
                          animate__faster
                        `
                    }
                });

                if (idInstitucionSeleccionada === null || idInstitucionSeleccionada === undefined) {
                    return;
                }
                if (idInstitucionSeleccionada) {

                    const permisoSeleccionado = data.find(dat => dat.idInstitucion === idInstitucionSeleccionada);
                    nombreinstitucion = await getInstitucion({ codigoPresupuestario: permisoSeleccionado.idInstitucion });

                    permiso = permisoSeleccionado.permisosUsuario;


                }
                GuardarEnLocalStorage(usuarioI, idInstitucionSeleccionada, permiso, nombreinstitucion);
                setLoggedIn(true);
            }








        } else {
            let data2 = await inicioSesionEncargado(parametro)
            //alert(JSON.stringify(data2));
            if (data2 !== null && data2.length !== 0) {
                let usuarioValido = false;
                let passwordValido = false;
                let id = '';
                let user = '';
                let pass = '';
                //alert(JSON.stringify(data));
                for (const DatosUsuario of data2) {

                    let { usuario, passwd, idInstitucion } = DatosUsuario;

                    if (username === usuario) {

                        usuarioI = usuario
                        if (password === passwd) {
                            id = idInstitucion
                            user = usuario
                            pass = passwd
                            usuarioValido = true;
                            passwordValido = true;
                            break;

                        } else {

                            passwordValido = false;

                        }

                    }
                }
                if (!passwordValido) {
                    setError('La contraseña es incorrecta.');
                    return; // Detener la ejecución si el nombre de usuario no coincide
                }
                if (usuarioValido && passwordValido) {
                    const inputOptions = new Promise(async (resolve) => {
                        // // Obtener las idInstitucion disponibles para el usuario
                        const idInstituciones = data2
                            .filter(usuario => usuario.usuario === user && usuario.passwd === pass) // Filtrar los usuarios que coincidan con user y pass
                            .map(usuario => usuario.idInstitucion);
                        // // Crear un objeto con las idInstitucion como clave y valor
                        const options = {};
                        // const nombreinsti = await getInstitucion({ codigoPresupuestario: id });
                        //     options[id] = nombreinsti;
                        for (const id of idInstituciones) {
                            const nombreinsti = await getInstitucion({ codigoPresupuestario: id });
                            options[id] = nombreinsti; // Asignar el nombre de la institución como valor
                        }
                        // Resolver la promesa con las opciones de input
                        resolve(options);
                    });

                    const { value: idInstitucionSeleccionada } = await Swal.fire({
                        title: "Seleccione la institucion a la que desea ingresar",
                        input: "radio",
                        inputOptions,
                        inputValidator: (value) => {
                            if (!value) {
                                return "¡Debes seleccionar una opción!";
                            }
                        },
                        allowOutsideClick: false,
                        width: 700,

                        showCloseButton: true,
                        showClass: {
                            popup: `
                              animate__animated
                              animate__zoomIn
                              animate__faster
                            `
                        },
                        hideClass: {
                            popup: `
                              animate__animated
                              animate__zoomOut
                              animate__faster
                            `
                        }
                    });

                    if (idInstitucionSeleccionada === null || idInstitucionSeleccionada === undefined) {
                        return;
                    }
                    if (idInstitucionSeleccionada) {

                        const permisoSeleccionado = data2.find(dat => dat.idInstitucion === idInstitucionSeleccionada);

                        //alert(JSON.stringify(permisoSeleccionado));
                        permiso = permisoSeleccionado.permisoUsuario;

                        nombreinstitucion = await getInstitucion({ codigoPresupuestario: permisoSeleccionado.idInstitucion });




                    }
                    GuardarEnLocalStorage(usuarioI, idInstitucionSeleccionada, permiso, nombreinstitucion);
                    setLoggedIn(true);
                }


            }
            else {

                setUsername("");
                setPassword("");
                ErrorMessage("Inicio de Sesión", "Usuario ó contraseña incorrectos  ");
                return;
            }
        }



    };



    if (loggedIn) {
        return <Navigate to="/Menu" />;


    }


    // Redirigir automáticamente si el usuario ya está autenticado
    if (localStorage.getItem('Token') === 'true') {
        return <Navigate to="/Menu" />;
    }

    const toggleMostrarContrasena = () => {
        setMostrarContrasena(!mostrarContrasena); // Cambia el estado para mostrar o no la contraseña
    };
    return (

        <div className="container-fluid p-0 cero-pading-margin">
            <header className="bg-nav">
                <nav class=" navbar  bg-nav fixed-top navbar-login p-0">
                    <div class="container-fluid">

                        <div className="row contenedor-logo">
                            <div className="col-6 n logo-empresa"><img src={LogoSiage2} alt="SIGAE Logo" /></div>
                            <div className="col-6 as nombre-empresa"><a>SIGÄE</a></div>
                        </div>

                        <button class="navbar-toggler btn-ingresar" type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                            Iniciar Sesión
                        </button>

                    </div>
                </nav>

            </header>

            <div className="offcanvas offcanvas-end efecto-transparente" tabIndex="-1" data-bs-backdrop="static" id="staticBackdrop" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                    <h6 className="offcanvas-title text-white" id="offcanvasNavbarLabel">Login</h6>
                    <button type="button" className="btn-close text-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div class="container-fluid my-5 p-2">

                        <div class="container shadowWhite p-4 br-15  login-container">

                            <div class="row ajustarLogin px-2 shadow">
                                <div className='col-12 mb-3 row'>
                                    <h4 class="  text-center text-white">Bienvenido(a)</h4>
                                </div>
                                <div className='col-12'>
                                    <form onSubmit={ValidarInicioSecion}>

                                        <div class="form-floating mb-3">
                                            <FormGroup>
                                                <Label for="password" className="text-white">
                                                    Usuario
                                                </Label>
                                                <Input

                                                    required
                                                    id="usuario"
                                                    name="usiario"
                                                    title="Formato como aparece en la cedula con los 0"
                                                    placeholder="Usuario"
                                                    value={username}
                                                    onChange={(e) => ValidarSoloNumeros(e, setUsername, setUserValido)}
                                                    className={userValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                                />
                                                {!userValido && <div className="invalid-feedback">El Usuario debe ser tu cedula de Identidad.</div>}
                                            </FormGroup>

                                        </div>


                                        <div class="form-floating mb-3">
                                            <FormGroup>
                                                <Label for=" password" className="text-white">
                                                    Contraseña
                                                </Label>
                                                <InputGroup>
                                                    <Input

                                                        required
                                                        id="password"
                                                        name="password"
                                                        placeholder="Contraseña"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        type={mostrarContrasena ? "text" : "password"} // Cambia el tipo de input según el estado
                                                    />
                                                    <InputGroupText addonType="append" className='p-0'>
                                                        <Button onClick={toggleMostrarContrasena} color="" className='p-0 px-2'>
                                                            {mostrarContrasena ? <FiEyeOff /> : <FiEye />} {/* Cambia el ícono según el estado */}
                                                        </Button>
                                                    </InputGroupText>
                                                </InputGroup>

                                            </FormGroup>

                                        </div>

                                        <button className='btnSuscess' type="submit" >Ingresar</button>
                                    </form>

                                </div>
                                <div className='col-12  my-3 text-center'>
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="PrincipalSvgContainer">
                <div className="row">
                    <div className="PrincipalSvgContainer ">
                        <div class="overlay-imagen">
                            <h1 className="">SIGÄE</h1>
                            <h2>Sistema de Gestión Administrativa y Educativa</h2>
                        </div>
                        <img src={PrincipalSVG} alt="SIGAE EDUCACIÓN" />
                    </div>
                </div>
            </div>

            <div className=" container-fluid contenedor-fluido" id="sobreNosotros">
                <div className="row container-tittle">
                    <h2>Sobre Nostros</h2>
                    <Barra />
                </div>
                <div className="row div-nosotros-content mt-5">
                    <p>
                        SIGAE es una plataforma virtual de alto nivel tanto para uso administrativo como estudiantil, que facilita el establecimiento de objetivos tanto para la institución como para el estudiante. Estamos transformando la experiencia educativa.
                    </p>
                    <ul>
                        <li>Acceso fácil y conveniente</li>
                        <li>Facilitación de trámites educativos</li>
                        <li>Mayor comunicación y colaboración</li>
                        <li>Participación activa de los padres</li>
                        <li>Transparencia y claridad</li>
                        <li>Acceso único para estudiantes y padres</li>
                    </ul>
                </div>
            </div>

            <div className="contenedor contenedor-perfil" id="perfiles">
                <div className="row container-tittle">
                    <h2>Perfiles de acceso</h2>
                    <Barra />
                    <p className="text-center mt-5 p-content">
                        SIGÄE gestiona perfiles individuales para cada usuario de la plataforma
                    </p>
                </div>
                <div className="row d-flex justify-content-between flex-wrap contenedor-perfiles">
                    <div className="col-lg-3 py-5 mr-lg-1 row-perfil">
                        <div className="card-perfil">
                            <div class="imagen-perfil">
                                <img src={Encargado} alt="Encargado" />
                            </div>
                            <div class="texto-perfil pt-3">
                                <h2>Encargado</h2>
                                <p>
                                    El encargado están facultado para acceder al sistema y revisar integralmente el desempeño académico del estudiante, incluyendo sus calificaciones, resultados de exámenes, autorizaciones para salir de la institución, llevar a cabo gestiones del centro educativo.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 py-5 mr-lg-1 row-perfil">
                        <div className="card-perfil">
                            <div class="imagen-perfil">
                                <img src={Profesor} alt="Profesor" />
                            </div>

                            <div class="texto-perfil pt-3">
                                <h2>Profesor</h2>
                                <p>
                                    Los docentes administran los grupos, lo que les permite llevar a cabo evaluaciones, establecer el calendario académico, revisar calificaciones, realizar reportes al hogar y gestionar la asistencia de manera eficiente y efectiva.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 py-5 row-perfil">
                        <div className="card-perfil">
                            <div class="imagen-perfil">
                                <img src={Administrativo} alt="Administrativo" />
                            </div>
                            <div class="texto-perfil pt-3">
                                <h2>Administrativo</h2>
                                <p>
                                    Los administradores personalizan la plataforma según las necesidades específicas de la institución, gestionando tanto los procesos administrativos como los educativos, como el proceso de admisión y matrícula.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" container-fluid contenedor-fluido" id="soporte">
                <div className="row container-tittle">
                    <h2>Soporte 24/7</h2>
                    <Barra />
                </div>
                <div className="row div-nosotros-content">
                    <p className="text-center mt-5">
                        SIGAE cuenta con un equipo de soporte técnico que se encuentra permanentemente disponible para brindarte asistencia, todos los días y en cualquier momento.
                    </p>
                </div>
            </div>

            <div className="contenedor" id="contacto">
                <div className="row container-tittle">
                    <h2>Contacto</h2>
                    <Barra />
                    <p className="text-center mt-5 p-content">
                        Cuéntenos sus necesidades y nosotros le proporcionaremos la solución óptima para su institución. Póngase en contacto con nosotros para comenzar.
                    </p>
                </div>
                <div className="row">
                    <div className="col-lg-4 py-5 mr-lg-3">
                        <div className="card-contacto">
                            <div className="container-fluid card-contacto-info">

                                <div className="row pb-3">
                                    <h4><i class="bi bi-geo-alt-fill fs-5 mr-3 text-primary"></i> Ubicación</h4>
                                    <p>Upala, Upala, Alajuela, 75 mts Sur del juzgado penal.</p>
                                </div>

                                <div className="row pb-3">
                                    <h4><i class="bi bi-envelope-at-fill fs-6  mr-3 text-primary px-1"></i>Correo Electrónico</h4>
                                    <p>info@sigaeapp.com</p>
                                </div>
                                <div className="row pb-3">
                                    <h4><i class="bi bi-telephone-fill fs-6 mr-3 text-primary px-1"></i>Telefóno</h4>
                                    <p>(+506) 7094 3873</p>
                                </div>
                                <div class="row">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31342.861265935495!2d-85.03673345470096!3d10.898420535415399!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f75498160737249%3A0x6da06feef33a6933!2sProvincia%20de%20Alajuela%2C%20Upala!5e0!3m2!1ses-419!2scr!4v1711996888224!5m2!1ses-419!2scr"
                                        width="400"
                                        height="300"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="Mapa de Alajuela, Upala"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 py-5">
                        <div className="card-contacto pb-5">
                            <ContactForm />
                        </div>
                    </div>
                </div>

            </div>

            <footer className="pb-2 home-footer   text-black  ">
                <svg className="curveDownColor" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <path d="M0 0 C 50 100 80 100 100 0 Z"></path>
                </svg>
                <div className="container-fluid  pt-51">
                    <div className="container-fluid">
                        <div className="row  text-white ">
                            <div className="col-lg-3 col-md-6 col-sm 12 col-12">
                                <div className="row align-items-center">
                                    <div className="col-12 mt-2 d-flex justify-content-center">
                                        <a className="navbar-brands footer-app-name" href="#">
                                            SIGÄE
                                        </a>
                                    </div>
                                    <div className="col-12 mt-2 d-flex justify-content-center ">
                                        <ul className="list-inline">
                                            <li className="mt-2"><a href="" className="text-uppercase   text-Enlaces">
                                                <i class="bi bi-geo-alt-fill mr-2 text-primary"></i>  Costa Rica
                                            </a></li>

                                            <li className="mt-2"><a href="tel:+50670943873" className="   text-Enlaces" style={{ textDecoration: 'none' }}>
                                                <i class="bi bi-telephone-fill mr-2 text-primary px-1"></i>
                                                <span className="span-negrita">Teléfono: </span>
                                                (+506) 7094 3873
                                            </a></li>

                                            <li className="mt-2 mb-2"><a href="mailto:info@jyjtechsolutions.com" className="enlace text-Enlaces"
                                                style={{ textDecoration: 'none' }}>
                                                <i class="bi bi-envelope-at-fill  mr-2 text-primary px-1"></i>
                                                <span className="span-negrita">Correo: </span>
                                                info@sigaeapp.com
                                            </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm 12 col-12">
                                <div className="row ">
                                    <div className="col-12 mt-2 d-flex justify-content-center">
                                        <h4 className="footer-tittle">Mapa del Sitio</h4>
                                    </div>
                                    <div className="col-12 mt-2 d-flex justify-content-center ">
                                        <ul className="list-inline">
                                            <li className="mt-2"><a href="#" className="text-Enlaces">Inicio</a></li>
                                            <li className="mt-2"><a href="#sobreNosotros" className="text-Enlaces">Sobre Nosotros</a></li>
                                            <li className="mt-2"><a href="#soporte" className="text-Enlaces">Soporte</a></li>
                                            <li className="mt-2"><a href="#perfiles" className="text-Enlaces">Perfiles</a></li>
                                            <li className="mt-2 mb-2"><a href="#contacto" className="text-Enlaces">Contacto</a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-3 col-md-6 col-sm 12 col-12">
                                <div className="row ">
                                    <div className="col-12 mt-2 d-flex justify-content-center">
                                        <h4 className="footer-tittle">Términos y Condiciones</h4>
                                    </div>
                                </div>
                                <div className="col-12 mt-2 d-flex justify-content-center ">
                                    <ul className="list-inline">
                                        <li className="mt-2"><a href="#" className="text-Enlaces">Términos y Condiciones</a></li>
                                        <li className="mt-2"><a href="#" className="text-Enlaces">Políticas</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm 12 col-12">
                                <div className="row ">
                                    <div className="col-12 mt-2 d-flex justify-content-center">
                                        <h4 className="footer-tittle">Nuestras Redes Sociales</h4>
                                    </div>
                                    <div className="col-12 mt-2 d-flex justify-content-center icon-footer-container">

                                        <a href="https://api.whatsapp.com/send?phone=+50670943873&amp;text=Hola,%20quiero%20información%20sobre%20sus%20productos%20" className="whatsapp  px-1" >
                                            <i class="bi bi-whatsapp fs-4"></i>
                                        </a>
                                        <a href="https://www.instagram.com/jyj_techsolutions?igsh=MTJseWtmZWh4YjFiNg==" className="instagram px-1">
                                            <i class="bi bi-instagram fs-4"></i>
                                        </a>
                                        <a href="https://www.facebook.com/profile.php?id=100069400132225&locale=es_LA" className="facebook px-1">
                                            <i class="bi bi-facebook fs-4"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="div-end-footer">
                <div className="col-12 d-flex justify-content-center mt-3 fz-20 text-center d-flex">
                    <p className="text-white text-center">© 2024 All Rights Reserved. Designed by <a href="https://www.jyjtechsolutions.com"><img width="155" height="15" src={Logo} alt="J&J TechSolutions" /></a></p>
                </div>
            </div>

            <button className="btn-subir" onClick={scrollToTop} style={{ display: isVisible ? 'block' : 'none', }}>
                <i className="bi bi-arrow-up "></i>
            </button>
        </div>


    );
}
export default Login;
