const CargosJson = require('../../CapaPresentacion/Json/Cargos.json');
const NombramientoJson = require('../../CapaPresentacion/Json/TiposNombramientos.json');
const RolesJson = require('../../CapaPresentacion/Json/Roles.json');
const TipoDereccionJson = require('../../CapaPresentacion/Json/TipoDirecion.json');
const SexoJson = require('../../CapaPresentacion/Json/Sexo.json');
const GradosJson = require('../../CapaPresentacion/Json/Grados.json');
const modoadquisitivo = require('../../CapaPresentacion/Json/ModosAdquisitivos.json');
const TipoActivosJson = require('../../CapaPresentacion/Json/TiposActivos.json');
const TipoEdificiosJson = require('../../CapaPresentacion/Json/TiposEdificios.json');
const TipoInstitucionJson = require('../../CapaPresentacion/Json/TipoInstitucion.json');
const EstadoCivilJson = require('../../CapaPresentacion/Json/EstadosCivil.json');
const ParentescoJson = require('../../CapaPresentacion/Json/Parentescos.json');
const NivelEscolaridadJson = require('../../CapaPresentacion/Json/NivelEscolaridad.json');
const OcupacionJson = require('../../CapaPresentacion/Json/Ocupacion.json');
const TipoCasaJson = require('../../CapaPresentacion/Json/EstadoCasa.json');
const CondicionCasaJson = require('../../CapaPresentacion/Json/CondicionCasa.json');
const TipoBecaJson = require('../../CapaPresentacion/Json/TipoBecas.json');
const NumerosJson = require('../../CapaPresentacion/Json/Numeros.json');
const DiscapacidadesJson = require('../../CapaPresentacion/Json/Discapacidades.json'); 
const TiposSanguineosJson = require('../../CapaPresentacion/Json/TiposSanguineos.json'); 
const BucoDentalidadJson = require('../../CapaPresentacion/Json/CondicionBucoDental.json'); 
const TipoIdentificacionJson = require('../../CapaPresentacion/Json/TipoNacionalidad.json')

class NivelEscolaridad {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}
class Ocupacion{
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}
class parentesco {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class estadoCivil {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class modosAdquisitivos {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class TipoActivo {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class TipoInstitucion {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class TipoEdificio {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class Cargos {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class Grados {
 constructor(id, nombre) {
    this.id = id;
    this.nombre = nombre;
 }
}

class Nombramiento {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class Roles {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class TipoDereccion {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class Sexo {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class tipoCasa {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}
class condicionCasa {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}
class tipoBeca {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}
class Numeros {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class Discapacidades {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class TiposSanguineos {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class BucoDental {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}

class TipoNacionalidad {
    constructor(id, nombre) {
        this.id = id;
        this.nombre = nombre;
    }
}
/**
 * Clase para realizar las consultas a los archivos Json
 */
class SelectsService {

    async getTipoIdentificacion() {
        const TID = [];
        
        for (const [tipoId, tipo_nombre] of Object.entries(TipoIdentificacionJson)) {
            const tipo = new TipoNacionalidad(parseInt(tipoId), tipo_nombre);
           
            TID.push(tipo);
        }
        return TID;
    }

    
    async getCondicionBucoDental() {
        const list = [];

        for (const [Id, nombre] of Object.entries(BucoDentalidadJson)) {
            const tipo = new BucoDental(parseInt(Id), nombre);
            list.push(tipo);

        }
        return list;
    }

    async getTiposSanguineos() {
        const list = [];

        for (const [Id, nombre] of Object.entries(TiposSanguineosJson)) {
            const tipo = new TiposSanguineos(parseInt(Id), nombre);
            list.push(tipo);

        }
        return list;
    }

    async getDiscapacidades() {
        const list = [];

        for (const [Id, nombre] of Object.entries(DiscapacidadesJson)) {
            const tipo = new Discapacidades(parseInt(Id), nombre);
            list.push(tipo);

        }
        return list;
    }


    async getNumeros() {
        const list = [];

        for (const [Id, nombre] of Object.entries(NumerosJson)) {
            const tipo = new Numeros(parseInt(Id), nombre);
            list.push(tipo);

        }
        return list;
    }

    async getTipoBeca() {
        const list = [];

        for (const [Id, nombre] of Object.entries(TipoBecaJson)) {
            const tipo = new tipoBeca(parseInt(Id), nombre);
            list.push(tipo);

        }
        return list;
    }

    async getCondicionCasa() {
        const list = [];

        for (const [Id, nombre] of Object.entries(CondicionCasaJson)) {
            const tipo = new condicionCasa(parseInt(Id), nombre);
            list.push(tipo);

        }
        return list;
    }

    async getTipoCasa() {
        const list = [];

        for (const [Id, nombre] of Object.entries(TipoCasaJson)) {
            const tipo = new tipoCasa(parseInt(Id), nombre);
            list.push(tipo);

        }
        return list;
    }

    async getOcupacion() {
        const Ocupac = [];

        for (const [Id, nombre] of Object.entries(OcupacionJson)) {
            const ocup = new Ocupacion(parseInt(Id), nombre);
            Ocupac.push(ocup);

        }
        return Ocupac;
    }

    async getNivelEscolaridad() {
        const N_Escolaridad = [];

        for (const [Id, nombre] of Object.entries(NivelEscolaridadJson)) {
            const N_Esco = new NivelEscolaridad(parseInt(Id), nombre);
            N_Escolaridad.push(N_Esco);

        }
        return N_Escolaridad;
    }

    async getParentesco() {
        const parentescoL = [];

        for (const [parentesLcoId, parentescoL_nombre] of Object.entries(ParentescoJson)) {
            const Parent = new parentesco(parseInt(parentesLcoId), parentescoL_nombre);
            parentescoL.push(Parent);

        }
        return parentescoL;
    }

    async getEstadoCivil() {
        const estado = [];

        for (const [estadoId, estado_nombre] of Object.entries(EstadoCivilJson)) {
            const Estado_Civil = new estadoCivil(parseInt(estadoId), estado_nombre);
            estado.push(Estado_Civil);

        }
        return estado;
    }

    async getmodoAdquisitivo() {
        const modoAdqui = [];

        for (const [modoId, modo_nombre] of Object.entries(modoadquisitivo)) {
            const modo = new modosAdquisitivos(parseInt(modoId), modo_nombre);
            modoAdqui.push(modo);

        }
        return modoAdqui;
    }


    async getTipoActivo() {
        const tipo = [];

        for (const [tipoId, tipo_nombre] of Object.entries(TipoActivosJson)) {
            const tipos = new TipoActivo(parseInt(tipoId), tipo_nombre);
            tipo.push(tipos);

        }
        return tipo;
    }

    async getTipoInstitucion() {
        const tipo = [];

        for (const [tipoId, tipo_nombre] of Object.entries(TipoInstitucionJson)) {
            const tipos = new TipoInstitucion(parseInt(tipoId), tipo_nombre);
            tipo.push(tipos);

        }
        return tipo;
    }

    async getTipoEdificio() {
        const tipo = [];

        for (const [tipoId, tipo_nombre] of Object.entries(TipoEdificiosJson)) {
            const tipos = new TipoEdificio(parseInt(tipoId), tipo_nombre);
            tipo.push(tipos);

        }
        return tipo;
    }


    /**
     * Optiene los cargos de archivo Json
     * @returns retorna un arreglo con los cargos en formato JSON
     */
    async getCargos() {
        const cargos = [];

        for (const [cargoId, cargo_nombre] of Object.entries(CargosJson)) {
            const cargo = new Cargos(parseInt(cargoId), cargo_nombre);
            cargos.push(cargo);

        }
        return cargos;
    }



        /**
     * Optiene los cargos de archivo Json
     * @returns retorna un arreglo con los cargos en formato JSON
     */
        async getGrados() {
            const grados = [];
    
            for (const [gradoId, grado_nombre] of Object.entries(GradosJson)) {
                const grado = new Grados(parseInt(gradoId), grado_nombre);
                grados.push(grado);
    
            }
            grados.sort((a, b) => a.id - b.id);
            return grados;
        }


/**
     * Optiene los Nombramientos de archivo Json
     * @returns retorna un arreglo con los Tipos de nombramientos en formato JSON
     */
    async getNombramientos() {
        const nombramientos = [];

        for (const [nombramientoId, nombramiento_nombre] of Object.entries(NombramientoJson)) {
            const nombramiento = new Nombramiento(parseInt(nombramientoId), nombramiento_nombre);
            nombramientos.push(nombramiento);

        }
        return nombramientos;
    }

    /**
     * Optiene los Roles de archivo Json
     * @returns retorna un arreglo con los roles en formato JSON
     */
    async getRoles() {
        const roles = [];

        for (const [roleId, role_nombre] of Object.entries(RolesJson)) {
            const role = new Roles(parseInt(roleId), role_nombre);
            roles.push(role);

        }
        return roles;
    }

    /**
     * Optiene los Tipos de Direccion de archivo Json
     * @returns retorna un arreglo con los Tipos de Direccion en formato JSON
     */
    async getTipoDereccion() {
        const tipoDereccion = [];
        
        for (const [tipoDereccionId, tipoDereccion_nombre] of Object.entries(TipoDereccionJson)) {
           
            const tipDereccion = new TipoDereccion(parseInt(tipoDereccionId), tipoDereccion_nombre);
           
            tipoDereccion.push(tipDereccion);
            

        }
       
        return tipoDereccion;
    }

      /**
     * Optiene los Tipos de sexo de archivo Json
     * @returns retorna un arreglo con los Tipos de sexo en formato JSON
     */
    async getSexo() {
        const sexo = [];
        
        for (const [sexoId, sexo_nombre] of Object.entries(SexoJson)) {
            const sex = new Sexo(parseInt(sexoId), sexo_nombre);
           
            sexo.push(sex);
        }
        return sexo;
    }


}
module.exports = SelectsService;