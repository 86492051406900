import React, { useState, useEffect } from 'react';
import {
    Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal,
    ModalBody,
    Form, FormGroup, Label, Input, InputGroup, InputGroupText, Progress,
    Breadcrumb,
    BreadcrumbItem,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody
} from 'reactstrap';
import { TbFuncionario } from '../../../CapaEntidades/TbFuncionario';
import { TbUsuarios } from '../../../CapaEntidades/TbUsuarios';
import { EstadosIngresarInstitucion } from '../../../CapaUtilidades/Estados/EstadosIngresarInstitucion';
import { GetBarrios, GetBarrios2, GetCantones, GetCantones2, GetDistritos, GetDistritos2, GetPaises, GetProvincias } from '../../../CapaDatos/DatosCargarUbicaciones';
import { GetRegionales } from '../../../CapaDatos/DatosCargarRegionales';
import { GetCargos, GetNombramientos, GetRoles, GetSexo, GetTipoDereccion } from '../../../CapaDatos/DatosCargarSelects';
import { getActualizarFuncionario, getFuncionariosActivos } from '../../../CapaDatos/DatosFuncionarios';
import { UpdateUser, getDatosUsuario } from '../../../CapaDatos/DatosUsuarios';
import { ContraseñaSegura, ErrorMessage, EvaluarSeguridadContraseña, NivelSeguridad, ObtenerImagen, removeBase64Prefix } from '../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import { ValidarSoloLetras, ValidarSoloNumeros, ValidarCorreo } from '../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import Foto from '../../assets/faces/user (3).png'
import * as  Tb from "react-icons/tb";
import { IoMdReturnLeft } from "react-icons/io";
import { FiEye, FiEyeOff } from 'react-icons/fi';
import MenuItem from '../../components/MenuItem';
import Cargando from '../../components/Cargando';


const Perfil = ({ codigoPresupuestario }) => {
    let CodigoPresupuestario = codigoPresupuestario;
    const [openAC, setOpenAC] = useState('');
    useEffect(() => {
        // Esto se ejecutará cada vez que cambie la ubicación (incluido al recargar la página)
        // Aquí puedes colocar cualquier lógica que necesites cuando se monta o actualiza el componente
    }, [window.location]);



    const [loading, setLoading] = useState(true);
    const [DatosPerfil, setDatosPerfil] = useState([]);
    const [ListaPaises, setListaPaises] = useState([]);
    const [ListaProvincias, setListaProvincias] = useState([]);
    const [ListaCantones, setListaCantones] = useState([]);
    const [ListaDistritos, setListaDistritos] = useState([]);
    const [ListaBarrios, setListaBarrios] = useState([]);
    const [ListaCargos, setListaCargos] = useState([]);
    const [ListaNombramientos, setListaNombramientos] = useState([]);
    const [ListaRoles, setListaRoles] = useState([]);
    const [ListaTipoDereccion, setListaTipoDereccion] = useState([]);
    const [ListaSexo, setListaSexo] = useState([]);
    const [ListaRegionales, setListaRegionales] = useState([]);

    const {
        cedula, setCedula,
        nombre, setNombre,
        apellido1, setapellido1,
        apellido2, setapellido2,
        cargo, setcargo,
        tipoNombramiento, setTipoNombramiento,
        telefonoFunPrincipal, setTelefonoFunPrincipal,
        telefonoFunSecundario, setTelefonoFunSecundario,
        correo, setCorreo,
        DireccionFuncionario, setDireccionFuncionario,
        fotoPerfil, setfotoPerfil,
        pais, setPais,
        selectedProvinciaFuncionario, setSelectedProvinciaFuncionario,
        selectedCantonFuncionario, setSelectedCantonFuncionario,
        selectedDistritoFuncionario, setSelectedDistritoFuncionario,
        selectedBarrioFuncionario, setSelectedBarrioFuncionario,
        sexo, setSexo
    } = TbFuncionario();

    const {
        idUsuario, setidUsurio,
        usuario, setUsuario,
        password, setPassword,
        ultIngreso, setultIngreso,
        institucionUser, setInstitucionUser,
        permisoUsuario, setPermisoUsuario,
        estadoUser, setEstadoUser,
    } = TbUsuarios();

    const {
        codigoValido, setCodigoValido,
        telefonoValido, setTelefonoValido,
        directorValido, setdirectorValido,
        nombreValido, setNombreValido,
        apellido1Valido, setapellido1Valido,
        apellido2Valido, setapellido2Valido,
        telefonoFunPrincipalValido, setTelefonoFunPrincipalvalido,
        telefonoFunSecundarioValido, setTelefonoFunSecundariovalido,
        correoValido, setcorreoValido,
        cedulaValida, setcedulaValidad,
        mostrarContrasena, setMostrarContrasena,
        selectedImage, setSelectedImage,
        selectedLogo, setSelectedLogo,
    } = EstadosIngresarInstitucion();

    const CargarDatos = () => {
        GetPaises(setListaPaises)
        GetProvincias(setListaProvincias)
        GetRegionales(setListaRegionales)
        GetCargos(setListaCargos)
        GetNombramientos(setListaNombramientos)
        GetRoles(setListaRoles)
        GetTipoDereccion(setListaTipoDereccion)
        GetSexo(setListaSexo)
        setEstadoUser(1)

    }

    const CargarDatosUser = () => {
        // const FuncionarioSeleccionado = DatosPerfil[0].find(funcionario => funcionario.cedulaFuncionario === data);

        if (DatosPerfil[0]) {

            setCedula(DatosPerfil[0].cedulaFuncionario);
            setNombre(DatosPerfil[0].nombreCompleto);
            setapellido1(DatosPerfil[0].apellido1);
            setapellido2(DatosPerfil[0].apellido2);
            setcargo(DatosPerfil[0].cargo);
            setTipoNombramiento(DatosPerfil[0].tipoNombramiento);
            setTelefonoFunPrincipal(DatosPerfil[0].telefonoPrincipal);
            setTelefonoFunSecundario(DatosPerfil[0].telefono1);
            setCorreo(DatosPerfil[0].correo);
            setDireccionFuncionario(DatosPerfil[0].direccion);
            if (DatosPerfil[0].foto !== null) {
                setfotoPerfil(`data:image/jpeg;base64,${removeBase64Prefix(DatosPerfil[0].foto)}`);
                setSelectedImage(`data:image/jpeg;base64,${removeBase64Prefix(DatosPerfil[0].foto)}`);
            }
            setPais(DatosPerfil[0].nacionalidad);
            setSelectedProvinciaFuncionario(DatosPerfil[0].Provincia);

            setSelectedCantonFuncionario(DatosPerfil[0].Canton);
            setSelectedDistritoFuncionario(DatosPerfil[0].Distrito);
            setSelectedBarrioFuncionario(DatosPerfil[0].Barrio);
            setSexo(DatosPerfil[0].sexo);
            setUsuario(DatosPerfil[0].usuario);
            setPassword(DatosPerfil[0].passwd);
            setInstitucionUser(DatosPerfil[0].idInstitucion);
            setPermisoUsuario(DatosPerfil[0].permisosUsuario);
            setEstadoUser(DatosPerfil[0].estado);
        }



    }
    const fetchData = async () => {
        try {
            const data = await getDatosUsuario();
            const parametro = 1;

            const data2 = await getFuncionariosActivos(parametro);

            const FuncionarioSeleccionado = data2.find(funcionario => funcionario.cedulaFuncionario === data[0].cedulaFuncionario);


            //console.log(JSON.stringify(data[0]));
            setDatosPerfil(data[0]);
            if (data[0]) {
                //console.log(JSON.stringify(data[0]));
                setCedula(data[0].cedulaFuncionario);
                setNombre(data[0].nombreCompleto);
                setapellido1(data[0].apellido1);
                setapellido2(data[0].apellido2);
                setTelefonoFunPrincipal(data[0].telefonoPrincipal);
                setTelefonoFunSecundario(data[0].telefono1);
                setCorreo(data[0].correo);
                setDireccionFuncionario(data[0].direccion);
                if (data[0].foto !== null) {
                    setfotoPerfil(`data:image/jpeg;base64,${removeBase64Prefix(data[0].foto)}`);
                    setSelectedImage(`data:image/jpeg;base64,${removeBase64Prefix(data[0].foto)}`);
                }
                setPais(data[0].nacionalidad);


                setSelectedProvinciaFuncionario(data[0].Provincia);

                setSelectedCantonFuncionario(data[0].Canton);
                setSelectedDistritoFuncionario(data[0].Distrito);
                setSelectedBarrioFuncionario(data[0].Barrio);
                setSexo(data[0].sexo);
                //document.getElementById('Sexo').value = parseInt(sexo);
                setUsuario(data[0].usuario);
                setPassword(data[0].passwd);
                //console.log(usuario + " " + password)
                setInstitucionUser(CodigoPresupuestario);
                setPermisoUsuario(FuncionarioSeleccionado.permisosUsuario);
                setEstadoUser(FuncionarioSeleccionado.estado);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error al obtener los datos del usuario:", error);
        }
    };


    useEffect(() => {

        CargarDatos();
        fetchData();

    }, []);

    const fetchDataReload = async () => {
        try {
            const data = await getDatosUsuario();
            setDatosPerfil(data);

        } catch (error) {
            console.error("Error al obtener los datos del usuario:", error);
        }
    };

    if (DatosPerfil.estado === 0) {
        //fetchData();

    }

    if (!DatosPerfil?.length) {


        <Cargando />


    }



    const nivelSeguridad = EvaluarSeguridadContraseña(password);
    const MostrarContrasena = () => {
        setMostrarContrasena(!mostrarContrasena); // Cambia el estado para mostrar o no la contraseña
    };

    const Contraseña_TextChanged = (event) => {
        setPassword(event.target.value);
    };

    const ActualizarFuncionario = async (event) => {
        event.preventDefault();
        if (nivelSeguridad === NivelSeguridad.MuySegura || nivelSeguridad === NivelSeguridad.MediaSegura) {
            const data =
            {
                cedulaFuncionario: cedula,
                nombreCompleto: nombre,
                apellido1: apellido1,
                apellido2: apellido2,
                telefonoPrincipal: telefonoFunPrincipal,
                telefono1: telefonoFunSecundario,
                correo: correo,
                direccion: DireccionFuncionario,
                foto: fotoPerfil,
                nacionalidad: parseInt(pais),
                Provincia: selectedProvinciaFuncionario.toString(),
                Canton: selectedCantonFuncionario.toString(),
                Distrito: selectedDistritoFuncionario.toString(),
                Barrio: selectedBarrioFuncionario.toString(),
                sexo: sexo


            }

            const success = await getActualizarFuncionario(data);
            if (success.estado) {


                const dataUser =
                {
                    usuario: usuario,
                    passwd: password,
                    idInstitucion: institucionUser,
                    permisosUsuario: parseInt(permisoUsuario),


                }




                const successUser = await UpdateUser(dataUser)
                if (successUser.estado) {


                    fetchData();

                } else {
                    ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante durante el proceso de guardado.');
                    return;
                }






            } else {
                ErrorMessage('Notificación del Sistema', 'Ocurrió un error durante la solicitud al servidor.');
            }
        } else {
            ErrorMessage('Notificación del Sistema', 'Nivel de seguridad de la contraseña no apto.');
            return;
        }



    };

    if (loading) {
        return (
            <Cargando />
        );
    }

    const toggleAC = (id) => {
        setOpenAC(openAC === id ? null : id); // Abre o cierra el acordeón según el id
    };


    return (
        <Container fluid className="pt-2 Divcontenedor ">
            <Col className='p-0'>
                <Breadcrumb listTag="div" className='m-0 breadcrumb'>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Inicio "
                        to="/Menu"
                        classname={'NoDecoration2 tlink'}
                    />
                    <Label className=' px-1 py-1'>/</Label>
                    <MenuItem
                        className='p-0 text-dark'

                        text="Perfil"
                        to="/Menu/Perfil"
                        classname={'NoDecoration2 tlink'}
                    />

                </Breadcrumb>

            </Col>
            <Container fluid className='  p-4 shadow2 border rounded  ' id='institucion'  >
                <Form onSubmit={ActualizarFuncionario}>
                    <h5 className='py-1' > Información de Perfil </h5>

                    <Accordion className='mb-5  ' flush open={openAC} toggle={toggleAC}>

                        <AccordionItem className='mb-3 '>
                            <AccordionHeader targetId="1" className=' accordionBorder accordion-button2'>Información Personal </AccordionHeader>
                            <AccordionBody accordionId="1" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="Provincia">
                                                Nacionalidad
                                            </Label>
                                            <Input
                                                required
                                                id="NacionalidadFuncionario"
                                                name="NacionalidadFuncionario"
                                                placeholder="NacionalidadFuncionario"
                                                type="select"
                                                defaultValue={pais}
                                                onChange={(e) => setPais(e.target.value)}
                                            >
                                                <option value="0" disabled selected  >Seleccione la Nacionalidad</option>
                                                {ListaPaises.map((paises, index) => (
                                                    <option key={paises.id} value={paises.id}  >{paises.nombre}</option>
                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={9}></Col>

                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="identificacion" className="me-2">
                                                Identificación
                                            </Label>

                                            <Input
                                                disabled
                                                required
                                                id="identificacion"
                                                name="identificacion"
                                                placeholder="0-0000-0000"
                                                type="text"
                                                value={cedula}
                                                onChange={(event) => {
                                                    ValidarSoloNumeros(event, setCedula, setcedulaValidad);
                                                    setUsuario(event.target.value);
                                                }}
                                                maxLength={12}
                                                minLength={9}
                                                className={cedulaValida ? ' ' : 'is-invalid  '}
                                            />

                                            {!cedulaValida && <div className="invalid-feedback">Formato de Cedula no valido</div>}


                                        </FormGroup>
                                    </Col>

                                    <Col md={3} >
                                        <FormGroup >
                                            <Label for="nombre" className="me-2">
                                                Nombre
                                            </Label>
                                            <Input

                                                required
                                                id="nombre"
                                                name="nombre"
                                                placeholder=""
                                                type="text"
                                                value={nombre}
                                                onChange={(event) => ValidarSoloLetras(event, setNombre, setNombreValido)}
                                                className={nombreValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!nombreValido && <div className="invalid-feedback">No se admiten caracteres numericos.</div>}
                                        </FormGroup>

                                    </Col>

                                    <Col md={3} >
                                        <FormGroup >
                                            <Label for="primerApellido" className="me-2">
                                                Primer Apellido
                                            </Label>
                                            <Input

                                                required
                                                id="primerApellido"
                                                name="primerApellido"
                                                placeholder=""
                                                type="text"
                                                value={apellido1}
                                                onChange={(event) => ValidarSoloLetras(event, setapellido1, setapellido1Valido)}
                                                className={apellido1Valido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!apellido1Valido && <div className="invalid-feedback">No se admiten caracteres numericos.</div>}
                                        </FormGroup>

                                    </Col>

                                    <Col md={3} >
                                        <FormGroup >
                                            <Label for="segundoApellido" className="me-2">
                                                Segundo Apellido
                                            </Label>
                                            <Input

                                                required
                                                id="segundoApellido"
                                                name="segundoApellido"
                                                placeholder=""
                                                type="text"
                                                value={apellido2}
                                                onChange={(event) => ValidarSoloLetras(event, setapellido2, setapellido2Valido)}
                                                className={apellido2Valido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!apellido2Valido && <div className="invalid-feedback">No se admiten caracteres numericos.</div>}
                                        </FormGroup>

                                    </Col>

                                    <Col md={4} className='py-1'>
                                        <FormGroup>
                                            <Label for="Sexo">
                                                Sexo
                                            </Label>
                                            <Input
                                                required
                                                id="Sexo"
                                                name="Sexo"
                                                placeholder="NacionalidadFuncionario"
                                                type="select"
                                                defaultValue={sexo}
                                                onChange={(e) => setSexo(e.target.value)}
                                            >
                                                <option value="0" disabled selected  >Seleccione el tipo Sexo</option>
                                                {ListaSexo.map(sexo => (
                                                    <option key={sexo.id} value={sexo.id}>{sexo.nombre}</option>
                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={3} className='py-1'>

                                        <FormGroup>
                                            <Label for="files" className='pr-2'>
                                                Foto de Perfil
                                            </Label>
                                            <Input
                                                hidden
                                                id="files"
                                                name="files"
                                                type="file"
                                                accept='image/*'
                                                onChange={(e) => ObtenerImagen(e, setSelectedImage, setfotoPerfil)}
                                            />
                                            <Label for="files" className="custom-file-upload">Seleccionar Archivo</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} >

                                        {selectedImage ? (
                                            <img src={selectedImage} className='FotoPerfil p-2' alt="Foto de Perfil" />
                                        ) : (
                                            <img src={Foto} className='FotoPerfil p-2' alt="Foto de Perfil" />
                                        )}


                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className='mb-3 '>
                            <AccordionHeader targetId="2" className=' accordionBorder accordion-button2'>Lugar de Residencia </AccordionHeader>
                            <AccordionBody accordionId="2" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="Provincia">
                                                Provincia
                                            </Label>
                                            <Input
                                                required
                                                id="ProvinciaFuncionario"
                                                name="ProvinciaFuncionario"
                                                placeholder="ProvinciaFuncionario"
                                                type="select"
                                                defaultValue={selectedProvinciaFuncionario}
                                                onChange={(event) => GetCantones(event, setSelectedProvinciaFuncionario, setListaCantones)}
                                            >
                                                <option value="0" disabled selected  >Seleccione una Provincia</option>
                                                {ListaProvincias.map(provincia => (
                                                    <option key={provincia.id} value={provincia.id}>{provincia.nombre}</option>
                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="CantonFuncionario">
                                                Cantón
                                            </Label>
                                            <Input
                                                required
                                                id="CantoFuncionarion"
                                                name="CantonFuncionario"

                                                type="select"
                                                defaultValue={selectedCantonFuncionario}
                                                onChange={(event) => GetDistritos(event, selectedProvinciaFuncionario, setSelectedCantonFuncionario, setListaDistritos)}

                                            >
                                                <option value="0" disabled selected  >Seleccione el Cantón</option>
                                                {ListaCantones.map(canton => (
                                                    <option key={canton.id} value={canton.id}>{canton.nombre}</option>

                                                ))}

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="DistritoFuncionario">
                                                Distrito
                                            </Label>
                                            <Input
                                                required
                                                id="DistritoFuncionario"
                                                name="DistritoFuncionario"

                                                type="select"
                                                defaultValue={selectedDistritoFuncionario}
                                                onChange={(event) => GetBarrios(event, selectedProvinciaFuncionario, selectedCantonFuncionario, setSelectedDistritoFuncionario, setListaBarrios)}
                                            >
                                                <option value="0" disabled selected  >Seleccione el Distrito</option>
                                                {ListaDistritos.map(distrito => (
                                                    <option key={distrito.id} value={distrito.id}>{distrito.nombre}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="BarrioFuncionario">
                                                Barrio
                                            </Label>
                                            <Input
                                                required
                                                id="BarrioFuncionario"
                                                name="BarrioFuncionario"

                                                type="select"
                                                defaultValue={selectedBarrioFuncionario}


                                                onChange={(e) => setSelectedBarrioFuncionario(e.target.value)}
                                            >
                                                <option value="0" disabled selected>Seleccione el Barrio</option>
                                                {ListaBarrios.map(barrio => (
                                                    <option key={barrio.id} value={barrio.id}>{barrio.nombre}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={12} className='py-1'>
                                        <Label for="DireccionFuncionario">
                                            Dirección
                                        </Label>
                                        <Input
                                            required
                                            id="DireccionFuncionario"
                                            name="DireccionFuncionario"
                                            placeholder="200 mt al este de la calle 10"
                                            value={DireccionFuncionario}
                                            onChange={(e) => setDireccionFuncionario(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className='mb-3 '>
                            <AccordionHeader targetId="3" className=' accordionBorder accordion-button2'>Información de Contacto </AccordionHeader>
                            <AccordionBody accordionId="3" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="telefonfuncionarioo">
                                                Telefono Principal
                                            </Label>
                                            <Input
                                                maxLength={8}
                                                minLength={8}
                                                id="telefonofuncionario"
                                                name="telefonofuncionario"
                                                placeholder=""
                                                type="tel"
                                                value={telefonoFunPrincipal}
                                                onChange={(event) => ValidarSoloNumeros(event, setTelefonoFunPrincipal, setTelefonoFunPrincipalvalido)}
                                                className={telefonoFunPrincipalValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!telefonoFunPrincipalValido && <div className="invalid-feedback">Solo se adminten caracteres numericos.</div>} {/* Mostrar mensaje de error si el número de teléfono no es válido */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="telefonSecundariofuncionarioo">
                                                Telefono Secundario
                                            </Label>
                                            <Input
                                                maxLength={8}
                                                minLength={8}
                                                id="telefonoSecundariofuncionario"
                                                name="telefonoSecundariofuncionario"
                                                placeholder=""
                                                type="tel"
                                                value={telefonoFunSecundario}
                                                onChange={(event) => ValidarSoloNumeros(event, setTelefonoFunSecundario, setTelefonoFunSecundariovalido)}
                                                className={telefonoFunSecundarioValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!telefonoFunSecundarioValido && <div className="invalid-feedback">Solo se adminten caracteres numericos.</div>} {/* Mostrar mensaje de error si el número de teléfono no es válido */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} className='py-1'>
                                        <FormGroup>
                                            <Label for="email">
                                                Correo Electronico
                                            </Label>
                                            <Input
                                                required
                                                id="email"
                                                name="email"
                                                placeholder=""
                                                type="mail"
                                                value={correo}
                                                onChange={(event) => ValidarCorreo(event, setCorreo, setcorreoValido)}
                                                className={correoValido ? '' : 'is-invalid'} // Agregar clase is-invalid si el número de teléfono no es válido
                                            />
                                            {!correoValido && <div className="invalid-feedback">Formato de correo no valido.</div>}

                                        </FormGroup>
                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                        <AccordionItem className='mb-3 '>
                            <AccordionHeader targetId="5" className=' accordionBorder accordion-button2'>Información de Usuario </AccordionHeader>
                            <AccordionBody accordionId="5" className='shadow4'>
                                <Row>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <Label for="usuario">
                                                Usuario
                                            </Label>
                                            <Input
                                                readOnly
                                                required
                                                id="usuario"
                                                name="usuario"
                                                placeholder=""
                                                type="text"
                                                value={usuario}
                                                onChange={(e) => setUsuario(e.target.value)}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className='py-1'>
                                        <FormGroup>
                                            <InputGroup className='d-flex justify-content-between '>
                                                <Label for=" password">
                                                    Contraseña
                                                </Label>
                                                <InputGroupText addonType="append" className='p-0 mr-5  border-0 '>
                                                    <Button
                                                        onClick={(e) => ContraseñaSegura(setPassword)}
                                                        color=''
                                                        className='p-0 px-2 rounded btn-editar '
                                                        title='Generar contraseña segura'>
                                                        <Tb.TbPasswordFingerprint />
                                                    </Button>
                                                </InputGroupText>
                                            </InputGroup>

                                            <InputGroup>
                                                <Input
                                                    required
                                                    id="password"
                                                    name="password"
                                                    placeholder=""
                                                    type={mostrarContrasena ? "text" : "password"} // Cambia el tipo de input según el estado
                                                    value={password}
                                                    onChange={Contraseña_TextChanged}
                                                />
                                                <InputGroupText addonType="append" className='p-0'>
                                                    <Button onClick={MostrarContrasena} color="" className='p-0 px-2'>
                                                        {mostrarContrasena ? <FiEyeOff /> : <FiEye />} {/* Cambia el ícono según el estado */}
                                                    </Button>
                                                </InputGroupText>
                                            </InputGroup>
                                            {NivelSeguridad && (
                                                <div>
                                                    {nivelSeguridad}
                                                    <Progress

                                                        value={nivelSeguridad === NivelSeguridad.MuySegura ? 100 : nivelSeguridad === NivelSeguridad.MediaSegura ? 75 : nivelSeguridad === NivelSeguridad.PocoSegura ? 25 : 1}
                                                        color={nivelSeguridad === NivelSeguridad.MuySegura ? 'success' : nivelSeguridad === NivelSeguridad.MediaSegura ? 'warning' : nivelSeguridad === NivelSeguridad.PocoSegura ? 'danger' : 'danger'}
                                                    />
                                                </div>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </AccordionBody>
                        </AccordionItem>

                    </Accordion>

                    <Col className='d-flex justify-content-between py-2'>
                        <Button type="button" className='p-0 btn   btnCancelar px-3 d-flex justify-content-start' id='GuardarInstitucion'>
                            <ul>
                                <MenuItem
                                    className=' '
                                    icon={<IoMdReturnLeft className="me-1" />}
                                    text="Volver"

                                    to="/Menu/"
                                    classname={'NoDecoration'}
                                />
                            </ul>
                        </Button>
                        <Button type="submit" className=' p-1 px-3 btnAceptar ' id='GuardarInstitucion'>
                            Actualizar Funcionario
                        </Button>
                    </Col>

                </Form>

            </Container>
        </Container>
    )
};

export default Perfil;