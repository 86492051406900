import React, { useState, useEffect } from 'react';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import MenuItem from '../../../components/MenuItem';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, AccordionItem, Accordion, AccordionBody, AccordionHeader, Table, ModalHeader, Spinner } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as Fa6 from "react-icons/fa6";
import * as  Fc from "react-icons/fc";
import * as Bi from "react-icons/bi";
import * as Md from "react-icons/md";
import * as Tb from "react-icons/tb";
import * as Io from "react-icons/io";
import * as  Fa from "react-icons/fa";
import { TbActivos } from '../../../../CapaEntidades/TbActivos';
import Swal from 'sweetalert2';
import { obtenerIdInstitucion } from '../../../../CapaUtilidades/Utils/encryptUserData';
import { getTipoInstitucion } from '../../../../CapaDatos/DatosDesarrolladores';
import { GetGrados } from '../../../../CapaDatos/DatosCargarSelects';
import { getSecciones } from '../../../../CapaDatos/DatosSecciones';
import MenuItem2 from '../../../components/MenuItem2';


let globalTipoInstitu = null;
let globalSecciones = null;


const FrmExpedientes = ({ codigoPresupuestario, codigoNombre }) => {
  let CodigoPresupuestario = codigoPresupuestario;
  let CodigoNombre = codigoNombre;

  const [tipoInstitucion, setTipoInstitucion] = useState('');
  const [loading, setLoading] = useState(true);
  const [ListaGrados, setListaGrados] = useState([]);
  const [ListaSecciones, setListaSecciones] = useState([]);
  const [ListaEstudiantes, setListaEstudiantes] = useState([
    { cedula: '1234567890', nombreCompleto: 'Juan', apellido1: ' Pérez', apellido2: '', nivel: -1, nombreSeccion: 'Materno-1' },
    { cedula: '2233445566', nombreCompleto: 'Ana', apellido1: ' Martínez', apellido2: '', nivel: -1, nombreSeccion: 'Materno-1' },
    { cedula: '7788990011', nombreCompleto: 'Miguel', apellido1: ' Hernández', apellido2: '', nivel: -1, nombreSeccion: 'Materno-1' },
    { cedula: '8899001123', nombreCompleto: 'Clara', apellido1: ' Muñoz', apellido2: '', nivel: -1, nombreSeccion: 'Materno-1' },
    { cedula: '9900112234', nombreCompleto: 'Pablo', apellido1: ' Santos', apellido2: '', nivel: -1, nombreSeccion: 'Materno-1' },
    { cedula: '0011223345', nombreCompleto: 'Elena', apellido1: ' Ortega', apellido2: '', nivel: -1, nombreSeccion: 'Materno-1' },
    { cedula: '1122334456', nombreCompleto: 'Roberto', apellido1: ' Vargas', apellido2: '', nivel: -1, nombreSeccion: 'Materno-2' },
    { cedula: '2233445567', nombreCompleto: 'Julia', apellido1: ' Castro', apellido2: '', nivel: -1, nombreSeccion: 'Materno-2' },
    { cedula: '3344556678', nombreCompleto: 'Diego', apellido1: ' Medina', apellido2: '', nivel: -1, nombreSeccion: 'Materno-2' },
    { cedula: '4455667789', nombreCompleto: 'Isabel', apellido1: ' Reyes', apellido2: '', nivel: -1, nombreSeccion: 'Materno-2' },
    { cedula: '5566778890', nombreCompleto: 'Manuel', apellido1: ' Soto', apellido2: '', nivel: -1, nombreSeccion: 'Materno-3' },
    { cedula: '6677889901', nombreCompleto: 'Andrea', apellido1: ' Mendoza', apellido2: '', nivel: -1, nombreSeccion: 'Materno-3' },
    { cedula: '7788990012', nombreCompleto: 'Raúl', apellido1: ' Domínguez', apellido2: '', nivel: -1, nombreSeccion: 'Materno-3' },
    { cedula: '8899001123', nombreCompleto: 'Nuria', apellido1: ' Gil', apellido2: '', nivel: -1, nombreSeccion: 'Materno-3' },
    { cedula: '9900112234', nombreCompleto: 'Mario', apellido1: ' Navarro', apellido2: '', nivel: -1, nombreSeccion: 'Materno-3' },

    { cedula: '0987654321', nombreCompleto: 'María', apellido1: ' López', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '8899001122', nombreCompleto: 'Laura', apellido1: ' Ruiz', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '9900112233', nombreCompleto: 'José', apellido1: ' Paredes', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '0011223344', nombreCompleto: 'Teresa', apellido1: ' Rojas', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '1122334455', nombreCompleto: 'Ricardo', apellido1: ' Blanco', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '2233445566', nombreCompleto: 'Luis', apellido1: ' Ramírez', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '3344556677', nombreCompleto: 'Verónica', apellido1: ' Sánchez', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '4455667788', nombreCompleto: 'Francisco', apellido1: ' Silva', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '5566778899', nombreCompleto: 'Daniela', apellido1: ' Romero', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '6677889900', nombreCompleto: 'Antonio', apellido1: ' Gutiérrez', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '7788990011', nombreCompleto: 'Marcos', apellido1: ' Peña', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '8899001122', nombreCompleto: 'Eva', apellido1: ' Morales', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '9900112233', nombreCompleto: 'Alberto', apellido1: ' Cabrera', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '0011223344', nombreCompleto: 'Patricia', apellido1: ' Cruz', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },
    { cedula: '1122334455', nombreCompleto: 'Héctor', apellido1: ' Martínez', apellido2: '', nivel: 0, nombreSeccion: 'Transicion-1' },

    { cedula: '1122334455', nombreCompleto: 'Carlos ', apellido1: 'García', apellido2: '', nivel: 1, nombreSeccion: '1-1' },
    { cedula: '2233445566', nombreCompleto: 'María ', apellido1: 'Luque', apellido2: '', nivel: 1, nombreSeccion: '1-1' },
    { cedula: '3344556677', nombreCompleto: 'Fernando ', apellido1: 'Gómez', apellido2: '', nivel: 1, nombreSeccion: '1-1' },
    { cedula: '4455667788', nombreCompleto: 'Carmen ', apellido1: 'Navas', apellido2: '', nivel: 1, nombreSeccion: '1-1' },
    { cedula: '5566778899', nombreCompleto: 'Esteban ', apellido1: 'Vargas', apellido2: '', nivel: 1, nombreSeccion: '1-1' },
    { cedula: '6677889900', nombreCompleto: 'Claudia ', apellido1: 'Santos', apellido2: '', nivel: 1, nombreSeccion: '1-1' },
    { cedula: '7788990011', nombreCompleto: 'José Luis ', apellido1: 'Torres', apellido2: '', nivel: 1, nombreSeccion: '1-1' },
    { cedula: '8899001122', nombreCompleto: 'Lucía ', apellido1: 'Herrero', apellido2: '', nivel: 1, nombreSeccion: '1-1' },
    { cedula: '9900112233', nombreCompleto: 'Joaquín ', apellido1: 'Luna', apellido2: '', nivel: 1, nombreSeccion: '1-2' },
    { cedula: '0011223344', nombreCompleto: 'Sergio ', apellido1: 'Olivares', apellido2: '', nivel: 1, nombreSeccion: '1-2' },
    { cedula: '1122334455', nombreCompleto: 'Paula ', apellido1: 'Bravo', apellido2: '', nivel: 1, nombreSeccion: '1-2' },
    { cedula: '2233445566', nombreCompleto: 'Marta ', apellido1: 'Ruiz', apellido2: '', nivel: 1, nombreSeccion: '1-2' },
    { cedula: '3344556677', nombreCompleto: 'Rafael ', apellido1: 'Díaz', apellido2: '', nivel: 1, nombreSeccion: '1-2' },
    { cedula: '4455667788', nombreCompleto: 'Adriana ', apellido1: 'Campos', apellido2: '', nivel: 1, nombreSeccion: '1-2' },
    { cedula: '5566778899', nombreCompleto: 'Vicente ', apellido1: 'López', apellido2: '', nivel: 1, nombreSeccion: '1-2' },

    { cedula: '2233445566', nombreCompleto: 'Ana', apellido1: 'Martínez', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '3344556677', nombreCompleto: 'Pedro', apellido1: 'López', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '4455667788', nombreCompleto: 'Carmen', apellido1: 'Ortega', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '5566778899', nombreCompleto: 'Javier', apellido1: 'Fernández', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '6677889900', nombreCompleto: 'Sofía', apellido1: 'Gil', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '7788990011', nombreCompleto: 'Mario', apellido1: 'Gutiérrez', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '8899001122', nombreCompleto: 'Sara', apellido1: 'Cabrera', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '9900112233', nombreCompleto: 'David', apellido1: 'Ramos', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '0011223344', nombreCompleto: 'Laura', apellido1: 'Pérez', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '1122334455', nombreCompleto: 'José Carlos', apellido1: 'Rivas', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '2233445566', nombreCompleto: 'Miguel', apellido1: 'Figueroa', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '3344556677', nombreCompleto: 'Gabriela', apellido1: 'Montoya', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '4455667788', nombreCompleto: 'Luis Alberto', apellido1: 'Serrano', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '5566778899', nombreCompleto: 'Patricia', apellido1: 'Hernández', apellido2: '', nivel: 2, nombreSeccion: '2-1' },
    { cedula: '6677889900', nombreCompleto: 'Juan Antonio', apellido1: 'Escobar', apellido2: '', nivel: 2, nombreSeccion: '2-1' },

    { cedula: '3344556677', nombreCompleto: 'Pedro', apellido1: 'Rodríguez', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '4455667788', nombreCompleto: 'Marta', apellido1: 'Gómez', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '5566778899', nombreCompleto: 'Antonio', apellido1: 'García', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '6677889900', nombreCompleto: 'Sandra', apellido1: 'López', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '7788990011', nombreCompleto: 'Francisco', apellido1: 'Díaz', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '8899001122', nombreCompleto: 'Elena', apellido1: 'Martínez', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '9900112233', nombreCompleto: 'Roberto', apellido1: 'Muñoz', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '0011223344', nombreCompleto: 'Raquel', apellido1: 'Núñez', apellido2: '', nivel: 3, nombreSeccion: '3-311' },
    { cedula: '1122334455', nombreCompleto: 'Alfonso', apellido1: 'Romero', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '2233445566', nombreCompleto: 'Eva', apellido1: 'Navarro', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '3344556677', nombreCompleto: 'José', apellido1: 'Morales', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '4455667788', nombreCompleto: 'Ana', apellido1: 'Vargas', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '5566778899', nombreCompleto: 'Sergio', apellido1: 'Marín', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '6677889900', nombreCompleto: 'Luisa', apellido1: 'Martín', apellido2: '', nivel: 3, nombreSeccion: '3-1' },
    { cedula: '7788990011', nombreCompleto: 'Juan Francisco', apellido1: 'Pardo', apellido2: '', nivel: 3, nombreSeccion: '3-1' },

    { cedula: '4455667788', nombreCompleto: 'Lucía', apellido1: ' Fernández', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '5566778899', nombreCompleto: 'Miguel Ángel', apellido1: ' Rivas', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '6677889900', nombreCompleto: 'Inés', apellido1: ' Cortés', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '7788990011', nombreCompleto: 'Gonzalo', apellido1: ' Durán', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '8899001122', nombreCompleto: 'Cecilia', apellido1: ' Ibáñez', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '9900112233', nombreCompleto: 'Carlos', apellido1: ' Sanz', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '0011223344', nombreCompleto: 'Natalia', apellido1: ' Ortega', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '1122334455', nombreCompleto: 'David', apellido1: ' Salazar', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '2233445566', nombreCompleto: 'Victoria', apellido1: ' Núñez', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '3344556677', nombreCompleto: 'Rosa', apellido1: ' Pineda', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '4455667788', nombreCompleto: 'Ismael', apellido1: ' Castillo', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '5566778899', nombreCompleto: 'Paula', apellido1: ' Domínguez', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '6677889900', nombreCompleto: 'Eduardo', apellido1: ' León', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '7788990011', nombreCompleto: 'Eva', apellido1: ' Benito', apellido2: '', nivel: 4, nombreSeccion: '4-2' },
    { cedula: '8899001122', nombreCompleto: 'Tomás', apellido1: ' Ortega', apellido2: '', nivel: 4, nombreSeccion: '4-2' },

    { cedula: '5566778899', nombreCompleto: 'Jorge', apellido1: ' González', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '6677889900', nombreCompleto: 'Isabel', apellido1: ' Cano', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '7788990011', nombreCompleto: 'Cristina', apellido1: ' Montes', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '8899001122', nombreCompleto: 'Javier', apellido1: ' Ibáñez', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '9900112233', nombreCompleto: 'Patricia', apellido1: ' Sanz', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '0011223344', nombreCompleto: 'José Manuel', apellido1: ' Ruiz', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '1122334455', nombreCompleto: 'Sara', apellido1: ' Mendoza', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '2233445566', nombreCompleto: 'David', apellido1: ' Robles', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '3344556677', nombreCompleto: 'Adriana', apellido1: ' Solís', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '4455667788', nombreCompleto: 'Luis Enrique', apellido1: ' Torres', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '5566778899', nombreCompleto: 'Teresa', apellido1: ' Ramos', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '6677889900', nombreCompleto: 'Enrique', apellido1: ' Lara', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '7788990011', nombreCompleto: 'Gloria', apellido1: ' Olmedo', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '8899001122', nombreCompleto: 'Óscar', apellido1: ' Pacheco', apellido2: '', nivel: 5, nombreSeccion: '5-1' },
    { cedula: '9900112233', nombreCompleto: 'Marina', apellido1: ' Hernández', apellido2: '', nivel: 5, nombreSeccion: '5-1' },

    { cedula: '6677889900', nombreCompleto: 'Sofía', apellido1: ' Torres', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '7788990011', nombreCompleto: 'Álvaro', apellido1: ' Maldonado', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '8899001122', nombreCompleto: 'Raquel', apellido1: ' Vega', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '9900112233', nombreCompleto: 'Antonio', apellido1: ' Jurado', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '0011223344', nombreCompleto: 'María Elena', apellido1: ' González', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '1122334455', nombreCompleto: 'Cristina', apellido1: ' León', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '2233445566', nombreCompleto: 'Héctor', apellido1: ' Silva', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '3344556677', nombreCompleto: 'Susana', apellido1: ' Ruiz', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '4455667788', nombreCompleto: 'Javier', apellido1: ' Quintana', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '5566778899', nombreCompleto: 'Natalia', apellido1: ' Espinosa', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '6677889900', nombreCompleto: 'Miguel', apellido1: ' Ángel Pérez', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '7788990011', nombreCompleto: 'Marta', apellido1: ' Jiménez', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '8899001122', nombreCompleto: 'Tomás', apellido1: ' Fuentes', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '9900112233', nombreCompleto: 'Inés', apellido1: ' Vallejo', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
    { cedula: '0011223344', nombreCompleto: 'Rosa', apellido1: ' María Núñez', apellido2: '', nivel: 6, nombreSeccion: '6-1' },
  ]);
  const [orden, setOrden] = useState({
    columna: null,
    ascendente: true
  });

  const [filtros, setFiltros] = useState({
    cedula: '',
    nombreCompleto: '',
    apellido1: '',
    apellido2: ''

  });


  const fetchData = async () => {
    try {
      const parametro = { codigoPresupuestario: obtenerIdInstitucion() };
      const tipoIns = await getTipoInstitucion(parametro);
      const listaSecciones = await getSecciones(1)

      globalTipoInstitu = tipoIns;
      globalSecciones = listaSecciones;



      setTipoInstitucion(tipoIns);
      setListaSecciones(listaSecciones);

      setLoading(false);

    } catch (error) {
      setLoading(false);
    }
  }


  useEffect(() => {


    fetchData();
    GetGrados(setListaGrados);


  }, []);

  if (loading) {
    return (
      <Cargando />
    );
  }

  const GradosFiltrados = ListaGrados.filter(grado => {

    if (tipoInstitucion === 1) {
      return grado.id >= -1 && grado.id <= 6;
    } else if (tipoInstitucion === 2) {
      return grado.id >= 7 && grado.id <= 12;
    }
    return false;
  })

  const OrdenarColumnas = (columna) => {
    setOrden(prevOrden => ({
      columna: columna,
      ascendente: prevOrden.columna === columna ? !prevOrden.ascendente : true
    }));
  };
  const obtenerIconoOrden = (columna) => {
    if (orden.columna === columna) {
      return orden.ascendente ? <Io.IoMdArrowDropup /> : <Io.IoMdArrowDropdown />
    }
    return null; // Retorna null si la columna no está siendo ordenada
  };

  const ordenarDatos = (datos) => {

    if (orden.columna) {
      return datos.slice().sort((a, b) => {
        const columnaA = a[orden.columna];
        const columnaB = b[orden.columna];
        if (typeof columnaA === 'string' && typeof columnaB === 'string') {
          return orden.ascendente ? columnaA.localeCompare(columnaB) : columnaB.localeCompare(columnaA);
        } else {
          return orden.ascendente ? columnaA - columnaB : columnaB - columnaA;
        }
      });
    } else {
      return datos;
    }
  };
  const Filtrar = (e, columna) => {
    const valor = e.target.value;
    setFiltros(prevFiltros => ({
      ...prevFiltros,
      [columna]: valor
    }));
  };
  const filtrarDatos = (datos) => {
    if (!Array.isArray(datos)) {
      console.error("Los datos proporcionados no son un arreglo.");
      return [];
    }

    return datos.filter(activo => {
      return Object.entries(filtros).every(([columna, valorFiltro]) => {
        if (valorFiltro === '') return true;
        return activo[columna].toLowerCase().includes(valorFiltro.toLowerCase());
      });
    });
  };




  return (
    <Container fluid className=" Divcontenedor ">

      <Col>
        <Breadcrumb listTag="div">
          <MenuItem
            className='p-0 text-dark'

            text="Inicio"
            to="/Menu"
            classname={'NoDecoration2 tlink'}
          />
          <Label className=' px-1 py-1'>/</Label>
          <MenuItem
            className='p-0 text-dark'

            text="Expedientes"
            to="/Menu/FrmExpedientes"
            classname={'NoDecoration2 tlink'}
          />

        </Breadcrumb>

      </Col>

      <Container fluid className='p-0 Mymt-1'>
        <Col className='mb-1 pb-1 border-bottom'>
          <Button className='p-0  btn btnAceptar    '>
            <ul>
              <MenuItem
                className=' p-0'
                icon={<Fa6.FaCirclePlus className="me-1 " size={18} />}
                text="Registrar Estudiante "
                to="/Menu/FrmNuevoExpediente" // aca agregar la ruta
                classname={'NoDecoration'}
              />
            </ul>
          </Button>
        </Col>
      </Container>

      <Container fluid className='  px-0  ' >
        <Tabs className={'p-0 m-0  border  '}>
          <TabList className={'tabs_ m-0'}  >
            {GradosFiltrados.map((grados, index) => (
              <Tab>{grados.nombre}</Tab>
            ))}

          </TabList>
          <Container fluid className=' shadow2 px-0 contenedorTabs rounded-0' >
            {GradosFiltrados.map((grado, index) => (
              <TabPanel key={index}>
                <Container fluid className='px-0'>
                  <Tabs className={'p-0 m-0  border  '}>
                    <TabList className={'tabs_ m-0 pt-1'} >
                      {ListaSecciones.filter(sec => sec.nivel === grado.id).map(seccion => (
                        <Tab>{seccion.nombreSeccion}</Tab>
                      ))}
                    </TabList>
                    {ListaSecciones.filter(sec => sec.nivel === grado.id).map((seccion, secIndex) => (
                      <TabPanel key={secIndex}>
                        <Container fluid className='p-0'>
                          <Table responsive >
                            <thead className='border-bottom'>
                              <tr>
                                <th className='pb-0'>
                                  <a className='cursor' onClick={() => OrdenarColumnas('cedula')}>  Cedula {obtenerIconoOrden('cedula')}</a>

                                </th>
                                <th className='pb-0'>
                                  <a className='cursor' onClick={() => OrdenarColumnas('apellido1')}>  Primer Apellido {obtenerIconoOrden('apellido1')}</a>
                                </th>
                                <th className='pb-0'>
                                  <a className='cursor' onClick={() => OrdenarColumnas('apellido2')}>  Segundo Apellido {obtenerIconoOrden('apellido2')}</a>
                                </th>
                                <th className='pb-0'>
                                  <a className='cursor' onClick={() => OrdenarColumnas('nombreCompleto')}>  Nombre {obtenerIconoOrden('nombreCompleto')}</a>
                                </th>
                              </tr>
                              <tr className='bg-tr'>

                                <th className='p-0 px-2 pb-1   '>
                                  <Col md={6} sm={12} className="input-with-icon">
                                    <Input
                                      type="text"
                                      className="py-1"
                                      value={filtros.cedula}
                                      onChange={(e) => Filtrar(e, 'cedula')}
                                    />
                                    <Fa.FaFilter className="filter-icon" />
                                  </Col>

                                </th>
                                <th className='p-0 px-2 pb-1   '>
                                  <Col md={6} sm={12} className="input-with-icon">
                                    <Input
                                      type="text"
                                      className="py-1"
                                      value={filtros.apellido1}
                                      onChange={(e) => Filtrar(e, 'apellido1')}
                                    />
                                    <Fa.FaFilter className="filter-icon" />
                                  </Col>

                                </th>
                                <th className='p-0 px-2 pb-1   '>
                                  <Col md={6} sm={12} className="input-with-icon">
                                    <Input
                                      type="text"
                                      className="py-1"
                                      value={filtros.apellido2}
                                      onChange={(e) => Filtrar(e, 'apellido2')}
                                    />
                                    <Fa.FaFilter className="filter-icon" />
                                  </Col>

                                </th>
                                <th className='p-0 px-2 pb-1   '>
                                  <Col md={6} sm={12} className="input-with-icon">
                                    <Input
                                      type="text"
                                      className="py-1"
                                      value={filtros.nombreCompleto}
                                      onChange={(e) => Filtrar(e, 'nombreCompleto')}
                                    />
                                    <Fa.FaFilter className="filter-icon" />
                                  </Col>

                                </th>
                              </tr>
                            </thead>
                            <tbody className='tablebody' id='tablebody'>
                              {
                                ordenarDatos(filtrarDatos(ListaEstudiantes)).length > 0 ? (
                                  ordenarDatos(filtrarDatos(ListaEstudiantes)).filter(estudiante => estudiante.nivel === grado.id && estudiante.nombreSeccion === seccion.nombreSeccion).map(estudiante => {
                                    return (
                                      <tr className="table-p" key={estudiante.cedula}>
                                        <td>
                                          <MenuItem2
                                            text={estudiante.cedula}
                                            to="/Menu/FrmNuevoExpediente"
                                          />

                                        </td>
                                        <td>
                                          {estudiante.apellido1}
                                        </td>
                                        <td>
                                          {estudiante.apellido2}
                                        </td>
                                        <td>
                                          {estudiante.nombreCompleto}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="6">No hay estudiantes ingresados en el sistema.</td>
                                  </tr>
                                )}

                            </tbody>
                          </Table>
                        </Container>
                      </TabPanel>
                    ))}
                  </Tabs>
                </Container>

              </TabPanel>
            ))}

          </Container>

        </Tabs>

      </Container>
      <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
        <ul>
          <MenuItem
            className='btnAceptar'
            icon={<Io.IoMdReturnLeft className="me-1" />}
            text="Volver"
            to="/Menu"
            classname={'NoDecoration'}
          />
        </ul>
      </Button>

    </Container >
  )
}

export default FrmExpedientes
