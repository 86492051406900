import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Card, CardHeader, CardBody, CardTitle, Table, CardText, CardFooter, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Breadcrumb, ModalHeader } from 'reactstrap';
import { TbAsignaturas } from '../../../../CapaEntidades/TbAsignaturas';
import { RestoreAsignatura, deleteAsignatura, getAsignaturas, updateAsignatura } from '../../../../CapaDatos/DatosAsignaturas';
import { ObtenerNombreInst, obtenerIdInstitucion } from '../../../../CapaUtilidades/Utils/encryptUserData';
import Espaciador from '../../../components/Espaciador';
import * as Fa6 from "react-icons/fa6";
import * as Io from "react-icons/io";
import * as Bi from "react-icons/bi";
import * as Fas from 'react-icons/fa';
import * as Md from "react-icons/md";
import * as  Fa from "react-icons/fa";
import { IoMdReturnLeft } from "react-icons/io";
import MenuItem from '../../../components/MenuItem';
import Cargando from '../../../components/Cargando';
import SinDatos from '../../../components/SinDatos';
import { Descargar, ErrorMessage } from '../../../../CapaUtilidades/Utils/FuncionesDeReutilizacion';
import Swal from 'sweetalert2';
import { ValidarSoloLetras, ValidarSoloNumeros } from '../../../../CapaUtilidades/Utils/FuncionesDeValidacion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


let globalData = null;
let globalData2 = null;
const FrmAsignaturas = ({ codigoPresupuestario }) => {
  let CodigoPresupuestario = codigoPresupuestario;
  const [loading, setLoading] = useState(true);
  const [Asignaturas, setAsignaturas] = useState([]);
  const [AsignaturasInactivas, setAsignaturasInactivas] = useState([]);
  const [open, setOpen] = useState(false);
  const [NombreInst, setNombreInst] = useState('');
  const [orden, setOrden] = useState({
    columna: null,
    ascendente: true
  });

  const [filtros, setFiltros] = useState({
    codigoAsignatura: '',
    nombreAsignatura: '',
    calificacionMinima: '',
  });


  const OrdenarColumnas = (columna) => {
    setOrden(prevOrden => ({
      columna: columna,
      ascendente: prevOrden.columna === columna ? !prevOrden.ascendente : true
    }));
  };
  const obtenerIconoOrden = (columna) => {
    if (orden.columna === columna) {
      return orden.ascendente ? <Io.IoMdArrowDropup /> : <Io.IoMdArrowDropdown />
    }
    return null; // Retorna null si la columna no está siendo ordenada
  };
  const ordenarDatos = (datos) => {

    if (orden.columna) {
      return datos.slice().sort((a, b) => {
        const columnaA = a[orden.columna];
        const columnaB = b[orden.columna];
        if (typeof columnaA === 'string' && typeof columnaB === 'string') {
          return orden.ascendente ? columnaA.localeCompare(columnaB) : columnaB.localeCompare(columnaA);
        } else {
          return orden.ascendente ? columnaA - columnaB : columnaB - columnaA;
        }
      });
    } else {
      return datos;
    }
  };
  const Filtrar = (e, columna) => {
    const valor = e.target.value;
    setFiltros(prevFiltros => ({
      ...prevFiltros,
      [columna]: valor
    }));
  };
  const filtrarDatos = (datos) => {
    if (!Array.isArray(datos)) {
      console.error("Los datos proporcionados no son un arreglo.");
      return [];
    }

    return datos.filter(activo => {
      return Object.entries(filtros).every(([columna, valorFiltro]) => {
        if (valorFiltro === '') return true;
        return activo[columna].toLowerCase().includes(valorFiltro.toLowerCase());
      });
    });
  };

  const IrDescargar = (nombre, array) => {
    if (array && array.length > 0) {

      Descargar(array, nombre)
    } else {
      ErrorMessage('Notificación del Sistema', 'No hay edificios para descargar')
    }
  }




  const TBAsignaturas = TbAsignaturas();




  const fetchData = async () => {
    try {

      const Estado = 1;

      const data = await getAsignaturas(Estado);

      const Estado2 = 0;

      const data2 = await getAsignaturas(Estado2);

      globalData = data;
      globalData2 = data2;

      setAsignaturas(data);

      setAsignaturasInactivas(data2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {

  

      fetchData();
      
    
  }, []);

  const toggle = () => {
    setOpen(!open);

  }



  const Abrirmodal = (data) => {



    TBAsignaturas.setNombreAsignatura(data.nomAsig);
    TBAsignaturas.setCodigoAsignatura(data.codAsig);
    TBAsignaturas.setCalificacionMinima(data.califi);
    TBAsignaturas.setAsignaturaCentro(data.institucion);
    TBAsignaturas.setNombreInst(ObtenerNombreInst())
    setOpen(!open)
  }

  const ObtenerCodAsignatura = (e) => {
    ValidarSoloLetras(e, TBAsignaturas.setNombreAsignatura, TBAsignaturas.setAsignaturaValida)
    if (TBAsignaturas.NombreAsignatura !== "") {
      const Codigo = TBAsignaturas.NombreAsignatura.substring(-1, 3).toUpperCase();
      TBAsignaturas.setCodigoAsignatura("CA-" + Codigo + "-" + CodigoPresupuestario);
      TBAsignaturas.setAsignaturaCentro(CodigoPresupuestario);
    } else {
      TBAsignaturas.setCodigoAsignatura("")
      TBAsignaturas.setAsignaturaCentro(CodigoPresupuestario);
    }


  }

  const ActualizarAsignatura = async (event) => {
    event.preventDefault();

    const data = {
      codigoAsignatura: TBAsignaturas.CodigoAsignatura,
      nombreAsignatura: TBAsignaturas.NombreAsignatura,
      calificacionMinima: parseInt(TBAsignaturas.CalificacionMinima),
      asignaturaCentro: TBAsignaturas.AsignaturaCentro,
      estado: 1
    }
    //console.log(JSON.stringify(data));
    const success = await updateAsignatura(data);

    if (success) {
      fetchData();
      toggle();
    } else {
      ErrorMessage('Notificación del Sistema', 'Ocurrrio un error durante el proceso de guardado')
      return;
    }






  }



  const Eliminar = async (param, IdInstitucion) => {
    TBAsignaturas.setAsignaturaCentro(CodigoPresupuestario)
    const data = {

      codigoPresupuestario: IdInstitucion,
      codigoAsignatura: param,
      estado: "0"
    }

    Swal.fire({
      title: "Estas seguro que deseas eliminar esta asignatura?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, bórrar!",
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {

        const success = await deleteAsignatura(data);

        if (success) {
          Swal.fire({
            title: "¡Eliminado!",
            text: "La Asignatura a sido eliminada",
            icon: "success",
            timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
            timerProgressBar: true, // Barra de progreso de tiempo
            showConfirmButton: false
          });
          fetchData();
        } else {
          ErrorMessage('Notificación del Sistema', success.mensaje);
          return;

        }

      }
    });
  };

  if (loading) {
    return (
      <Cargando />
    );


  }

  if (Asignaturas.estado === 0) {
    fetchData();
    // if (Asignaturas.estado === 0) {

    //   return (
    //     <SinDatos
    //       mensaje={"Error Interno del Servidor"}
    //       error={"Lo sentimos, a ocurrido un error con el servidor comunicate con el departamento de TI"}
    //       route="/Menu" />
    //   );
    // }
  }

  // if (!Asignaturas?.length) {

  //   return (
  //     <SinDatos
  //       mensaje={"Notificacion del Sistema"}
  //       error={"Lo sentimos, no existen instituciones registradas"}
  //       route="/Menu" />
  //   );
  // }

  const Restaurar = async (param, IdInstitucion) => {
    TBAsignaturas.setAsignaturaCentro(CodigoPresupuestario)
    const data = {

      codigoPresupuestario: IdInstitucion,
      codigoAsignatura: param,
      estado: "1"
    }

    Swal.fire({

      title: "Restaurar Asignatura",
      text: "¡Estas seguro que deseas restaurar esta asignatura?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, Restaurar",
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {

        const success = await RestoreAsignatura(data);

        if (success) {
          Swal.fire({
            title: "¡Restaurado!",
            text: "La Asignatura a sido restaurada",
            icon: "success",
            timer: 2500, // Tiempo en milisegundos (en este caso, 3 segundos)
            timerProgressBar: true, // Barra de progreso de tiempo
            showConfirmButton: false
          });
          fetchData();
        } else {
          ErrorMessage('Notificación del Sistema', success.mensaje);
          return;

        }

      }
    });
  };


  return (
    <Container fluid className="pt-2 Divcontenedor ">
      <Col>
        <Breadcrumb listTag="div">
          <MenuItem
            className='p-0 text-dark'

            text="Inicio"
            to="/Menu"
            classname={'NoDecoration2 tlink'}
          />
          <Label className=' px-1 py-1'>/</Label>
          <MenuItem
            className='p-0 text-dark'

            text="Asignaturas"
            to="/Menu/FrmAsignaturas"
            classname={'NoDecoration2 tlink'}
          />

        </Breadcrumb>

      </Col>


      <Container fluid className='p-0 Mymt-5'>
        <Col className='mb-1 pb-2 border-bottom'>
          <Button className='p-0  btn btnAceptar    '>
            <ul>
              <MenuItem
                className=' p-0'
                icon={<Fa6.FaCirclePlus className="me-1 sizeIcon2" />}
                text="Asignatura"
                to="/Menu/FrmRegis_Asignatura" // aca agregar la ruta
                classname={'NoDecoration'}
              />
            </ul>
          </Button>

        </Col>



        <Container fluid className='  px-0  ' >
          <Tabs className={'p-0 m-0  border  '}>
            <TabList className={'tabs_ '} >
              <Tab>Asiganturas</Tab>
              <Tab>Asignaturas Inactivas</Tab>
            </TabList>
            <Container fluid className=' shadow2 px-0 cont rounded-0' >

              <TabPanel>
                <Table responsive size="sm">
                  <thead className='border-bottom'>
                    <tr>
                      <th className='pb-0'>
                        <a className='cursor px-2' onClick={() => OrdenarColumnas('codigoAsignatura')}>  Codigo Asignatura {obtenerIconoOrden('codigoAsignatura')}</a>
                      </th>
                      <th className='pb-0'>
                        <a className='cursor px-2' onClick={() => OrdenarColumnas('nombreAsignatura')}>Asignatura {obtenerIconoOrden('nombreAsignatura')}</a>
                      </th>
                      <th className='pb-0'>
                        <a className='cursor px-2' onClick={() => OrdenarColumnas('calificacionMinima')}>Calificacion {obtenerIconoOrden('calificacionMinima')}</a>
                      </th>
                      <th colSpan={2} className='pb-0'>
                        <Fa.FaDownload className='mx-2 edi2' cursor={'pointer'} color='#314843' size={18} onClick={(e) => IrDescargar('Lista de Asignaturas', Asignaturas)} />
                      </th>
                    </tr>

                    <tr className='bg-tr'>

                      <th className='p-0 px-2 pb-1   '>
                        <Col md={6} className="input-with-icon   ">
                          <Input
                            type="text"
                            className="py-1"
                            value={filtros.codigoAsignatura}
                            onChange={(e) => Filtrar(e, 'codigoAsignatura')}
                          />
                          <Fa.FaFilter className="filter-icon" />
                        </Col>

                      </th>
                      <th className='p-0 px-2 pb-1'  >
                        <Col md={6} className="input-with-icon   ">
                          <Input
                            type="text"
                            className="py-1"
                            value={filtros.asignatura}
                            onChange={(e) => Filtrar(e, 'nombreAsignatura')}
                          />
                          <Fa.FaFilter className="filter-icon" />
                        </Col>

                      </th>

                      <th colSpan={3} className='p-0 px-2 pb-1' > </th>
                    </tr>
                  </thead>
                  <tbody className='tablebody' id='tablebody' >
                    {ordenarDatos(filtrarDatos(Asignaturas)).length > 0 ? (
                      ordenarDatos(filtrarDatos(Asignaturas)).map((asig, index) => {
                        // Verificar si el activo está asignado

                        return (
                          <tr className="table-p" key={index}>
                            <td>{asig.codigoAsignatura}</td>
                            <td>{asig.nombreAsignatura}</td>
                            <td>{asig.calificacionMinima}</td>
                            <td>
                              <Fa.FaEye title='Ver mas...' className='mb-1 edi2 ' cursor={'pointer'} color='#314843' size={18} onClick={(e) => Abrirmodal({
                                institucion: asig.asignaturaCentro,
                                codAsig: asig.codigoAsignatura,
                                nomAsig: asig.nombreAsignatura,
                                califi: asig.calificacionMinima
                              })} />
                            </td>
                            <td>
                              <Fa.FaTrash title='Suspender Asignatura' className='mb-1  eli2 ' cursor={'pointer'} color='#314843' size={18} onClick={(e) => Eliminar(asig.codigoAsignatura, asig.asignaturaCentro)} />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">No hay Asignaturas registradas en el sistema. Por favor ingresa las asignaturas.</td>
                      </tr>
                    )}

                  </tbody>
                </Table>
              </TabPanel>

              <TabPanel>
                <Table responsive size="sm">
                  <thead className='border-bottom'>
                    <tr>
                      <th className='pb-0'>
                        <a className='cursor px-2' onClick={() => OrdenarColumnas('codigoAsignatura')}>  Codigo Asignatura {obtenerIconoOrden('codigoAsignatura')}</a>
                      </th>
                      <th className='pb-0'>
                        <a className='cursor px-2' onClick={() => OrdenarColumnas('nombreAsignatura')}>Asignatura {obtenerIconoOrden('nombreAsignatura')}</a>
                      </th>
                      <th className='pb-0'>
                        <a className='cursor px-2' onClick={() => OrdenarColumnas('calificacionMinima')}>Calificacion {obtenerIconoOrden('calificacionMinima')}</a>
                      </th>
                      <th colSpan={2} className='pb-0'>
                        <Fa.FaDownload className='mx-2 edi2' cursor={'pointer'} color='#314843' size={18} onClick={(e) => IrDescargar('Lista de Asignaturas Inactivas', AsignaturasInactivas)} />
                      </th>
                    </tr>

                    <tr className='bg-tr'>

                      <th className='p-0 px-2 pb-1   '>
                        <Col md={6} className="input-with-icon   ">
                          <Input
                            type="text"
                            className="py-1"
                            value={filtros.codigoAsignatura}
                            onChange={(e) => Filtrar(e, 'codigoAsignatura')}
                          />
                          <Fa.FaFilter className="filter-icon" />
                        </Col>

                      </th>
                      <th className='p-0 px-2 pb-1'  >
                        <Col md={6} className="input-with-icon   ">
                          <Input
                            type="text"
                            className="py-1"
                            value={filtros.asignatura}
                            onChange={(e) => Filtrar(e, 'nombreAsignatura')}
                          />
                          <Fa.FaFilter className="filter-icon" />
                        </Col>

                      </th>
                      <th className='p-0 px-2 pb-1'  >
                        <Col md={6} className="input-with-icon   ">
                          <Input
                            type="text"
                            className="py-1"
                            value={filtros.calificacion}
                            onChange={(e) => Filtrar(e, 'calificacionMinima')}
                          />
                          <Fa.FaFilter className="filter-icon" />
                        </Col>

                      </th>
                      <th colSpan={1} className='p-0 px-2 pb-1' > </th>
                    </tr>
                  </thead>
                  <tbody className='tablebody' id='tablebody' >
                    {ordenarDatos(filtrarDatos(AsignaturasInactivas)).length > 0 ? (
                      ordenarDatos(filtrarDatos(AsignaturasInactivas)).map((asig, index) => {
                        // Verificar si el activo está asignado

                        return (
                          <tr className="table-p" key={index}>
                            <td>{asig.codigoAsignatura}</td>
                            <td>{asig.nombreAsignatura}</td>
                            <td>{asig.calificacionMinima}</td>
                            <td>
                              <Fa.FaTrashRestore title='Restaurar Asignatura' className='mb-1  eli2 ' cursor={'pointer'} color='#314843' size={18} onClick={(e) => Restaurar(asig.codigoAsignatura, asig.asignaturaCentro)} />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">No hay asignaturas inactivas actualmente.</td>
                      </tr>
                    )}

                  </tbody>
                </Table>
              </TabPanel>

            </Container>

          </Tabs>
        </Container>
        <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2 mt-2' id='GuardarInstitucion'>
          <ul>
            <MenuItem
              className='btnAceptar'
              icon={<Io.IoMdReturnLeft className="me-1" />}
              text="Volver"
              to="/Menu"
              classname={'NoDecoration'}
            />
          </ul>
        </Button>



        {/* {Asignaturas !== null && Asignaturas.length > 0 ? (




          <Row>
            {Asignaturas.map((asig, index) => (
              <Col xxl={3} xl={4} lg={4} md={6} key={index} className='pb-1'>
                <Card className="mb-3 shadow2 rounded-3 ">
                  <CardBody className="d-flex flex-column py-4">
                    <Col>
                      <Row>
                        <Col md={12}>
                          <strong>Código Asignatura: </strong> <text>{asig.codigoAsignatura}</text> <br />
                        </Col>
                        <Col md={12}>
                          <strong>Asignatura: </strong>{asig.nombreAsignatura}
                        </Col>
                        <Col md={12}>
                          <strong>Calificación Minima: </strong> {asig.calificacionMinima}<br />
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                  <CardFooter className='d-flex justify-content-end'>
                    <Button color='' className='mx-1 btn-editar' onClick={(e) =>
                      Abrirmodal({
                        institucion: asig.asignaturaCentro,
                        codAsig: asig.codigoAsignatura,
                        nomAsig: asig.nombreAsignatura,
                        califi: asig.calificacionMinima
                      })}
                    >
                      Actualizar
                    </Button>
                    <Button color=' ' type='button' className='mx-1 btn-eliminar' onClick={(e) => Eliminar(asig.codigoAsignatura, asig.asignaturaCentro)}>
                      Suspender
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Col>
            <p>No hay asignaturas disponibles.</p>
          </Col>
        )}
        <Button type="button" className='p-0 btn btnCancelar d-flex justify-content-start px-2' id='GuardarInstitucion'>
          <ul>
            <MenuItem
              className='btnAceptar'
              icon={<IoMdReturnLeft className="me-1" />}
              text="Volver"
              to="/Menu"
              classname={'NoDecoration'}
            />
          </ul>
        </Button> */}
      </Container>

      <Modal isOpen={open} size='md' fullscreen='lg' fade className='mt-4 shadow2 p-0' backdrop="static">
        <ModalHeader className='color-header-form '>
          <h5 className='py-1 m-1' >  Actualizar Asignatura </h5>
        </ModalHeader>
        <ModalBody className='p-0'>
          <Container fluid className='  p-3    rounded  ' id='institucion'  >
            <Form onSubmit={ActualizarAsignatura} id='form1' >

              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="calificacion" className="me-2">
                      Código Presupuestario
                    </Label>

                    <Input
                      readOnly
                      required
                      id="calificacion"
                      name="calificacion"

                      type="text"
                      value={TBAsignaturas.AsignaturaCentro + " - " + NombreInst}

                    />
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <FormGroup>
                    <Label for="calificacion" className="me-2">
                      Nombre de la Asignatura
                    </Label>

                    <Input
                      required
                      id="calificacion"
                      name="calificacion"

                      type="text"
                      value={TBAsignaturas.NombreAsignatura}
                      onChange={(e) => ValidarSoloLetras(e, TBAsignaturas.setNombreAsignatura, TBAsignaturas.setAsignaturaValida)}
                      className={TBAsignaturas.AsignaturaValida ? ' ' : 'is-invalid  '}
                    />

                    {!TBAsignaturas.AsignaturaValida && <div className="invalid-feedback">Formato de ingresado no valido</div>}


                  </FormGroup>
                </Col>


                <Col md={12}>
                  <FormGroup>
                    <Label for="calificacion" className="me-2">
                      Código Asignatura
                    </Label>

                    <Input
                      readOnly
                      required
                      id="calificacion"
                      name="calificacion"

                      type="text"
                      value={TBAsignaturas.CodigoAsignatura}


                    />
                    <p className='LetraPeq'><strong>* Este campo es autogenerado por el Sistema.</strong></p>
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <FormGroup>
                    <Label for="calificacion" className="me-2">
                      Calificación Minima
                    </Label>

                    <Input
                      required
                      id="calificacion"
                      name="calificacion"

                      type="text"
                      value={TBAsignaturas.CalificacionMinima}
                      onChange={(event) => ValidarSoloNumeros(event, TBAsignaturas.setCalificacionMinima, TBAsignaturas.setCalificacionValida)}
                      maxLength={2}
                      minLength={2}
                      className={TBAsignaturas.CalificacionValida ? ' ' : 'is-invalid  '}
                    />

                    {!TBAsignaturas.CalificacionValida && <div className="invalid-feedback">Formato de ingresado no valido</div>}


                  </FormGroup>
                </Col>




              </Row>
              <Col className='d-flex justify-content-between'>
                <Button type="button" onClick={toggle} className=' p-1 px-3 btn   btnCancelar d-flex justify-content-start' id='GuardarInstitucion'>
                  <IoMdReturnLeft className="me-2 mt-1" /> Volver
                </Button>
                <Button type="submit" className='btnAceptar p-1 px-3' id='GuardarInstitucion'>
                  Actualizar Asignatura
                </Button>
              </Col>

            </Form>

          </Container>

        </ModalBody>
      </Modal>
    </Container >
  )
}

export default FrmAsignaturas
